$pixel-count: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px,
  6: 6px,
  7: 7px,
  8: 8px,
  9: 9px,
  10: 10px,
  11: 11px,
  12: 12px,
  13: 13px,
  14: 14px,
  15: 15px,
  16: 16px,
  17: 17px,
  18: 18px,
  19: 19px,
  20: 20px,
  21: 21px,
  22: 22px,
  23: 23px,
  24: 24px,
  25: 25px,
) !default;

@each $level, $value in $pixel-count {
  .blur-#{$level} {
    filter: blur(#{$value});
  }

  .rounded-#{$level} {
    border-radius: #{$value};
  }
}

$ambiguity: (
  "font",
  "text"
) !default;

@each $name in $ambiguity {
  .#{$name}-italic {
    font-style: italic;
  }

  .#{$name}-bold {
    font-weight: 700;
  }

  .#{$name}-underline {
    text-decoration: underline;
  }
}

label {
  display: inline-block;
  margin-bottom: .5rem;
}

.form-group {
  margin-bottom: 1rem;
}

.bw {
  filter: grayscale(1);
}

.color {
  filter: grayscale(0);
}
