@charset "UTF-8";
/*!
 * Bootstrap v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #7a8288;
  --bs-gray-dark: #3a3f44;
  --bs-primary: #0d6efd;
  --bs-secondary: #7a8288;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #272b30;
  --bs-sfx: #0a94d9;
  --bs-that-dark-dark: #121416;
  --bs-flat-f: #fff;
  --bs-flat-e: #eee;
  --bs-flat-d: #ddd;
  --bs-flat-c: #ccc;
  --bs-flat-b: #bbb;
  --bs-flat-a: #aaa;
  --bs-flat-9: #999;
  --bs-flat-8: #888;
  --bs-flat-7: #777;
  --bs-flat-6: #666;
  --bs-flat-5: #555;
  --bs-flat-4: #444;
  --bs-flat-3: #333;
  --bs-flat-2: #222;
  --bs-flat-1: #111;
  --bs-flat-0: #000;
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #7a8288;
  --bs-gray-dark: #3a3f44;
  --bs-blue-100: #cfe2ff;
  --bs-blue-200: #9ec5fe;
  --bs-blue-300: #6ea8fe;
  --bs-blue-400: #3d8bfd;
  --bs-blue-500: #0d6efd;
  --bs-blue-600: #0a58ca;
  --bs-blue-700: #084298;
  --bs-blue-800: #052c65;
  --bs-blue-900: #031633;
  --bs-indigo-100: #e0cffc;
  --bs-indigo-200: #c29ffa;
  --bs-indigo-300: #a370f7;
  --bs-indigo-400: #8540f5;
  --bs-indigo-500: #6610f2;
  --bs-indigo-600: #520dc2;
  --bs-indigo-700: #3d0a91;
  --bs-indigo-800: #290661;
  --bs-indigo-900: #140330;
  --bs-purple-100: #e2d9f3;
  --bs-purple-200: #c5b3e6;
  --bs-purple-300: #a98eda;
  --bs-purple-400: #8c68cd;
  --bs-purple-500: #6f42c1;
  --bs-purple-600: #59359a;
  --bs-purple-700: #432874;
  --bs-purple-800: #2c1a4d;
  --bs-purple-900: #160d27;
  --bs-pink-100: #f7d6e6;
  --bs-pink-200: #efadce;
  --bs-pink-300: #e685b5;
  --bs-pink-400: #de5c9d;
  --bs-pink-500: #d63384;
  --bs-pink-600: #ab296a;
  --bs-pink-700: #801f4f;
  --bs-pink-800: #561435;
  --bs-pink-900: #2b0a1a;
  --bs-red-100: #f8d7da;
  --bs-red-200: #f1aeb5;
  --bs-red-300: #ea868f;
  --bs-red-400: #e35d6a;
  --bs-red-500: #dc3545;
  --bs-red-600: #b02a37;
  --bs-red-700: #842029;
  --bs-red-800: #58151c;
  --bs-red-900: #2c0b0e;
  --bs-orange-100: #ffe5d0;
  --bs-orange-200: #fecba1;
  --bs-orange-300: #feb272;
  --bs-orange-400: #fd9843;
  --bs-orange-500: #fd7e14;
  --bs-orange-600: #ca6510;
  --bs-orange-700: #984c0c;
  --bs-orange-800: #653208;
  --bs-orange-900: #331904;
  --bs-yellow-100: #fff3cd;
  --bs-yellow-200: #ffe69c;
  --bs-yellow-300: #ffda6a;
  --bs-yellow-400: #ffcd39;
  --bs-yellow-500: #ffc107;
  --bs-yellow-600: #cc9a06;
  --bs-yellow-700: #997404;
  --bs-yellow-800: #664d03;
  --bs-yellow-900: #332701;
  --bs-green-100: #d1e7dd;
  --bs-green-200: #a3cfbb;
  --bs-green-300: #75b798;
  --bs-green-400: #479f76;
  --bs-green-500: #198754;
  --bs-green-600: #146c43;
  --bs-green-700: #0f5132;
  --bs-green-800: #0a3622;
  --bs-green-900: #051b11;
  --bs-teal-100: #d2f4ea;
  --bs-teal-200: #a6e9d5;
  --bs-teal-300: #79dfc1;
  --bs-teal-400: #4dd4ac;
  --bs-teal-500: #20c997;
  --bs-teal-600: #1aa179;
  --bs-teal-700: #13795b;
  --bs-teal-800: #0d503c;
  --bs-teal-900: #06281e;
  --bs-cyan-100: #cff4fc;
  --bs-cyan-200: #9eeaf9;
  --bs-cyan-300: #6edff6;
  --bs-cyan-400: #3dd5f3;
  --bs-cyan-500: #0dcaf0;
  --bs-cyan-600: #0aa2c0;
  --bs-cyan-700: #087990;
  --bs-cyan-800: #055160;
  --bs-cyan-900: #032830;
  --bs-font-sans-serif: system-ui, "Segoe UI", -apple-system, Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 0.925rem;
  font-weight: 400;
  line-height: 1.5;
  color: #999;
  background-color: #101010;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.35625rem + 1.275vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.3125rem;
  }
}

h2, .h2 {
  font-size: calc(1.31rem + 0.72vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.85rem;
  }
}

h3, .h3 {
  font-size: calc(1.286875rem + 0.4425vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.61875rem;
  }
}

h4, .h4 {
  font-size: calc(1.26375rem + 0.165vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.3875rem;
  }
}

h5, .h5 {
  font-size: 1.15625rem;
}

h6, .h6 {
  font-size: 0.925rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #d6d6d6;
  text-decoration: none;
}
a:hover {
  color: white;
  -webkit-text-decoration: 1px dotted #d6d6d6;
  text-decoration: 1px dotted #d6d6d6;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #272b30;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #7a8288;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.15625rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.font-weight-100 {
  font-weight: 100;
}

.font-weight-200 {
  font-weight: 200;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

.font-weight-900 {
  font-weight: 900;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.15625rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #7a8288;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #101010;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #7a8288;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #999;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #999;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #999;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #999;
  vertical-align: top;
  border-color: #7a8288;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #cfe2ff;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bacbe6;
}

.table-secondary {
  --bs-table-bg: #e4e6e7;
  --bs-table-striped-bg: #d9dbdb;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cdcfd0;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d3d5d6;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cdcfd0;
}

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcd0c7;
}

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badce3;
}

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dbb9;
}

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfc2c4;
}

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe0e1;
}

.table-dark {
  --bs-table-bg: #272b30;
  --bs-table-striped-bg: #32363a;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #3d4045;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #373b40;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #3d4045;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.15625rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.809375rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #7a8288;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.925rem;
  font-weight: 400;
  line-height: 1.5;
  color: #999;
  background-color: #121416;
  background-clip: padding-box;
  border: 1px solid #3a3f44;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #999;
  background-color: #121416;
  border-color: rgba(10, 148, 217, 0.8);
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(39, 43, 48, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::-moz-placeholder {
  color: #7a8288;
  opacity: 1;
}
.form-control::placeholder {
  color: #7a8288;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #0e1012;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #999;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}
.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #999;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #999;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + (0.5rem + 2px));
  padding: 0.25rem 0.5rem;
  font-size: 0.809375rem;
  border-radius: 0.2rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px));
  padding: 0.5rem 1rem;
  font-size: 1.15625rem;
  border-radius: 0.3rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + (0.75rem + 2px));
}
textarea.form-control-sm {
  min-height: calc(1.5em + (0.5rem + 2px));
}
textarea.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px));
}

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 0.925rem;
  font-weight: 400;
  line-height: 1.5;
  color: #999;
  background-color: #121416;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%233a3f44' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #3a3f44;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: rgba(10, 148, 217, 0.8);
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(39, 43, 48, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #999;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.809375rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.15625rem;
}

.form-check {
  display: block;
  min-height: 1.3875rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #121416;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: rgba(10, 148, 217, 0.8);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(39, 43, 48, 0.25);
}
.form-check-input:checked {
  background-color: #272b30;
  border-color: #272b30;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ced4da' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ced4da'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #272b30;
  border-color: #272b30;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ced4da' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%2810, 148, 217, 0.8%29'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ced4da'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #101010, 0 0 0 0.25rem rgba(39, 43, 48, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #101010, 0 0 0 0.25rem rgba(39, 43, 48, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #272b30;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #bebfc1;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #272b30;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #bebfc1;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #999;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #999;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::-moz-placeholder {
  color: transparent;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.925rem;
  font-weight: 400;
  line-height: 1.5;
  color: #999;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #3a3f44;
  border-radius: 0.25rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.15625rem;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.809375rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.809375rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%233a3f44' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.809375rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%233a3f44' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #999;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.925rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #999;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(39, 43, 48, 0.25);
}
.btn-check:checked + .btn, .btn-check:active + .btn, .btn:active, .btn.active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-check:checked + .btn:focus, .btn-check:active + .btn:focus, .btn:active:focus, .btn.active:focus {
  box-shadow: 0 0 0 0.25rem rgba(39, 43, 48, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
  box-shadow: none;
}

.btn-primary {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-primary:hover {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0a58ca;
  border-color: #0a53be;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-secondary {
  color: #000;
  background-color: #7a8288;
  border-color: #7a8288;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-secondary:hover {
  color: #000;
  background-color: #8e959a;
  border-color: #878f94;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #000;
  background-color: #8e959a;
  border-color: #878f94;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(104, 111, 116, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #000;
  background-color: #959ba0;
  border-color: #878f94;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(104, 111, 116, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #000;
  background-color: #7a8288;
  border-color: #7a8288;
}

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-success:hover {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #146c43;
  border-color: #13653f;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}
.btn-success:disabled, .btn-success.disabled {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-info:hover {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
}
.btn-check:focus + .btn-info, .btn-info:focus {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #3dd5f3;
  border-color: #25cff2;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-info:disabled, .btn-info.disabled {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-warning:hover {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffc720;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-light:hover {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}
.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}
.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}
.btn-light:disabled, .btn-light.disabled {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-dark {
  color: #fff;
  background-color: #272b30;
  border-color: #272b30;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #1f2226;
}
.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #212529;
  border-color: #1f2226;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(71, 75, 79, 0.5);
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1f2226;
  border-color: #1d2024;
}
.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(71, 75, 79, 0.5);
}
.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #272b30;
  border-color: #272b30;
}

.btn-sfx {
  color: #000;
  background-color: #0a94d9;
  border-color: #0a94d9;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-sfx:hover {
  color: #000;
  background-color: #2fa4df;
  border-color: #239fdd;
}
.btn-check:focus + .btn-sfx, .btn-sfx:focus {
  color: #000;
  background-color: #2fa4df;
  border-color: #239fdd;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(9, 126, 184, 0.5);
}
.btn-check:checked + .btn-sfx, .btn-check:active + .btn-sfx, .btn-sfx:active, .btn-sfx.active, .show > .btn-sfx.dropdown-toggle {
  color: #000;
  background-color: #3ba9e1;
  border-color: #239fdd;
}
.btn-check:checked + .btn-sfx:focus, .btn-check:active + .btn-sfx:focus, .btn-sfx:active:focus, .btn-sfx.active:focus, .show > .btn-sfx.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(9, 126, 184, 0.5);
}
.btn-sfx:disabled, .btn-sfx.disabled {
  color: #000;
  background-color: #0a94d9;
  border-color: #0a94d9;
}

.btn-that-dark-dark {
  color: #fff;
  background-color: #121416;
  border-color: #121416;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-that-dark-dark:hover {
  color: #fff;
  background-color: #0f1113;
  border-color: #0e1012;
}
.btn-check:focus + .btn-that-dark-dark, .btn-that-dark-dark:focus {
  color: #fff;
  background-color: #0f1113;
  border-color: #0e1012;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(54, 55, 57, 0.5);
}
.btn-check:checked + .btn-that-dark-dark, .btn-check:active + .btn-that-dark-dark, .btn-that-dark-dark:active, .btn-that-dark-dark.active, .show > .btn-that-dark-dark.dropdown-toggle {
  color: #fff;
  background-color: #0e1012;
  border-color: #0e0f11;
}
.btn-check:checked + .btn-that-dark-dark:focus, .btn-check:active + .btn-that-dark-dark:focus, .btn-that-dark-dark:active:focus, .btn-that-dark-dark.active:focus, .show > .btn-that-dark-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(54, 55, 57, 0.5);
}
.btn-that-dark-dark:disabled, .btn-that-dark-dark.disabled {
  color: #fff;
  background-color: #121416;
  border-color: #121416;
}

.btn-flat-f {
  color: #000;
  background-color: #fff;
  border-color: #fff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-flat-f:hover {
  color: #000;
  background-color: white;
  border-color: white;
}
.btn-check:focus + .btn-flat-f, .btn-flat-f:focus {
  color: #000;
  background-color: white;
  border-color: white;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(217, 217, 217, 0.5);
}
.btn-check:checked + .btn-flat-f, .btn-check:active + .btn-flat-f, .btn-flat-f:active, .btn-flat-f.active, .show > .btn-flat-f.dropdown-toggle {
  color: #000;
  background-color: white;
  border-color: white;
}
.btn-check:checked + .btn-flat-f:focus, .btn-check:active + .btn-flat-f:focus, .btn-flat-f:active:focus, .btn-flat-f.active:focus, .show > .btn-flat-f.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(217, 217, 217, 0.5);
}
.btn-flat-f:disabled, .btn-flat-f.disabled {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.btn-flat-e {
  color: #000;
  background-color: #eee;
  border-color: #eee;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-flat-e:hover {
  color: #000;
  background-color: #f1f1f1;
  border-color: #f0f0f0;
}
.btn-check:focus + .btn-flat-e, .btn-flat-e:focus {
  color: #000;
  background-color: #f1f1f1;
  border-color: #f0f0f0;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(202, 202, 202, 0.5);
}
.btn-check:checked + .btn-flat-e, .btn-check:active + .btn-flat-e, .btn-flat-e:active, .btn-flat-e.active, .show > .btn-flat-e.dropdown-toggle {
  color: #000;
  background-color: #f1f1f1;
  border-color: #f0f0f0;
}
.btn-check:checked + .btn-flat-e:focus, .btn-check:active + .btn-flat-e:focus, .btn-flat-e:active:focus, .btn-flat-e.active:focus, .show > .btn-flat-e.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(202, 202, 202, 0.5);
}
.btn-flat-e:disabled, .btn-flat-e.disabled {
  color: #000;
  background-color: #eee;
  border-color: #eee;
}

.btn-flat-d {
  color: #000;
  background-color: #ddd;
  border-color: #ddd;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-flat-d:hover {
  color: #000;
  background-color: #e2e2e2;
  border-color: #e0e0e0;
}
.btn-check:focus + .btn-flat-d, .btn-flat-d:focus {
  color: #000;
  background-color: #e2e2e2;
  border-color: #e0e0e0;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(188, 188, 188, 0.5);
}
.btn-check:checked + .btn-flat-d, .btn-check:active + .btn-flat-d, .btn-flat-d:active, .btn-flat-d.active, .show > .btn-flat-d.dropdown-toggle {
  color: #000;
  background-color: #e4e4e4;
  border-color: #e0e0e0;
}
.btn-check:checked + .btn-flat-d:focus, .btn-check:active + .btn-flat-d:focus, .btn-flat-d:active:focus, .btn-flat-d.active:focus, .show > .btn-flat-d.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(188, 188, 188, 0.5);
}
.btn-flat-d:disabled, .btn-flat-d.disabled {
  color: #000;
  background-color: #ddd;
  border-color: #ddd;
}

.btn-flat-c {
  color: #000;
  background-color: #ccc;
  border-color: #ccc;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-flat-c:hover {
  color: #000;
  background-color: #d4d4d4;
  border-color: #d1d1d1;
}
.btn-check:focus + .btn-flat-c, .btn-flat-c:focus {
  color: #000;
  background-color: #d4d4d4;
  border-color: #d1d1d1;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(173, 173, 173, 0.5);
}
.btn-check:checked + .btn-flat-c, .btn-check:active + .btn-flat-c, .btn-flat-c:active, .btn-flat-c.active, .show > .btn-flat-c.dropdown-toggle {
  color: #000;
  background-color: #d6d6d6;
  border-color: #d1d1d1;
}
.btn-check:checked + .btn-flat-c:focus, .btn-check:active + .btn-flat-c:focus, .btn-flat-c:active:focus, .btn-flat-c.active:focus, .show > .btn-flat-c.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(173, 173, 173, 0.5);
}
.btn-flat-c:disabled, .btn-flat-c.disabled {
  color: #000;
  background-color: #ccc;
  border-color: #ccc;
}

.btn-flat-b {
  color: #000;
  background-color: #bbb;
  border-color: #bbb;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-flat-b:hover {
  color: #000;
  background-color: #c5c5c5;
  border-color: #c2c2c2;
}
.btn-check:focus + .btn-flat-b, .btn-flat-b:focus {
  color: #000;
  background-color: #c5c5c5;
  border-color: #c2c2c2;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(159, 159, 159, 0.5);
}
.btn-check:checked + .btn-flat-b, .btn-check:active + .btn-flat-b, .btn-flat-b:active, .btn-flat-b.active, .show > .btn-flat-b.dropdown-toggle {
  color: #000;
  background-color: #c9c9c9;
  border-color: #c2c2c2;
}
.btn-check:checked + .btn-flat-b:focus, .btn-check:active + .btn-flat-b:focus, .btn-flat-b:active:focus, .btn-flat-b.active:focus, .show > .btn-flat-b.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(159, 159, 159, 0.5);
}
.btn-flat-b:disabled, .btn-flat-b.disabled {
  color: #000;
  background-color: #bbb;
  border-color: #bbb;
}

.btn-flat-a {
  color: #000;
  background-color: #aaa;
  border-color: #aaa;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-flat-a:hover {
  color: #000;
  background-color: #b7b7b7;
  border-color: #b3b3b3;
}
.btn-check:focus + .btn-flat-a, .btn-flat-a:focus {
  color: #000;
  background-color: #b7b7b7;
  border-color: #b3b3b3;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(145, 145, 145, 0.5);
}
.btn-check:checked + .btn-flat-a, .btn-check:active + .btn-flat-a, .btn-flat-a:active, .btn-flat-a.active, .show > .btn-flat-a.dropdown-toggle {
  color: #000;
  background-color: #bbbbbb;
  border-color: #b3b3b3;
}
.btn-check:checked + .btn-flat-a:focus, .btn-check:active + .btn-flat-a:focus, .btn-flat-a:active:focus, .btn-flat-a.active:focus, .show > .btn-flat-a.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(145, 145, 145, 0.5);
}
.btn-flat-a:disabled, .btn-flat-a.disabled {
  color: #000;
  background-color: #aaa;
  border-color: #aaa;
}

.btn-flat-9 {
  color: #000;
  background-color: #999;
  border-color: #999;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-flat-9:hover {
  color: #000;
  background-color: #a8a8a8;
  border-color: #a3a3a3;
}
.btn-check:focus + .btn-flat-9, .btn-flat-9:focus {
  color: #000;
  background-color: #a8a8a8;
  border-color: #a3a3a3;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(130, 130, 130, 0.5);
}
.btn-check:checked + .btn-flat-9, .btn-check:active + .btn-flat-9, .btn-flat-9:active, .btn-flat-9.active, .show > .btn-flat-9.dropdown-toggle {
  color: #000;
  background-color: #adadad;
  border-color: #a3a3a3;
}
.btn-check:checked + .btn-flat-9:focus, .btn-check:active + .btn-flat-9:focus, .btn-flat-9:active:focus, .btn-flat-9.active:focus, .show > .btn-flat-9.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(130, 130, 130, 0.5);
}
.btn-flat-9:disabled, .btn-flat-9.disabled {
  color: #000;
  background-color: #999;
  border-color: #999;
}

.btn-flat-8 {
  color: #000;
  background-color: #888;
  border-color: #888;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-flat-8:hover {
  color: #000;
  background-color: #9a9a9a;
  border-color: #949494;
}
.btn-check:focus + .btn-flat-8, .btn-flat-8:focus {
  color: #000;
  background-color: #9a9a9a;
  border-color: #949494;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(116, 116, 116, 0.5);
}
.btn-check:checked + .btn-flat-8, .btn-check:active + .btn-flat-8, .btn-flat-8:active, .btn-flat-8.active, .show > .btn-flat-8.dropdown-toggle {
  color: #000;
  background-color: #a0a0a0;
  border-color: #949494;
}
.btn-check:checked + .btn-flat-8:focus, .btn-check:active + .btn-flat-8:focus, .btn-flat-8:active:focus, .btn-flat-8.active:focus, .show > .btn-flat-8.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(116, 116, 116, 0.5);
}
.btn-flat-8:disabled, .btn-flat-8.disabled {
  color: #000;
  background-color: #888;
  border-color: #888;
}

.btn-flat-7 {
  color: #000;
  background-color: #777;
  border-color: #777;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-flat-7:hover {
  color: #000;
  background-color: #8b8b8b;
  border-color: #858585;
}
.btn-check:focus + .btn-flat-7, .btn-flat-7:focus {
  color: #000;
  background-color: #8b8b8b;
  border-color: #858585;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(101, 101, 101, 0.5);
}
.btn-check:checked + .btn-flat-7, .btn-check:active + .btn-flat-7, .btn-flat-7:active, .btn-flat-7.active, .show > .btn-flat-7.dropdown-toggle {
  color: #000;
  background-color: #929292;
  border-color: #858585;
}
.btn-check:checked + .btn-flat-7:focus, .btn-check:active + .btn-flat-7:focus, .btn-flat-7:active:focus, .btn-flat-7.active:focus, .show > .btn-flat-7.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(101, 101, 101, 0.5);
}
.btn-flat-7:disabled, .btn-flat-7.disabled {
  color: #000;
  background-color: #777;
  border-color: #777;
}

.btn-flat-6 {
  color: #fff;
  background-color: #666;
  border-color: #666;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-flat-6:hover {
  color: #fff;
  background-color: #575757;
  border-color: #525252;
}
.btn-check:focus + .btn-flat-6, .btn-flat-6:focus {
  color: #fff;
  background-color: #575757;
  border-color: #525252;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(125, 125, 125, 0.5);
}
.btn-check:checked + .btn-flat-6, .btn-check:active + .btn-flat-6, .btn-flat-6:active, .btn-flat-6.active, .show > .btn-flat-6.dropdown-toggle {
  color: #fff;
  background-color: #525252;
  border-color: #4d4d4d;
}
.btn-check:checked + .btn-flat-6:focus, .btn-check:active + .btn-flat-6:focus, .btn-flat-6:active:focus, .btn-flat-6.active:focus, .show > .btn-flat-6.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(125, 125, 125, 0.5);
}
.btn-flat-6:disabled, .btn-flat-6.disabled {
  color: #fff;
  background-color: #666;
  border-color: #666;
}

.btn-flat-5 {
  color: #fff;
  background-color: #555;
  border-color: #555;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-flat-5:hover {
  color: #fff;
  background-color: #484848;
  border-color: #444444;
}
.btn-check:focus + .btn-flat-5, .btn-flat-5:focus {
  color: #fff;
  background-color: #484848;
  border-color: #444444;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(111, 111, 111, 0.5);
}
.btn-check:checked + .btn-flat-5, .btn-check:active + .btn-flat-5, .btn-flat-5:active, .btn-flat-5.active, .show > .btn-flat-5.dropdown-toggle {
  color: #fff;
  background-color: #444444;
  border-color: #404040;
}
.btn-check:checked + .btn-flat-5:focus, .btn-check:active + .btn-flat-5:focus, .btn-flat-5:active:focus, .btn-flat-5.active:focus, .show > .btn-flat-5.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(111, 111, 111, 0.5);
}
.btn-flat-5:disabled, .btn-flat-5.disabled {
  color: #fff;
  background-color: #555;
  border-color: #555;
}

.btn-flat-4 {
  color: #fff;
  background-color: #444;
  border-color: #444;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-flat-4:hover {
  color: #fff;
  background-color: #3a3a3a;
  border-color: #363636;
}
.btn-check:focus + .btn-flat-4, .btn-flat-4:focus {
  color: #fff;
  background-color: #3a3a3a;
  border-color: #363636;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(96, 96, 96, 0.5);
}
.btn-check:checked + .btn-flat-4, .btn-check:active + .btn-flat-4, .btn-flat-4:active, .btn-flat-4.active, .show > .btn-flat-4.dropdown-toggle {
  color: #fff;
  background-color: #363636;
  border-color: #333333;
}
.btn-check:checked + .btn-flat-4:focus, .btn-check:active + .btn-flat-4:focus, .btn-flat-4:active:focus, .btn-flat-4.active:focus, .show > .btn-flat-4.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(96, 96, 96, 0.5);
}
.btn-flat-4:disabled, .btn-flat-4.disabled {
  color: #fff;
  background-color: #444;
  border-color: #444;
}

.btn-flat-3 {
  color: #fff;
  background-color: #333;
  border-color: #333;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-flat-3:hover {
  color: #fff;
  background-color: #2b2b2b;
  border-color: #292929;
}
.btn-check:focus + .btn-flat-3, .btn-flat-3:focus {
  color: #fff;
  background-color: #2b2b2b;
  border-color: #292929;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(82, 82, 82, 0.5);
}
.btn-check:checked + .btn-flat-3, .btn-check:active + .btn-flat-3, .btn-flat-3:active, .btn-flat-3.active, .show > .btn-flat-3.dropdown-toggle {
  color: #fff;
  background-color: #292929;
  border-color: #262626;
}
.btn-check:checked + .btn-flat-3:focus, .btn-check:active + .btn-flat-3:focus, .btn-flat-3:active:focus, .btn-flat-3.active:focus, .show > .btn-flat-3.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(82, 82, 82, 0.5);
}
.btn-flat-3:disabled, .btn-flat-3.disabled {
  color: #fff;
  background-color: #333;
  border-color: #333;
}

.btn-flat-2 {
  color: #fff;
  background-color: #222;
  border-color: #222;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-flat-2:hover {
  color: #fff;
  background-color: #1d1d1d;
  border-color: #1b1b1b;
}
.btn-check:focus + .btn-flat-2, .btn-flat-2:focus {
  color: #fff;
  background-color: #1d1d1d;
  border-color: #1b1b1b;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(67, 67, 67, 0.5);
}
.btn-check:checked + .btn-flat-2, .btn-check:active + .btn-flat-2, .btn-flat-2:active, .btn-flat-2.active, .show > .btn-flat-2.dropdown-toggle {
  color: #fff;
  background-color: #1b1b1b;
  border-color: #1a1a1a;
}
.btn-check:checked + .btn-flat-2:focus, .btn-check:active + .btn-flat-2:focus, .btn-flat-2:active:focus, .btn-flat-2.active:focus, .show > .btn-flat-2.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(67, 67, 67, 0.5);
}
.btn-flat-2:disabled, .btn-flat-2.disabled {
  color: #fff;
  background-color: #222;
  border-color: #222;
}

.btn-flat-1 {
  color: #fff;
  background-color: #111;
  border-color: #111;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-flat-1:hover {
  color: #fff;
  background-color: #0e0e0e;
  border-color: #0e0e0e;
}
.btn-check:focus + .btn-flat-1, .btn-flat-1:focus {
  color: #fff;
  background-color: #0e0e0e;
  border-color: #0e0e0e;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(53, 53, 53, 0.5);
}
.btn-check:checked + .btn-flat-1, .btn-check:active + .btn-flat-1, .btn-flat-1:active, .btn-flat-1.active, .show > .btn-flat-1.dropdown-toggle {
  color: #fff;
  background-color: #0e0e0e;
  border-color: #0d0d0d;
}
.btn-check:checked + .btn-flat-1:focus, .btn-check:active + .btn-flat-1:focus, .btn-flat-1:active:focus, .btn-flat-1.active:focus, .show > .btn-flat-1.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(53, 53, 53, 0.5);
}
.btn-flat-1:disabled, .btn-flat-1.disabled {
  color: #fff;
  background-color: #111;
  border-color: #111;
}

.btn-flat-0 {
  color: #fff;
  background-color: #000;
  border-color: #000;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-flat-0:hover {
  color: #fff;
  background-color: black;
  border-color: black;
}
.btn-check:focus + .btn-flat-0, .btn-flat-0:focus {
  color: #fff;
  background-color: black;
  border-color: black;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(38, 38, 38, 0.5);
}
.btn-check:checked + .btn-flat-0, .btn-check:active + .btn-flat-0, .btn-flat-0:active, .btn-flat-0.active, .show > .btn-flat-0.dropdown-toggle {
  color: #fff;
  background-color: black;
  border-color: black;
}
.btn-check:checked + .btn-flat-0:focus, .btn-check:active + .btn-flat-0:focus, .btn-flat-0:active:focus, .btn-flat-0.active:focus, .show > .btn-flat-0.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(38, 38, 38, 0.5);
}
.btn-flat-0:disabled, .btn-flat-0.disabled {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-blue {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-blue:hover {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
}
.btn-check:focus + .btn-blue, .btn-blue:focus {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}
.btn-check:checked + .btn-blue, .btn-check:active + .btn-blue, .btn-blue:active, .btn-blue.active, .show > .btn-blue.dropdown-toggle {
  color: #fff;
  background-color: #0a58ca;
  border-color: #0a53be;
}
.btn-check:checked + .btn-blue:focus, .btn-check:active + .btn-blue:focus, .btn-blue:active:focus, .btn-blue.active:focus, .show > .btn-blue.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}
.btn-blue:disabled, .btn-blue.disabled {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-indigo {
  color: #fff;
  background-color: #6610f2;
  border-color: #6610f2;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-indigo:hover {
  color: #fff;
  background-color: #570ece;
  border-color: #520dc2;
}
.btn-check:focus + .btn-indigo, .btn-indigo:focus {
  color: #fff;
  background-color: #570ece;
  border-color: #520dc2;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(125, 52, 244, 0.5);
}
.btn-check:checked + .btn-indigo, .btn-check:active + .btn-indigo, .btn-indigo:active, .btn-indigo.active, .show > .btn-indigo.dropdown-toggle {
  color: #fff;
  background-color: #520dc2;
  border-color: #4d0cb6;
}
.btn-check:checked + .btn-indigo:focus, .btn-check:active + .btn-indigo:focus, .btn-indigo:active:focus, .btn-indigo.active:focus, .show > .btn-indigo.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(125, 52, 244, 0.5);
}
.btn-indigo:disabled, .btn-indigo.disabled {
  color: #fff;
  background-color: #6610f2;
  border-color: #6610f2;
}

.btn-purple {
  color: #fff;
  background-color: #6f42c1;
  border-color: #6f42c1;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-purple:hover {
  color: #fff;
  background-color: #5e38a4;
  border-color: #59359a;
}
.btn-check:focus + .btn-purple, .btn-purple:focus {
  color: #fff;
  background-color: #5e38a4;
  border-color: #59359a;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(133, 94, 202, 0.5);
}
.btn-check:checked + .btn-purple, .btn-check:active + .btn-purple, .btn-purple:active, .btn-purple.active, .show > .btn-purple.dropdown-toggle {
  color: #fff;
  background-color: #59359a;
  border-color: #533291;
}
.btn-check:checked + .btn-purple:focus, .btn-check:active + .btn-purple:focus, .btn-purple:active:focus, .btn-purple.active:focus, .show > .btn-purple.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(133, 94, 202, 0.5);
}
.btn-purple:disabled, .btn-purple.disabled {
  color: #fff;
  background-color: #6f42c1;
  border-color: #6f42c1;
}

.btn-pink {
  color: #fff;
  background-color: #d63384;
  border-color: #d63384;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-pink:hover {
  color: #fff;
  background-color: #b62b70;
  border-color: #ab296a;
}
.btn-check:focus + .btn-pink, .btn-pink:focus {
  color: #fff;
  background-color: #b62b70;
  border-color: #ab296a;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(220, 82, 150, 0.5);
}
.btn-check:checked + .btn-pink, .btn-check:active + .btn-pink, .btn-pink:active, .btn-pink.active, .show > .btn-pink.dropdown-toggle {
  color: #fff;
  background-color: #ab296a;
  border-color: #a12663;
}
.btn-check:checked + .btn-pink:focus, .btn-check:active + .btn-pink:focus, .btn-pink:active:focus, .btn-pink.active:focus, .show > .btn-pink.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(220, 82, 150, 0.5);
}
.btn-pink:disabled, .btn-pink.disabled {
  color: #fff;
  background-color: #d63384;
  border-color: #d63384;
}

.btn-red {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-red:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}
.btn-check:focus + .btn-red, .btn-red:focus {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-check:checked + .btn-red, .btn-check:active + .btn-red, .btn-red:active, .btn-red.active, .show > .btn-red.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834;
}
.btn-check:checked + .btn-red:focus, .btn-check:active + .btn-red:focus, .btn-red:active:focus, .btn-red.active:focus, .show > .btn-red.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-red:disabled, .btn-red.disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-orange {
  color: #000;
  background-color: #fd7e14;
  border-color: #fd7e14;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-orange:hover {
  color: #000;
  background-color: #fd9137;
  border-color: #fd8b2c;
}
.btn-check:focus + .btn-orange, .btn-orange:focus {
  color: #000;
  background-color: #fd9137;
  border-color: #fd8b2c;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(215, 107, 17, 0.5);
}
.btn-check:checked + .btn-orange, .btn-check:active + .btn-orange, .btn-orange:active, .btn-orange.active, .show > .btn-orange.dropdown-toggle {
  color: #000;
  background-color: #fd9843;
  border-color: #fd8b2c;
}
.btn-check:checked + .btn-orange:focus, .btn-check:active + .btn-orange:focus, .btn-orange:active:focus, .btn-orange.active:focus, .show > .btn-orange.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(215, 107, 17, 0.5);
}
.btn-orange:disabled, .btn-orange.disabled {
  color: #000;
  background-color: #fd7e14;
  border-color: #fd7e14;
}

.btn-yellow {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-yellow:hover {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
}
.btn-check:focus + .btn-yellow, .btn-yellow:focus {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}
.btn-check:checked + .btn-yellow, .btn-check:active + .btn-yellow, .btn-yellow:active, .btn-yellow.active, .show > .btn-yellow.dropdown-toggle {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffc720;
}
.btn-check:checked + .btn-yellow:focus, .btn-check:active + .btn-yellow:focus, .btn-yellow:active:focus, .btn-yellow.active:focus, .show > .btn-yellow.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}
.btn-yellow:disabled, .btn-yellow.disabled {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-green {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-green:hover {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
}
.btn-check:focus + .btn-green, .btn-green:focus {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}
.btn-check:checked + .btn-green, .btn-check:active + .btn-green, .btn-green:active, .btn-green.active, .show > .btn-green.dropdown-toggle {
  color: #fff;
  background-color: #146c43;
  border-color: #13653f;
}
.btn-check:checked + .btn-green:focus, .btn-check:active + .btn-green:focus, .btn-green:active:focus, .btn-green.active:focus, .show > .btn-green.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}
.btn-green:disabled, .btn-green.disabled {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-teal {
  color: #000;
  background-color: #20c997;
  border-color: #20c997;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-teal:hover {
  color: #000;
  background-color: #41d1a7;
  border-color: #36cea1;
}
.btn-check:focus + .btn-teal, .btn-teal:focus {
  color: #000;
  background-color: #41d1a7;
  border-color: #36cea1;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(27, 171, 128, 0.5);
}
.btn-check:checked + .btn-teal, .btn-check:active + .btn-teal, .btn-teal:active, .btn-teal.active, .show > .btn-teal.dropdown-toggle {
  color: #000;
  background-color: #4dd4ac;
  border-color: #36cea1;
}
.btn-check:checked + .btn-teal:focus, .btn-check:active + .btn-teal:focus, .btn-teal:active:focus, .btn-teal.active:focus, .show > .btn-teal.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(27, 171, 128, 0.5);
}
.btn-teal:disabled, .btn-teal.disabled {
  color: #000;
  background-color: #20c997;
  border-color: #20c997;
}

.btn-cyan {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-cyan:hover {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
}
.btn-check:focus + .btn-cyan, .btn-cyan:focus {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-check:checked + .btn-cyan, .btn-check:active + .btn-cyan, .btn-cyan:active, .btn-cyan.active, .show > .btn-cyan.dropdown-toggle {
  color: #000;
  background-color: #3dd5f3;
  border-color: #25cff2;
}
.btn-check:checked + .btn-cyan:focus, .btn-check:active + .btn-cyan:focus, .btn-cyan:active:focus, .btn-cyan.active:focus, .show > .btn-cyan.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-cyan:disabled, .btn-cyan.disabled {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-white {
  color: #000;
  background-color: #fff;
  border-color: #fff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-white:hover {
  color: #000;
  background-color: white;
  border-color: white;
}
.btn-check:focus + .btn-white, .btn-white:focus {
  color: #000;
  background-color: white;
  border-color: white;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(217, 217, 217, 0.5);
}
.btn-check:checked + .btn-white, .btn-check:active + .btn-white, .btn-white:active, .btn-white.active, .show > .btn-white.dropdown-toggle {
  color: #000;
  background-color: white;
  border-color: white;
}
.btn-check:checked + .btn-white:focus, .btn-check:active + .btn-white:focus, .btn-white:active:focus, .btn-white.active:focus, .show > .btn-white.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(217, 217, 217, 0.5);
}
.btn-white:disabled, .btn-white.disabled {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}

.btn-gray {
  color: #000;
  background-color: #7a8288;
  border-color: #7a8288;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-gray:hover {
  color: #000;
  background-color: #8e959a;
  border-color: #878f94;
}
.btn-check:focus + .btn-gray, .btn-gray:focus {
  color: #000;
  background-color: #8e959a;
  border-color: #878f94;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(104, 111, 116, 0.5);
}
.btn-check:checked + .btn-gray, .btn-check:active + .btn-gray, .btn-gray:active, .btn-gray.active, .show > .btn-gray.dropdown-toggle {
  color: #000;
  background-color: #959ba0;
  border-color: #878f94;
}
.btn-check:checked + .btn-gray:focus, .btn-check:active + .btn-gray:focus, .btn-gray:active:focus, .btn-gray.active:focus, .show > .btn-gray.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(104, 111, 116, 0.5);
}
.btn-gray:disabled, .btn-gray.disabled {
  color: #000;
  background-color: #7a8288;
  border-color: #7a8288;
}

.btn-gray-dark {
  color: #fff;
  background-color: #3a3f44;
  border-color: #3a3f44;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-gray-dark:hover {
  color: #fff;
  background-color: #31363a;
  border-color: #2e3236;
}
.btn-check:focus + .btn-gray-dark, .btn-gray-dark:focus {
  color: #fff;
  background-color: #31363a;
  border-color: #2e3236;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(88, 92, 96, 0.5);
}
.btn-check:checked + .btn-gray-dark, .btn-check:active + .btn-gray-dark, .btn-gray-dark:active, .btn-gray-dark.active, .show > .btn-gray-dark.dropdown-toggle {
  color: #fff;
  background-color: #2e3236;
  border-color: #2c2f33;
}
.btn-check:checked + .btn-gray-dark:focus, .btn-check:active + .btn-gray-dark:focus, .btn-gray-dark:active:focus, .btn-gray-dark.active:focus, .show > .btn-gray-dark.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(88, 92, 96, 0.5);
}
.btn-gray-dark:disabled, .btn-gray-dark.disabled {
  color: #fff;
  background-color: #3a3f44;
  border-color: #3a3f44;
}

.btn-blue-100 {
  color: #000;
  background-color: #cfe2ff;
  border-color: #cfe2ff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-blue-100:hover {
  color: #000;
  background-color: #d6e6ff;
  border-color: #d4e5ff;
}
.btn-check:focus + .btn-blue-100, .btn-blue-100:focus {
  color: #000;
  background-color: #d6e6ff;
  border-color: #d4e5ff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(176, 192, 217, 0.5);
}
.btn-check:checked + .btn-blue-100, .btn-check:active + .btn-blue-100, .btn-blue-100:active, .btn-blue-100.active, .show > .btn-blue-100.dropdown-toggle {
  color: #000;
  background-color: #d9e8ff;
  border-color: #d4e5ff;
}
.btn-check:checked + .btn-blue-100:focus, .btn-check:active + .btn-blue-100:focus, .btn-blue-100:active:focus, .btn-blue-100.active:focus, .show > .btn-blue-100.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(176, 192, 217, 0.5);
}
.btn-blue-100:disabled, .btn-blue-100.disabled {
  color: #000;
  background-color: #cfe2ff;
  border-color: #cfe2ff;
}

.btn-blue-200 {
  color: #000;
  background-color: #9ec5fe;
  border-color: #9ec5fe;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-blue-200:hover {
  color: #000;
  background-color: #adcefe;
  border-color: #a8cbfe;
}
.btn-check:focus + .btn-blue-200, .btn-blue-200:focus {
  color: #000;
  background-color: #adcefe;
  border-color: #a8cbfe;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(134, 167, 216, 0.5);
}
.btn-check:checked + .btn-blue-200, .btn-check:active + .btn-blue-200, .btn-blue-200:active, .btn-blue-200.active, .show > .btn-blue-200.dropdown-toggle {
  color: #000;
  background-color: #b1d1fe;
  border-color: #a8cbfe;
}
.btn-check:checked + .btn-blue-200:focus, .btn-check:active + .btn-blue-200:focus, .btn-blue-200:active:focus, .btn-blue-200.active:focus, .show > .btn-blue-200.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(134, 167, 216, 0.5);
}
.btn-blue-200:disabled, .btn-blue-200.disabled {
  color: #000;
  background-color: #9ec5fe;
  border-color: #9ec5fe;
}

.btn-blue-300 {
  color: #000;
  background-color: #6ea8fe;
  border-color: #6ea8fe;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-blue-300:hover {
  color: #000;
  background-color: #84b5fe;
  border-color: #7db1fe;
}
.btn-check:focus + .btn-blue-300, .btn-blue-300:focus {
  color: #000;
  background-color: #84b5fe;
  border-color: #7db1fe;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(94, 143, 216, 0.5);
}
.btn-check:checked + .btn-blue-300, .btn-check:active + .btn-blue-300, .btn-blue-300:active, .btn-blue-300.active, .show > .btn-blue-300.dropdown-toggle {
  color: #000;
  background-color: #8bb9fe;
  border-color: #7db1fe;
}
.btn-check:checked + .btn-blue-300:focus, .btn-check:active + .btn-blue-300:focus, .btn-blue-300:active:focus, .btn-blue-300.active:focus, .show > .btn-blue-300.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(94, 143, 216, 0.5);
}
.btn-blue-300:disabled, .btn-blue-300.disabled {
  color: #000;
  background-color: #6ea8fe;
  border-color: #6ea8fe;
}

.btn-blue-400 {
  color: #000;
  background-color: #3d8bfd;
  border-color: #3d8bfd;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-blue-400:hover {
  color: #000;
  background-color: #5a9cfd;
  border-color: #5097fd;
}
.btn-check:focus + .btn-blue-400, .btn-blue-400:focus {
  color: #000;
  background-color: #5a9cfd;
  border-color: #5097fd;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(52, 118, 215, 0.5);
}
.btn-check:checked + .btn-blue-400, .btn-check:active + .btn-blue-400, .btn-blue-400:active, .btn-blue-400.active, .show > .btn-blue-400.dropdown-toggle {
  color: #000;
  background-color: #64a2fd;
  border-color: #5097fd;
}
.btn-check:checked + .btn-blue-400:focus, .btn-check:active + .btn-blue-400:focus, .btn-blue-400:active:focus, .btn-blue-400.active:focus, .show > .btn-blue-400.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(52, 118, 215, 0.5);
}
.btn-blue-400:disabled, .btn-blue-400.disabled {
  color: #000;
  background-color: #3d8bfd;
  border-color: #3d8bfd;
}

.btn-blue-500 {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-blue-500:hover {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
}
.btn-check:focus + .btn-blue-500, .btn-blue-500:focus {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}
.btn-check:checked + .btn-blue-500, .btn-check:active + .btn-blue-500, .btn-blue-500:active, .btn-blue-500.active, .show > .btn-blue-500.dropdown-toggle {
  color: #fff;
  background-color: #0a58ca;
  border-color: #0a53be;
}
.btn-check:checked + .btn-blue-500:focus, .btn-check:active + .btn-blue-500:focus, .btn-blue-500:active:focus, .btn-blue-500.active:focus, .show > .btn-blue-500.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}
.btn-blue-500:disabled, .btn-blue-500.disabled {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-blue-600 {
  color: #fff;
  background-color: #0a58ca;
  border-color: #0a58ca;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-blue-600:hover {
  color: #fff;
  background-color: #094bac;
  border-color: #0846a2;
}
.btn-check:focus + .btn-blue-600, .btn-blue-600:focus {
  color: #fff;
  background-color: #094bac;
  border-color: #0846a2;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(47, 113, 210, 0.5);
}
.btn-check:checked + .btn-blue-600, .btn-check:active + .btn-blue-600, .btn-blue-600:active, .btn-blue-600.active, .show > .btn-blue-600.dropdown-toggle {
  color: #fff;
  background-color: #0846a2;
  border-color: #084298;
}
.btn-check:checked + .btn-blue-600:focus, .btn-check:active + .btn-blue-600:focus, .btn-blue-600:active:focus, .btn-blue-600.active:focus, .show > .btn-blue-600.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(47, 113, 210, 0.5);
}
.btn-blue-600:disabled, .btn-blue-600.disabled {
  color: #fff;
  background-color: #0a58ca;
  border-color: #0a58ca;
}

.btn-blue-700 {
  color: #fff;
  background-color: #084298;
  border-color: #084298;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-blue-700:hover {
  color: #fff;
  background-color: #073881;
  border-color: #06357a;
}
.btn-check:focus + .btn-blue-700, .btn-blue-700:focus {
  color: #fff;
  background-color: #073881;
  border-color: #06357a;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(45, 94, 167, 0.5);
}
.btn-check:checked + .btn-blue-700, .btn-check:active + .btn-blue-700, .btn-blue-700:active, .btn-blue-700.active, .show > .btn-blue-700.dropdown-toggle {
  color: #fff;
  background-color: #06357a;
  border-color: #063272;
}
.btn-check:checked + .btn-blue-700:focus, .btn-check:active + .btn-blue-700:focus, .btn-blue-700:active:focus, .btn-blue-700.active:focus, .show > .btn-blue-700.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(45, 94, 167, 0.5);
}
.btn-blue-700:disabled, .btn-blue-700.disabled {
  color: #fff;
  background-color: #084298;
  border-color: #084298;
}

.btn-blue-800 {
  color: #fff;
  background-color: #052c65;
  border-color: #052c65;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-blue-800:hover {
  color: #fff;
  background-color: #042556;
  border-color: #042351;
}
.btn-check:focus + .btn-blue-800, .btn-blue-800:focus {
  color: #fff;
  background-color: #042556;
  border-color: #042351;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(43, 76, 124, 0.5);
}
.btn-check:checked + .btn-blue-800, .btn-check:active + .btn-blue-800, .btn-blue-800:active, .btn-blue-800.active, .show > .btn-blue-800.dropdown-toggle {
  color: #fff;
  background-color: #042351;
  border-color: #04214c;
}
.btn-check:checked + .btn-blue-800:focus, .btn-check:active + .btn-blue-800:focus, .btn-blue-800:active:focus, .btn-blue-800.active:focus, .show > .btn-blue-800.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(43, 76, 124, 0.5);
}
.btn-blue-800:disabled, .btn-blue-800.disabled {
  color: #fff;
  background-color: #052c65;
  border-color: #052c65;
}

.btn-blue-900 {
  color: #fff;
  background-color: #031633;
  border-color: #031633;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-blue-900:hover {
  color: #fff;
  background-color: #03132b;
  border-color: #021229;
}
.btn-check:focus + .btn-blue-900, .btn-blue-900:focus {
  color: #fff;
  background-color: #03132b;
  border-color: #021229;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(41, 57, 82, 0.5);
}
.btn-check:checked + .btn-blue-900, .btn-check:active + .btn-blue-900, .btn-blue-900:active, .btn-blue-900.active, .show > .btn-blue-900.dropdown-toggle {
  color: #fff;
  background-color: #021229;
  border-color: #021126;
}
.btn-check:checked + .btn-blue-900:focus, .btn-check:active + .btn-blue-900:focus, .btn-blue-900:active:focus, .btn-blue-900.active:focus, .show > .btn-blue-900.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(41, 57, 82, 0.5);
}
.btn-blue-900:disabled, .btn-blue-900.disabled {
  color: #fff;
  background-color: #031633;
  border-color: #031633;
}

.btn-indigo-100 {
  color: #000;
  background-color: #e0cffc;
  border-color: #e0cffc;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-indigo-100:hover {
  color: #000;
  background-color: #e5d6fc;
  border-color: #e3d4fc;
}
.btn-check:focus + .btn-indigo-100, .btn-indigo-100:focus {
  color: #000;
  background-color: #e5d6fc;
  border-color: #e3d4fc;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(190, 176, 214, 0.5);
}
.btn-check:checked + .btn-indigo-100, .btn-check:active + .btn-indigo-100, .btn-indigo-100:active, .btn-indigo-100.active, .show > .btn-indigo-100.dropdown-toggle {
  color: #000;
  background-color: #e6d9fd;
  border-color: #e3d4fc;
}
.btn-check:checked + .btn-indigo-100:focus, .btn-check:active + .btn-indigo-100:focus, .btn-indigo-100:active:focus, .btn-indigo-100.active:focus, .show > .btn-indigo-100.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(190, 176, 214, 0.5);
}
.btn-indigo-100:disabled, .btn-indigo-100.disabled {
  color: #000;
  background-color: #e0cffc;
  border-color: #e0cffc;
}

.btn-indigo-200 {
  color: #000;
  background-color: #c29ffa;
  border-color: #c29ffa;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-indigo-200:hover {
  color: #000;
  background-color: #cbadfb;
  border-color: #c8a9fb;
}
.btn-check:focus + .btn-indigo-200, .btn-indigo-200:focus {
  color: #000;
  background-color: #cbadfb;
  border-color: #c8a9fb;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(165, 135, 213, 0.5);
}
.btn-check:checked + .btn-indigo-200, .btn-check:active + .btn-indigo-200, .btn-indigo-200:active, .btn-indigo-200.active, .show > .btn-indigo-200.dropdown-toggle {
  color: #000;
  background-color: #ceb2fb;
  border-color: #c8a9fb;
}
.btn-check:checked + .btn-indigo-200:focus, .btn-check:active + .btn-indigo-200:focus, .btn-indigo-200:active:focus, .btn-indigo-200.active:focus, .show > .btn-indigo-200.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(165, 135, 213, 0.5);
}
.btn-indigo-200:disabled, .btn-indigo-200.disabled {
  color: #000;
  background-color: #c29ffa;
  border-color: #c29ffa;
}

.btn-indigo-300 {
  color: #000;
  background-color: #a370f7;
  border-color: #a370f7;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-indigo-300:hover {
  color: #000;
  background-color: #b185f8;
  border-color: #ac7ef8;
}
.btn-check:focus + .btn-indigo-300, .btn-indigo-300:focus {
  color: #000;
  background-color: #b185f8;
  border-color: #ac7ef8;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(139, 95, 210, 0.5);
}
.btn-check:checked + .btn-indigo-300, .btn-check:active + .btn-indigo-300, .btn-indigo-300:active, .btn-indigo-300.active, .show > .btn-indigo-300.dropdown-toggle {
  color: #000;
  background-color: #b58df9;
  border-color: #ac7ef8;
}
.btn-check:checked + .btn-indigo-300:focus, .btn-check:active + .btn-indigo-300:focus, .btn-indigo-300:active:focus, .btn-indigo-300.active:focus, .show > .btn-indigo-300.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(139, 95, 210, 0.5);
}
.btn-indigo-300:disabled, .btn-indigo-300.disabled {
  color: #000;
  background-color: #a370f7;
  border-color: #a370f7;
}

.btn-indigo-400 {
  color: #fff;
  background-color: #8540f5;
  border-color: #8540f5;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-indigo-400:hover {
  color: #fff;
  background-color: #7136d0;
  border-color: #6a33c4;
}
.btn-check:focus + .btn-indigo-400, .btn-indigo-400:focus {
  color: #fff;
  background-color: #7136d0;
  border-color: #6a33c4;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(151, 93, 247, 0.5);
}
.btn-check:checked + .btn-indigo-400, .btn-check:active + .btn-indigo-400, .btn-indigo-400:active, .btn-indigo-400.active, .show > .btn-indigo-400.dropdown-toggle {
  color: #fff;
  background-color: #6a33c4;
  border-color: #6430b8;
}
.btn-check:checked + .btn-indigo-400:focus, .btn-check:active + .btn-indigo-400:focus, .btn-indigo-400:active:focus, .btn-indigo-400.active:focus, .show > .btn-indigo-400.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(151, 93, 247, 0.5);
}
.btn-indigo-400:disabled, .btn-indigo-400.disabled {
  color: #fff;
  background-color: #8540f5;
  border-color: #8540f5;
}

.btn-indigo-500 {
  color: #fff;
  background-color: #6610f2;
  border-color: #6610f2;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-indigo-500:hover {
  color: #fff;
  background-color: #570ece;
  border-color: #520dc2;
}
.btn-check:focus + .btn-indigo-500, .btn-indigo-500:focus {
  color: #fff;
  background-color: #570ece;
  border-color: #520dc2;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(125, 52, 244, 0.5);
}
.btn-check:checked + .btn-indigo-500, .btn-check:active + .btn-indigo-500, .btn-indigo-500:active, .btn-indigo-500.active, .show > .btn-indigo-500.dropdown-toggle {
  color: #fff;
  background-color: #520dc2;
  border-color: #4d0cb6;
}
.btn-check:checked + .btn-indigo-500:focus, .btn-check:active + .btn-indigo-500:focus, .btn-indigo-500:active:focus, .btn-indigo-500.active:focus, .show > .btn-indigo-500.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(125, 52, 244, 0.5);
}
.btn-indigo-500:disabled, .btn-indigo-500.disabled {
  color: #fff;
  background-color: #6610f2;
  border-color: #6610f2;
}

.btn-indigo-600 {
  color: #fff;
  background-color: #520dc2;
  border-color: #520dc2;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-indigo-600:hover {
  color: #fff;
  background-color: #460ba5;
  border-color: #420a9b;
}
.btn-check:focus + .btn-indigo-600, .btn-indigo-600:focus {
  color: #fff;
  background-color: #460ba5;
  border-color: #420a9b;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(108, 49, 203, 0.5);
}
.btn-check:checked + .btn-indigo-600, .btn-check:active + .btn-indigo-600, .btn-indigo-600:active, .btn-indigo-600.active, .show > .btn-indigo-600.dropdown-toggle {
  color: #fff;
  background-color: #420a9b;
  border-color: #3e0a92;
}
.btn-check:checked + .btn-indigo-600:focus, .btn-check:active + .btn-indigo-600:focus, .btn-indigo-600:active:focus, .btn-indigo-600.active:focus, .show > .btn-indigo-600.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(108, 49, 203, 0.5);
}
.btn-indigo-600:disabled, .btn-indigo-600.disabled {
  color: #fff;
  background-color: #520dc2;
  border-color: #520dc2;
}

.btn-indigo-700 {
  color: #fff;
  background-color: #3d0a91;
  border-color: #3d0a91;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-indigo-700:hover {
  color: #fff;
  background-color: #34097b;
  border-color: #310874;
}
.btn-check:focus + .btn-indigo-700, .btn-indigo-700:focus {
  color: #fff;
  background-color: #34097b;
  border-color: #310874;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(90, 47, 162, 0.5);
}
.btn-check:checked + .btn-indigo-700, .btn-check:active + .btn-indigo-700, .btn-indigo-700:active, .btn-indigo-700.active, .show > .btn-indigo-700.dropdown-toggle {
  color: #fff;
  background-color: #310874;
  border-color: #2e086d;
}
.btn-check:checked + .btn-indigo-700:focus, .btn-check:active + .btn-indigo-700:focus, .btn-indigo-700:active:focus, .btn-indigo-700.active:focus, .show > .btn-indigo-700.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(90, 47, 162, 0.5);
}
.btn-indigo-700:disabled, .btn-indigo-700.disabled {
  color: #fff;
  background-color: #3d0a91;
  border-color: #3d0a91;
}

.btn-indigo-800 {
  color: #fff;
  background-color: #290661;
  border-color: #290661;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-indigo-800:hover {
  color: #fff;
  background-color: #230552;
  border-color: #21054e;
}
.btn-check:focus + .btn-indigo-800, .btn-indigo-800:focus {
  color: #fff;
  background-color: #230552;
  border-color: #21054e;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(73, 43, 121, 0.5);
}
.btn-check:checked + .btn-indigo-800, .btn-check:active + .btn-indigo-800, .btn-indigo-800:active, .btn-indigo-800.active, .show > .btn-indigo-800.dropdown-toggle {
  color: #fff;
  background-color: #21054e;
  border-color: #1f0549;
}
.btn-check:checked + .btn-indigo-800:focus, .btn-check:active + .btn-indigo-800:focus, .btn-indigo-800:active:focus, .btn-indigo-800.active:focus, .show > .btn-indigo-800.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(73, 43, 121, 0.5);
}
.btn-indigo-800:disabled, .btn-indigo-800.disabled {
  color: #fff;
  background-color: #290661;
  border-color: #290661;
}

.btn-indigo-900 {
  color: #fff;
  background-color: #140330;
  border-color: #140330;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-indigo-900:hover {
  color: #fff;
  background-color: #110329;
  border-color: #100226;
}
.btn-check:focus + .btn-indigo-900, .btn-indigo-900:focus {
  color: #fff;
  background-color: #110329;
  border-color: #100226;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(55, 41, 79, 0.5);
}
.btn-check:checked + .btn-indigo-900, .btn-check:active + .btn-indigo-900, .btn-indigo-900:active, .btn-indigo-900.active, .show > .btn-indigo-900.dropdown-toggle {
  color: #fff;
  background-color: #100226;
  border-color: #0f0224;
}
.btn-check:checked + .btn-indigo-900:focus, .btn-check:active + .btn-indigo-900:focus, .btn-indigo-900:active:focus, .btn-indigo-900.active:focus, .show > .btn-indigo-900.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(55, 41, 79, 0.5);
}
.btn-indigo-900:disabled, .btn-indigo-900.disabled {
  color: #fff;
  background-color: #140330;
  border-color: #140330;
}

.btn-purple-100 {
  color: #000;
  background-color: #e2d9f3;
  border-color: #e2d9f3;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-purple-100:hover {
  color: #000;
  background-color: #e6dff5;
  border-color: #e5ddf4;
}
.btn-check:focus + .btn-purple-100, .btn-purple-100:focus {
  color: #000;
  background-color: #e6dff5;
  border-color: #e5ddf4;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(192, 184, 207, 0.5);
}
.btn-check:checked + .btn-purple-100, .btn-check:active + .btn-purple-100, .btn-purple-100:active, .btn-purple-100.active, .show > .btn-purple-100.dropdown-toggle {
  color: #000;
  background-color: #e8e1f5;
  border-color: #e5ddf4;
}
.btn-check:checked + .btn-purple-100:focus, .btn-check:active + .btn-purple-100:focus, .btn-purple-100:active:focus, .btn-purple-100.active:focus, .show > .btn-purple-100.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(192, 184, 207, 0.5);
}
.btn-purple-100:disabled, .btn-purple-100.disabled {
  color: #000;
  background-color: #e2d9f3;
  border-color: #e2d9f3;
}

.btn-purple-200 {
  color: #000;
  background-color: #c5b3e6;
  border-color: #c5b3e6;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-purple-200:hover {
  color: #000;
  background-color: #cebeea;
  border-color: #cbbbe9;
}
.btn-check:focus + .btn-purple-200, .btn-purple-200:focus {
  color: #000;
  background-color: #cebeea;
  border-color: #cbbbe9;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(167, 152, 196, 0.5);
}
.btn-check:checked + .btn-purple-200, .btn-check:active + .btn-purple-200, .btn-purple-200:active, .btn-purple-200.active, .show > .btn-purple-200.dropdown-toggle {
  color: #000;
  background-color: #d1c2eb;
  border-color: #cbbbe9;
}
.btn-check:checked + .btn-purple-200:focus, .btn-check:active + .btn-purple-200:focus, .btn-purple-200:active:focus, .btn-purple-200.active:focus, .show > .btn-purple-200.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(167, 152, 196, 0.5);
}
.btn-purple-200:disabled, .btn-purple-200.disabled {
  color: #000;
  background-color: #c5b3e6;
  border-color: #c5b3e6;
}

.btn-purple-300 {
  color: #000;
  background-color: #a98eda;
  border-color: #a98eda;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-purple-300:hover {
  color: #000;
  background-color: #b69fe0;
  border-color: #b299de;
}
.btn-check:focus + .btn-purple-300, .btn-purple-300:focus {
  color: #000;
  background-color: #b69fe0;
  border-color: #b299de;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(144, 121, 185, 0.5);
}
.btn-check:checked + .btn-purple-300, .btn-check:active + .btn-purple-300, .btn-purple-300:active, .btn-purple-300.active, .show > .btn-purple-300.dropdown-toggle {
  color: #000;
  background-color: #baa5e1;
  border-color: #b299de;
}
.btn-check:checked + .btn-purple-300:focus, .btn-check:active + .btn-purple-300:focus, .btn-purple-300:active:focus, .btn-purple-300.active:focus, .show > .btn-purple-300.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(144, 121, 185, 0.5);
}
.btn-purple-300:disabled, .btn-purple-300.disabled {
  color: #000;
  background-color: #a98eda;
  border-color: #a98eda;
}

.btn-purple-400 {
  color: #000;
  background-color: #8c68cd;
  border-color: #8c68cd;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-purple-400:hover {
  color: #000;
  background-color: #9d7fd5;
  border-color: #9877d2;
}
.btn-check:focus + .btn-purple-400, .btn-purple-400:focus {
  color: #000;
  background-color: #9d7fd5;
  border-color: #9877d2;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(119, 88, 174, 0.5);
}
.btn-check:checked + .btn-purple-400, .btn-check:active + .btn-purple-400, .btn-purple-400:active, .btn-purple-400.active, .show > .btn-purple-400.dropdown-toggle {
  color: #000;
  background-color: #a386d7;
  border-color: #9877d2;
}
.btn-check:checked + .btn-purple-400:focus, .btn-check:active + .btn-purple-400:focus, .btn-purple-400:active:focus, .btn-purple-400.active:focus, .show > .btn-purple-400.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(119, 88, 174, 0.5);
}
.btn-purple-400:disabled, .btn-purple-400.disabled {
  color: #000;
  background-color: #8c68cd;
  border-color: #8c68cd;
}

.btn-purple-500 {
  color: #fff;
  background-color: #6f42c1;
  border-color: #6f42c1;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-purple-500:hover {
  color: #fff;
  background-color: #5e38a4;
  border-color: #59359a;
}
.btn-check:focus + .btn-purple-500, .btn-purple-500:focus {
  color: #fff;
  background-color: #5e38a4;
  border-color: #59359a;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(133, 94, 202, 0.5);
}
.btn-check:checked + .btn-purple-500, .btn-check:active + .btn-purple-500, .btn-purple-500:active, .btn-purple-500.active, .show > .btn-purple-500.dropdown-toggle {
  color: #fff;
  background-color: #59359a;
  border-color: #533291;
}
.btn-check:checked + .btn-purple-500:focus, .btn-check:active + .btn-purple-500:focus, .btn-purple-500:active:focus, .btn-purple-500.active:focus, .show > .btn-purple-500.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(133, 94, 202, 0.5);
}
.btn-purple-500:disabled, .btn-purple-500.disabled {
  color: #fff;
  background-color: #6f42c1;
  border-color: #6f42c1;
}

.btn-purple-600 {
  color: #fff;
  background-color: #59359a;
  border-color: #59359a;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-purple-600:hover {
  color: #fff;
  background-color: #4c2d83;
  border-color: #472a7b;
}
.btn-check:focus + .btn-purple-600, .btn-purple-600:focus {
  color: #fff;
  background-color: #4c2d83;
  border-color: #472a7b;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(114, 83, 169, 0.5);
}
.btn-check:checked + .btn-purple-600, .btn-check:active + .btn-purple-600, .btn-purple-600:active, .btn-purple-600.active, .show > .btn-purple-600.dropdown-toggle {
  color: #fff;
  background-color: #472a7b;
  border-color: #432874;
}
.btn-check:checked + .btn-purple-600:focus, .btn-check:active + .btn-purple-600:focus, .btn-purple-600:active:focus, .btn-purple-600.active:focus, .show > .btn-purple-600.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(114, 83, 169, 0.5);
}
.btn-purple-600:disabled, .btn-purple-600.disabled {
  color: #fff;
  background-color: #59359a;
  border-color: #59359a;
}

.btn-purple-700 {
  color: #fff;
  background-color: #432874;
  border-color: #432874;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-purple-700:hover {
  color: #fff;
  background-color: #392263;
  border-color: #36205d;
}
.btn-check:focus + .btn-purple-700, .btn-purple-700:focus {
  color: #fff;
  background-color: #392263;
  border-color: #36205d;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(95, 72, 137, 0.5);
}
.btn-check:checked + .btn-purple-700, .btn-check:active + .btn-purple-700, .btn-purple-700:active, .btn-purple-700.active, .show > .btn-purple-700.dropdown-toggle {
  color: #fff;
  background-color: #36205d;
  border-color: #321e57;
}
.btn-check:checked + .btn-purple-700:focus, .btn-check:active + .btn-purple-700:focus, .btn-purple-700:active:focus, .btn-purple-700.active:focus, .show > .btn-purple-700.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(95, 72, 137, 0.5);
}
.btn-purple-700:disabled, .btn-purple-700.disabled {
  color: #fff;
  background-color: #432874;
  border-color: #432874;
}

.btn-purple-800 {
  color: #fff;
  background-color: #2c1a4d;
  border-color: #2c1a4d;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-purple-800:hover {
  color: #fff;
  background-color: #251641;
  border-color: #23153e;
}
.btn-check:focus + .btn-purple-800, .btn-purple-800:focus {
  color: #fff;
  background-color: #251641;
  border-color: #23153e;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(76, 60, 104, 0.5);
}
.btn-check:checked + .btn-purple-800, .btn-check:active + .btn-purple-800, .btn-purple-800:active, .btn-purple-800.active, .show > .btn-purple-800.dropdown-toggle {
  color: #fff;
  background-color: #23153e;
  border-color: #21143a;
}
.btn-check:checked + .btn-purple-800:focus, .btn-check:active + .btn-purple-800:focus, .btn-purple-800:active:focus, .btn-purple-800.active:focus, .show > .btn-purple-800.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(76, 60, 104, 0.5);
}
.btn-purple-800:disabled, .btn-purple-800.disabled {
  color: #fff;
  background-color: #2c1a4d;
  border-color: #2c1a4d;
}

.btn-purple-900 {
  color: #fff;
  background-color: #160d27;
  border-color: #160d27;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-purple-900:hover {
  color: #fff;
  background-color: #130b21;
  border-color: #120a1f;
}
.btn-check:focus + .btn-purple-900, .btn-purple-900:focus {
  color: #fff;
  background-color: #130b21;
  border-color: #120a1f;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(57, 49, 71, 0.5);
}
.btn-check:checked + .btn-purple-900, .btn-check:active + .btn-purple-900, .btn-purple-900:active, .btn-purple-900.active, .show > .btn-purple-900.dropdown-toggle {
  color: #fff;
  background-color: #120a1f;
  border-color: #110a1d;
}
.btn-check:checked + .btn-purple-900:focus, .btn-check:active + .btn-purple-900:focus, .btn-purple-900:active:focus, .btn-purple-900.active:focus, .show > .btn-purple-900.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(57, 49, 71, 0.5);
}
.btn-purple-900:disabled, .btn-purple-900.disabled {
  color: #fff;
  background-color: #160d27;
  border-color: #160d27;
}

.btn-pink-100 {
  color: #000;
  background-color: #f7d6e6;
  border-color: #f7d6e6;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-pink-100:hover {
  color: #000;
  background-color: #f8dcea;
  border-color: #f8dae9;
}
.btn-check:focus + .btn-pink-100, .btn-pink-100:focus {
  color: #000;
  background-color: #f8dcea;
  border-color: #f8dae9;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(210, 182, 196, 0.5);
}
.btn-check:checked + .btn-pink-100, .btn-check:active + .btn-pink-100, .btn-pink-100:active, .btn-pink-100.active, .show > .btn-pink-100.dropdown-toggle {
  color: #000;
  background-color: #f9deeb;
  border-color: #f8dae9;
}
.btn-check:checked + .btn-pink-100:focus, .btn-check:active + .btn-pink-100:focus, .btn-pink-100:active:focus, .btn-pink-100.active:focus, .show > .btn-pink-100.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(210, 182, 196, 0.5);
}
.btn-pink-100:disabled, .btn-pink-100.disabled {
  color: #000;
  background-color: #f7d6e6;
  border-color: #f7d6e6;
}

.btn-pink-200 {
  color: #000;
  background-color: #efadce;
  border-color: #efadce;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-pink-200:hover {
  color: #000;
  background-color: #f1b9d5;
  border-color: #f1b5d3;
}
.btn-check:focus + .btn-pink-200, .btn-pink-200:focus {
  color: #000;
  background-color: #f1b9d5;
  border-color: #f1b5d3;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(203, 147, 175, 0.5);
}
.btn-check:checked + .btn-pink-200, .btn-check:active + .btn-pink-200, .btn-pink-200:active, .btn-pink-200.active, .show > .btn-pink-200.dropdown-toggle {
  color: #000;
  background-color: #f2bdd8;
  border-color: #f1b5d3;
}
.btn-check:checked + .btn-pink-200:focus, .btn-check:active + .btn-pink-200:focus, .btn-pink-200:active:focus, .btn-pink-200.active:focus, .show > .btn-pink-200.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(203, 147, 175, 0.5);
}
.btn-pink-200:disabled, .btn-pink-200.disabled {
  color: #000;
  background-color: #efadce;
  border-color: #efadce;
}

.btn-pink-300 {
  color: #000;
  background-color: #e685b5;
  border-color: #e685b5;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-pink-300:hover {
  color: #000;
  background-color: #ea97c0;
  border-color: #e991bc;
}
.btn-check:focus + .btn-pink-300, .btn-pink-300:focus {
  color: #000;
  background-color: #ea97c0;
  border-color: #e991bc;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(196, 113, 154, 0.5);
}
.btn-check:checked + .btn-pink-300, .btn-check:active + .btn-pink-300, .btn-pink-300:active, .btn-pink-300.active, .show > .btn-pink-300.dropdown-toggle {
  color: #000;
  background-color: #eb9dc4;
  border-color: #e991bc;
}
.btn-check:checked + .btn-pink-300:focus, .btn-check:active + .btn-pink-300:focus, .btn-pink-300:active:focus, .btn-pink-300.active:focus, .show > .btn-pink-300.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(196, 113, 154, 0.5);
}
.btn-pink-300:disabled, .btn-pink-300.disabled {
  color: #000;
  background-color: #e685b5;
  border-color: #e685b5;
}

.btn-pink-400 {
  color: #000;
  background-color: #de5c9d;
  border-color: #de5c9d;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-pink-400:hover {
  color: #000;
  background-color: #e374ac;
  border-color: #e16ca7;
}
.btn-check:focus + .btn-pink-400, .btn-pink-400:focus {
  color: #000;
  background-color: #e374ac;
  border-color: #e16ca7;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(189, 78, 133, 0.5);
}
.btn-check:checked + .btn-pink-400, .btn-check:active + .btn-pink-400, .btn-pink-400:active, .btn-pink-400.active, .show > .btn-pink-400.dropdown-toggle {
  color: #000;
  background-color: #e57db1;
  border-color: #e16ca7;
}
.btn-check:checked + .btn-pink-400:focus, .btn-check:active + .btn-pink-400:focus, .btn-pink-400:active:focus, .btn-pink-400.active:focus, .show > .btn-pink-400.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(189, 78, 133, 0.5);
}
.btn-pink-400:disabled, .btn-pink-400.disabled {
  color: #000;
  background-color: #de5c9d;
  border-color: #de5c9d;
}

.btn-pink-500 {
  color: #fff;
  background-color: #d63384;
  border-color: #d63384;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-pink-500:hover {
  color: #fff;
  background-color: #b62b70;
  border-color: #ab296a;
}
.btn-check:focus + .btn-pink-500, .btn-pink-500:focus {
  color: #fff;
  background-color: #b62b70;
  border-color: #ab296a;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(220, 82, 150, 0.5);
}
.btn-check:checked + .btn-pink-500, .btn-check:active + .btn-pink-500, .btn-pink-500:active, .btn-pink-500.active, .show > .btn-pink-500.dropdown-toggle {
  color: #fff;
  background-color: #ab296a;
  border-color: #a12663;
}
.btn-check:checked + .btn-pink-500:focus, .btn-check:active + .btn-pink-500:focus, .btn-pink-500:active:focus, .btn-pink-500.active:focus, .show > .btn-pink-500.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(220, 82, 150, 0.5);
}
.btn-pink-500:disabled, .btn-pink-500.disabled {
  color: #fff;
  background-color: #d63384;
  border-color: #d63384;
}

.btn-pink-600 {
  color: #fff;
  background-color: #ab296a;
  border-color: #ab296a;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-pink-600:hover {
  color: #fff;
  background-color: #91235a;
  border-color: #892155;
}
.btn-check:focus + .btn-pink-600, .btn-pink-600:focus {
  color: #fff;
  background-color: #91235a;
  border-color: #892155;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(184, 73, 128, 0.5);
}
.btn-check:checked + .btn-pink-600, .btn-check:active + .btn-pink-600, .btn-pink-600:active, .btn-pink-600.active, .show > .btn-pink-600.dropdown-toggle {
  color: #fff;
  background-color: #892155;
  border-color: #801f50;
}
.btn-check:checked + .btn-pink-600:focus, .btn-check:active + .btn-pink-600:focus, .btn-pink-600:active:focus, .btn-pink-600.active:focus, .show > .btn-pink-600.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(184, 73, 128, 0.5);
}
.btn-pink-600:disabled, .btn-pink-600.disabled {
  color: #fff;
  background-color: #ab296a;
  border-color: #ab296a;
}

.btn-pink-700 {
  color: #fff;
  background-color: #801f4f;
  border-color: #801f4f;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-pink-700:hover {
  color: #fff;
  background-color: #6d1a43;
  border-color: #66193f;
}
.btn-check:focus + .btn-pink-700, .btn-pink-700:focus {
  color: #fff;
  background-color: #6d1a43;
  border-color: #66193f;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(147, 65, 105, 0.5);
}
.btn-check:checked + .btn-pink-700, .btn-check:active + .btn-pink-700, .btn-pink-700:active, .btn-pink-700.active, .show > .btn-pink-700.dropdown-toggle {
  color: #fff;
  background-color: #66193f;
  border-color: #60173b;
}
.btn-check:checked + .btn-pink-700:focus, .btn-check:active + .btn-pink-700:focus, .btn-pink-700:active:focus, .btn-pink-700.active:focus, .show > .btn-pink-700.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(147, 65, 105, 0.5);
}
.btn-pink-700:disabled, .btn-pink-700.disabled {
  color: #fff;
  background-color: #801f4f;
  border-color: #801f4f;
}

.btn-pink-800 {
  color: #fff;
  background-color: #561435;
  border-color: #561435;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-pink-800:hover {
  color: #fff;
  background-color: #49112d;
  border-color: #45102a;
}
.btn-check:focus + .btn-pink-800, .btn-pink-800:focus {
  color: #fff;
  background-color: #49112d;
  border-color: #45102a;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(111, 55, 83, 0.5);
}
.btn-check:checked + .btn-pink-800, .btn-check:active + .btn-pink-800, .btn-pink-800:active, .btn-pink-800.active, .show > .btn-pink-800.dropdown-toggle {
  color: #fff;
  background-color: #45102a;
  border-color: #410f28;
}
.btn-check:checked + .btn-pink-800:focus, .btn-check:active + .btn-pink-800:focus, .btn-pink-800:active:focus, .btn-pink-800.active:focus, .show > .btn-pink-800.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(111, 55, 83, 0.5);
}
.btn-pink-800:disabled, .btn-pink-800.disabled {
  color: #fff;
  background-color: #561435;
  border-color: #561435;
}

.btn-pink-900 {
  color: #fff;
  background-color: #2b0a1a;
  border-color: #2b0a1a;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-pink-900:hover {
  color: #fff;
  background-color: #250916;
  border-color: #220815;
}
.btn-check:focus + .btn-pink-900, .btn-pink-900:focus {
  color: #fff;
  background-color: #250916;
  border-color: #220815;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(75, 47, 60, 0.5);
}
.btn-check:checked + .btn-pink-900, .btn-check:active + .btn-pink-900, .btn-pink-900:active, .btn-pink-900.active, .show > .btn-pink-900.dropdown-toggle {
  color: #fff;
  background-color: #220815;
  border-color: #200814;
}
.btn-check:checked + .btn-pink-900:focus, .btn-check:active + .btn-pink-900:focus, .btn-pink-900:active:focus, .btn-pink-900.active:focus, .show > .btn-pink-900.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(75, 47, 60, 0.5);
}
.btn-pink-900:disabled, .btn-pink-900.disabled {
  color: #fff;
  background-color: #2b0a1a;
  border-color: #2b0a1a;
}

.btn-red-100 {
  color: #000;
  background-color: #f8d7da;
  border-color: #f8d7da;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-red-100:hover {
  color: #000;
  background-color: #f9dde0;
  border-color: #f9dbde;
}
.btn-check:focus + .btn-red-100, .btn-red-100:focus {
  color: #000;
  background-color: #f9dde0;
  border-color: #f9dbde;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(211, 183, 185, 0.5);
}
.btn-check:checked + .btn-red-100, .btn-check:active + .btn-red-100, .btn-red-100:active, .btn-red-100.active, .show > .btn-red-100.dropdown-toggle {
  color: #000;
  background-color: #f9dfe1;
  border-color: #f9dbde;
}
.btn-check:checked + .btn-red-100:focus, .btn-check:active + .btn-red-100:focus, .btn-red-100:active:focus, .btn-red-100.active:focus, .show > .btn-red-100.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(211, 183, 185, 0.5);
}
.btn-red-100:disabled, .btn-red-100.disabled {
  color: #000;
  background-color: #f8d7da;
  border-color: #f8d7da;
}

.btn-red-200 {
  color: #000;
  background-color: #f1aeb5;
  border-color: #f1aeb5;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-red-200:hover {
  color: #000;
  background-color: #f3bac0;
  border-color: #f2b6bc;
}
.btn-check:focus + .btn-red-200, .btn-red-200:focus {
  color: #000;
  background-color: #f3bac0;
  border-color: #f2b6bc;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(205, 148, 154, 0.5);
}
.btn-check:checked + .btn-red-200, .btn-check:active + .btn-red-200, .btn-red-200:active, .btn-red-200.active, .show > .btn-red-200.dropdown-toggle {
  color: #000;
  background-color: #f4bec4;
  border-color: #f2b6bc;
}
.btn-check:checked + .btn-red-200:focus, .btn-check:active + .btn-red-200:focus, .btn-red-200:active:focus, .btn-red-200.active:focus, .show > .btn-red-200.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(205, 148, 154, 0.5);
}
.btn-red-200:disabled, .btn-red-200.disabled {
  color: #000;
  background-color: #f1aeb5;
  border-color: #f1aeb5;
}

.btn-red-300 {
  color: #000;
  background-color: #ea868f;
  border-color: #ea868f;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-red-300:hover {
  color: #000;
  background-color: #ed98a0;
  border-color: #ec929a;
}
.btn-check:focus + .btn-red-300, .btn-red-300:focus {
  color: #000;
  background-color: #ed98a0;
  border-color: #ec929a;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(199, 114, 122, 0.5);
}
.btn-check:checked + .btn-red-300, .btn-check:active + .btn-red-300, .btn-red-300:active, .btn-red-300.active, .show > .btn-red-300.dropdown-toggle {
  color: #000;
  background-color: #ee9ea5;
  border-color: #ec929a;
}
.btn-check:checked + .btn-red-300:focus, .btn-check:active + .btn-red-300:focus, .btn-red-300:active:focus, .btn-red-300.active:focus, .show > .btn-red-300.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(199, 114, 122, 0.5);
}
.btn-red-300:disabled, .btn-red-300.disabled {
  color: #000;
  background-color: #ea868f;
  border-color: #ea868f;
}

.btn-red-400 {
  color: #000;
  background-color: #e35d6a;
  border-color: #e35d6a;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-red-400:hover {
  color: #000;
  background-color: #e77580;
  border-color: #e66d79;
}
.btn-check:focus + .btn-red-400, .btn-red-400:focus {
  color: #000;
  background-color: #e77580;
  border-color: #e66d79;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(193, 79, 90, 0.5);
}
.btn-check:checked + .btn-red-400, .btn-check:active + .btn-red-400, .btn-red-400:active, .btn-red-400.active, .show > .btn-red-400.dropdown-toggle {
  color: #000;
  background-color: #e97d88;
  border-color: #e66d79;
}
.btn-check:checked + .btn-red-400:focus, .btn-check:active + .btn-red-400:focus, .btn-red-400:active:focus, .btn-red-400.active:focus, .show > .btn-red-400.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(193, 79, 90, 0.5);
}
.btn-red-400:disabled, .btn-red-400.disabled {
  color: #000;
  background-color: #e35d6a;
  border-color: #e35d6a;
}

.btn-red-500 {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-red-500:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}
.btn-check:focus + .btn-red-500, .btn-red-500:focus {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-check:checked + .btn-red-500, .btn-check:active + .btn-red-500, .btn-red-500:active, .btn-red-500.active, .show > .btn-red-500.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834;
}
.btn-check:checked + .btn-red-500:focus, .btn-check:active + .btn-red-500:focus, .btn-red-500:active:focus, .btn-red-500.active:focus, .show > .btn-red-500.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-red-500:disabled, .btn-red-500.disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-red-600 {
  color: #fff;
  background-color: #b02a37;
  border-color: #b02a37;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-red-600:hover {
  color: #fff;
  background-color: #96242f;
  border-color: #8d222c;
}
.btn-check:focus + .btn-red-600, .btn-red-600:focus {
  color: #fff;
  background-color: #96242f;
  border-color: #8d222c;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(188, 74, 85, 0.5);
}
.btn-check:checked + .btn-red-600, .btn-check:active + .btn-red-600, .btn-red-600:active, .btn-red-600.active, .show > .btn-red-600.dropdown-toggle {
  color: #fff;
  background-color: #8d222c;
  border-color: #842029;
}
.btn-check:checked + .btn-red-600:focus, .btn-check:active + .btn-red-600:focus, .btn-red-600:active:focus, .btn-red-600.active:focus, .show > .btn-red-600.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(188, 74, 85, 0.5);
}
.btn-red-600:disabled, .btn-red-600.disabled {
  color: #fff;
  background-color: #b02a37;
  border-color: #b02a37;
}

.btn-red-700 {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-red-700:hover {
  color: #fff;
  background-color: #701b23;
  border-color: #6a1a21;
}
.btn-check:focus + .btn-red-700, .btn-red-700:focus {
  color: #fff;
  background-color: #701b23;
  border-color: #6a1a21;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(150, 65, 73, 0.5);
}
.btn-check:checked + .btn-red-700, .btn-check:active + .btn-red-700, .btn-red-700:active, .btn-red-700.active, .show > .btn-red-700.dropdown-toggle {
  color: #fff;
  background-color: #6a1a21;
  border-color: #63181f;
}
.btn-check:checked + .btn-red-700:focus, .btn-check:active + .btn-red-700:focus, .btn-red-700:active:focus, .btn-red-700.active:focus, .show > .btn-red-700.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(150, 65, 73, 0.5);
}
.btn-red-700:disabled, .btn-red-700.disabled {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.btn-red-800 {
  color: #fff;
  background-color: #58151c;
  border-color: #58151c;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-red-800:hover {
  color: #fff;
  background-color: #4b1218;
  border-color: #461116;
}
.btn-check:focus + .btn-red-800, .btn-red-800:focus {
  color: #fff;
  background-color: #4b1218;
  border-color: #461116;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(113, 56, 62, 0.5);
}
.btn-check:checked + .btn-red-800, .btn-check:active + .btn-red-800, .btn-red-800:active, .btn-red-800.active, .show > .btn-red-800.dropdown-toggle {
  color: #fff;
  background-color: #461116;
  border-color: #421015;
}
.btn-check:checked + .btn-red-800:focus, .btn-check:active + .btn-red-800:focus, .btn-red-800:active:focus, .btn-red-800.active:focus, .show > .btn-red-800.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(113, 56, 62, 0.5);
}
.btn-red-800:disabled, .btn-red-800.disabled {
  color: #fff;
  background-color: #58151c;
  border-color: #58151c;
}

.btn-red-900 {
  color: #fff;
  background-color: #2c0b0e;
  border-color: #2c0b0e;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-red-900:hover {
  color: #fff;
  background-color: #25090c;
  border-color: #23090b;
}
.btn-check:focus + .btn-red-900, .btn-red-900:focus {
  color: #fff;
  background-color: #25090c;
  border-color: #23090b;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(76, 48, 50, 0.5);
}
.btn-check:checked + .btn-red-900, .btn-check:active + .btn-red-900, .btn-red-900:active, .btn-red-900.active, .show > .btn-red-900.dropdown-toggle {
  color: #fff;
  background-color: #23090b;
  border-color: #21080b;
}
.btn-check:checked + .btn-red-900:focus, .btn-check:active + .btn-red-900:focus, .btn-red-900:active:focus, .btn-red-900.active:focus, .show > .btn-red-900.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(76, 48, 50, 0.5);
}
.btn-red-900:disabled, .btn-red-900.disabled {
  color: #fff;
  background-color: #2c0b0e;
  border-color: #2c0b0e;
}

.btn-orange-100 {
  color: #000;
  background-color: #ffe5d0;
  border-color: #ffe5d0;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-orange-100:hover {
  color: #000;
  background-color: #ffe9d7;
  border-color: #ffe8d5;
}
.btn-check:focus + .btn-orange-100, .btn-orange-100:focus {
  color: #000;
  background-color: #ffe9d7;
  border-color: #ffe8d5;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(217, 195, 177, 0.5);
}
.btn-check:checked + .btn-orange-100, .btn-check:active + .btn-orange-100, .btn-orange-100:active, .btn-orange-100.active, .show > .btn-orange-100.dropdown-toggle {
  color: #000;
  background-color: #ffead9;
  border-color: #ffe8d5;
}
.btn-check:checked + .btn-orange-100:focus, .btn-check:active + .btn-orange-100:focus, .btn-orange-100:active:focus, .btn-orange-100.active:focus, .show > .btn-orange-100.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(217, 195, 177, 0.5);
}
.btn-orange-100:disabled, .btn-orange-100.disabled {
  color: #000;
  background-color: #ffe5d0;
  border-color: #ffe5d0;
}

.btn-orange-200 {
  color: #000;
  background-color: #fecba1;
  border-color: #fecba1;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-orange-200:hover {
  color: #000;
  background-color: #fed3af;
  border-color: #fed0aa;
}
.btn-check:focus + .btn-orange-200, .btn-orange-200:focus {
  color: #000;
  background-color: #fed3af;
  border-color: #fed0aa;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(216, 173, 137, 0.5);
}
.btn-check:checked + .btn-orange-200, .btn-check:active + .btn-orange-200, .btn-orange-200:active, .btn-orange-200.active, .show > .btn-orange-200.dropdown-toggle {
  color: #000;
  background-color: #fed5b4;
  border-color: #fed0aa;
}
.btn-check:checked + .btn-orange-200:focus, .btn-check:active + .btn-orange-200:focus, .btn-orange-200:active:focus, .btn-orange-200.active:focus, .show > .btn-orange-200.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(216, 173, 137, 0.5);
}
.btn-orange-200:disabled, .btn-orange-200.disabled {
  color: #000;
  background-color: #fecba1;
  border-color: #fecba1;
}

.btn-orange-300 {
  color: #000;
  background-color: #feb272;
  border-color: #feb272;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-orange-300:hover {
  color: #000;
  background-color: #febe87;
  border-color: #feba80;
}
.btn-check:focus + .btn-orange-300, .btn-orange-300:focus {
  color: #000;
  background-color: #febe87;
  border-color: #feba80;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(216, 151, 97, 0.5);
}
.btn-check:checked + .btn-orange-300, .btn-check:active + .btn-orange-300, .btn-orange-300:active, .btn-orange-300.active, .show > .btn-orange-300.dropdown-toggle {
  color: #000;
  background-color: #fec18e;
  border-color: #feba80;
}
.btn-check:checked + .btn-orange-300:focus, .btn-check:active + .btn-orange-300:focus, .btn-orange-300:active:focus, .btn-orange-300.active:focus, .show > .btn-orange-300.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(216, 151, 97, 0.5);
}
.btn-orange-300:disabled, .btn-orange-300.disabled {
  color: #000;
  background-color: #feb272;
  border-color: #feb272;
}

.btn-orange-400 {
  color: #000;
  background-color: #fd9843;
  border-color: #fd9843;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-orange-400:hover {
  color: #000;
  background-color: #fda75f;
  border-color: #fda256;
}
.btn-check:focus + .btn-orange-400, .btn-orange-400:focus {
  color: #000;
  background-color: #fda75f;
  border-color: #fda256;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(215, 129, 57, 0.5);
}
.btn-check:checked + .btn-orange-400, .btn-check:active + .btn-orange-400, .btn-orange-400:active, .btn-orange-400.active, .show > .btn-orange-400.dropdown-toggle {
  color: #000;
  background-color: #fdad69;
  border-color: #fda256;
}
.btn-check:checked + .btn-orange-400:focus, .btn-check:active + .btn-orange-400:focus, .btn-orange-400:active:focus, .btn-orange-400.active:focus, .show > .btn-orange-400.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(215, 129, 57, 0.5);
}
.btn-orange-400:disabled, .btn-orange-400.disabled {
  color: #000;
  background-color: #fd9843;
  border-color: #fd9843;
}

.btn-orange-500 {
  color: #000;
  background-color: #fd7e14;
  border-color: #fd7e14;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-orange-500:hover {
  color: #000;
  background-color: #fd9137;
  border-color: #fd8b2c;
}
.btn-check:focus + .btn-orange-500, .btn-orange-500:focus {
  color: #000;
  background-color: #fd9137;
  border-color: #fd8b2c;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(215, 107, 17, 0.5);
}
.btn-check:checked + .btn-orange-500, .btn-check:active + .btn-orange-500, .btn-orange-500:active, .btn-orange-500.active, .show > .btn-orange-500.dropdown-toggle {
  color: #000;
  background-color: #fd9843;
  border-color: #fd8b2c;
}
.btn-check:checked + .btn-orange-500:focus, .btn-check:active + .btn-orange-500:focus, .btn-orange-500:active:focus, .btn-orange-500.active:focus, .show > .btn-orange-500.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(215, 107, 17, 0.5);
}
.btn-orange-500:disabled, .btn-orange-500.disabled {
  color: #000;
  background-color: #fd7e14;
  border-color: #fd7e14;
}

.btn-orange-600 {
  color: #000;
  background-color: #ca6510;
  border-color: #ca6510;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-orange-600:hover {
  color: #000;
  background-color: #d27c34;
  border-color: #cf7428;
}
.btn-check:focus + .btn-orange-600, .btn-orange-600:focus {
  color: #000;
  background-color: #d27c34;
  border-color: #cf7428;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(172, 86, 14, 0.5);
}
.btn-check:checked + .btn-orange-600, .btn-check:active + .btn-orange-600, .btn-orange-600:active, .btn-orange-600.active, .show > .btn-orange-600.dropdown-toggle {
  color: #000;
  background-color: #d58440;
  border-color: #cf7428;
}
.btn-check:checked + .btn-orange-600:focus, .btn-check:active + .btn-orange-600:focus, .btn-orange-600:active:focus, .btn-orange-600.active:focus, .show > .btn-orange-600.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(172, 86, 14, 0.5);
}
.btn-orange-600:disabled, .btn-orange-600.disabled {
  color: #000;
  background-color: #ca6510;
  border-color: #ca6510;
}

.btn-orange-700 {
  color: #fff;
  background-color: #984c0c;
  border-color: #984c0c;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-orange-700:hover {
  color: #fff;
  background-color: #81410a;
  border-color: #7a3d0a;
}
.btn-check:focus + .btn-orange-700, .btn-orange-700:focus {
  color: #fff;
  background-color: #81410a;
  border-color: #7a3d0a;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(167, 103, 48, 0.5);
}
.btn-check:checked + .btn-orange-700, .btn-check:active + .btn-orange-700, .btn-orange-700:active, .btn-orange-700.active, .show > .btn-orange-700.dropdown-toggle {
  color: #fff;
  background-color: #7a3d0a;
  border-color: #723909;
}
.btn-check:checked + .btn-orange-700:focus, .btn-check:active + .btn-orange-700:focus, .btn-orange-700:active:focus, .btn-orange-700.active:focus, .show > .btn-orange-700.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(167, 103, 48, 0.5);
}
.btn-orange-700:disabled, .btn-orange-700.disabled {
  color: #fff;
  background-color: #984c0c;
  border-color: #984c0c;
}

.btn-orange-800 {
  color: #fff;
  background-color: #653208;
  border-color: #653208;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-orange-800:hover {
  color: #fff;
  background-color: #562b07;
  border-color: #512806;
}
.btn-check:focus + .btn-orange-800, .btn-orange-800:focus {
  color: #fff;
  background-color: #562b07;
  border-color: #512806;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(124, 81, 45, 0.5);
}
.btn-check:checked + .btn-orange-800, .btn-check:active + .btn-orange-800, .btn-orange-800:active, .btn-orange-800.active, .show > .btn-orange-800.dropdown-toggle {
  color: #fff;
  background-color: #512806;
  border-color: #4c2606;
}
.btn-check:checked + .btn-orange-800:focus, .btn-check:active + .btn-orange-800:focus, .btn-orange-800:active:focus, .btn-orange-800.active:focus, .show > .btn-orange-800.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(124, 81, 45, 0.5);
}
.btn-orange-800:disabled, .btn-orange-800.disabled {
  color: #fff;
  background-color: #653208;
  border-color: #653208;
}

.btn-orange-900 {
  color: #fff;
  background-color: #331904;
  border-color: #331904;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-orange-900:hover {
  color: #fff;
  background-color: #2b1503;
  border-color: #291403;
}
.btn-check:focus + .btn-orange-900, .btn-orange-900:focus {
  color: #fff;
  background-color: #2b1503;
  border-color: #291403;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(82, 60, 42, 0.5);
}
.btn-check:checked + .btn-orange-900, .btn-check:active + .btn-orange-900, .btn-orange-900:active, .btn-orange-900.active, .show > .btn-orange-900.dropdown-toggle {
  color: #fff;
  background-color: #291403;
  border-color: #261303;
}
.btn-check:checked + .btn-orange-900:focus, .btn-check:active + .btn-orange-900:focus, .btn-orange-900:active:focus, .btn-orange-900.active:focus, .show > .btn-orange-900.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(82, 60, 42, 0.5);
}
.btn-orange-900:disabled, .btn-orange-900.disabled {
  color: #fff;
  background-color: #331904;
  border-color: #331904;
}

.btn-yellow-100 {
  color: #000;
  background-color: #fff3cd;
  border-color: #fff3cd;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-yellow-100:hover {
  color: #000;
  background-color: #fff5d5;
  border-color: #fff4d2;
}
.btn-check:focus + .btn-yellow-100, .btn-yellow-100:focus {
  color: #000;
  background-color: #fff5d5;
  border-color: #fff4d2;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(217, 207, 174, 0.5);
}
.btn-check:checked + .btn-yellow-100, .btn-check:active + .btn-yellow-100, .btn-yellow-100:active, .btn-yellow-100.active, .show > .btn-yellow-100.dropdown-toggle {
  color: #000;
  background-color: #fff5d7;
  border-color: #fff4d2;
}
.btn-check:checked + .btn-yellow-100:focus, .btn-check:active + .btn-yellow-100:focus, .btn-yellow-100:active:focus, .btn-yellow-100.active:focus, .show > .btn-yellow-100.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(217, 207, 174, 0.5);
}
.btn-yellow-100:disabled, .btn-yellow-100.disabled {
  color: #000;
  background-color: #fff3cd;
  border-color: #fff3cd;
}

.btn-yellow-200 {
  color: #000;
  background-color: #ffe69c;
  border-color: #ffe69c;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-yellow-200:hover {
  color: #000;
  background-color: #ffeaab;
  border-color: #ffe9a6;
}
.btn-check:focus + .btn-yellow-200, .btn-yellow-200:focus {
  color: #000;
  background-color: #ffeaab;
  border-color: #ffe9a6;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(217, 196, 133, 0.5);
}
.btn-check:checked + .btn-yellow-200, .btn-check:active + .btn-yellow-200, .btn-yellow-200:active, .btn-yellow-200.active, .show > .btn-yellow-200.dropdown-toggle {
  color: #000;
  background-color: #ffebb0;
  border-color: #ffe9a6;
}
.btn-check:checked + .btn-yellow-200:focus, .btn-check:active + .btn-yellow-200:focus, .btn-yellow-200:active:focus, .btn-yellow-200.active:focus, .show > .btn-yellow-200.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(217, 196, 133, 0.5);
}
.btn-yellow-200:disabled, .btn-yellow-200.disabled {
  color: #000;
  background-color: #ffe69c;
  border-color: #ffe69c;
}

.btn-yellow-300 {
  color: #000;
  background-color: #ffda6a;
  border-color: #ffda6a;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-yellow-300:hover {
  color: #000;
  background-color: #ffe080;
  border-color: #ffde79;
}
.btn-check:focus + .btn-yellow-300, .btn-yellow-300:focus {
  color: #000;
  background-color: #ffe080;
  border-color: #ffde79;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(217, 185, 90, 0.5);
}
.btn-check:checked + .btn-yellow-300, .btn-check:active + .btn-yellow-300, .btn-yellow-300:active, .btn-yellow-300.active, .show > .btn-yellow-300.dropdown-toggle {
  color: #000;
  background-color: #ffe188;
  border-color: #ffde79;
}
.btn-check:checked + .btn-yellow-300:focus, .btn-check:active + .btn-yellow-300:focus, .btn-yellow-300:active:focus, .btn-yellow-300.active:focus, .show > .btn-yellow-300.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(217, 185, 90, 0.5);
}
.btn-yellow-300:disabled, .btn-yellow-300.disabled {
  color: #000;
  background-color: #ffda6a;
  border-color: #ffda6a;
}

.btn-yellow-400 {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffcd39;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-yellow-400:hover {
  color: #000;
  background-color: #ffd557;
  border-color: #ffd24d;
}
.btn-check:focus + .btn-yellow-400, .btn-yellow-400:focus {
  color: #000;
  background-color: #ffd557;
  border-color: #ffd24d;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(217, 174, 48, 0.5);
}
.btn-check:checked + .btn-yellow-400, .btn-check:active + .btn-yellow-400, .btn-yellow-400:active, .btn-yellow-400.active, .show > .btn-yellow-400.dropdown-toggle {
  color: #000;
  background-color: #ffd761;
  border-color: #ffd24d;
}
.btn-check:checked + .btn-yellow-400:focus, .btn-check:active + .btn-yellow-400:focus, .btn-yellow-400:active:focus, .btn-yellow-400.active:focus, .show > .btn-yellow-400.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(217, 174, 48, 0.5);
}
.btn-yellow-400:disabled, .btn-yellow-400.disabled {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffcd39;
}

.btn-yellow-500 {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-yellow-500:hover {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
}
.btn-check:focus + .btn-yellow-500, .btn-yellow-500:focus {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}
.btn-check:checked + .btn-yellow-500, .btn-check:active + .btn-yellow-500, .btn-yellow-500:active, .btn-yellow-500.active, .show > .btn-yellow-500.dropdown-toggle {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffc720;
}
.btn-check:checked + .btn-yellow-500:focus, .btn-check:active + .btn-yellow-500:focus, .btn-yellow-500:active:focus, .btn-yellow-500.active:focus, .show > .btn-yellow-500.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}
.btn-yellow-500:disabled, .btn-yellow-500.disabled {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-yellow-600 {
  color: #000;
  background-color: #cc9a06;
  border-color: #cc9a06;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-yellow-600:hover {
  color: #000;
  background-color: #d4a92b;
  border-color: #d1a41f;
}
.btn-check:focus + .btn-yellow-600, .btn-yellow-600:focus {
  color: #000;
  background-color: #d4a92b;
  border-color: #d1a41f;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(173, 131, 5, 0.5);
}
.btn-check:checked + .btn-yellow-600, .btn-check:active + .btn-yellow-600, .btn-yellow-600:active, .btn-yellow-600.active, .show > .btn-yellow-600.dropdown-toggle {
  color: #000;
  background-color: #d6ae38;
  border-color: #d1a41f;
}
.btn-check:checked + .btn-yellow-600:focus, .btn-check:active + .btn-yellow-600:focus, .btn-yellow-600:active:focus, .btn-yellow-600.active:focus, .show > .btn-yellow-600.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(173, 131, 5, 0.5);
}
.btn-yellow-600:disabled, .btn-yellow-600.disabled {
  color: #000;
  background-color: #cc9a06;
  border-color: #cc9a06;
}

.btn-yellow-700 {
  color: #000;
  background-color: #997404;
  border-color: #997404;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-yellow-700:hover {
  color: #000;
  background-color: #a8892a;
  border-color: #a3821d;
}
.btn-check:focus + .btn-yellow-700, .btn-yellow-700:focus {
  color: #000;
  background-color: #a8892a;
  border-color: #a3821d;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(130, 99, 3, 0.5);
}
.btn-check:checked + .btn-yellow-700, .btn-check:active + .btn-yellow-700, .btn-yellow-700:active, .btn-yellow-700.active, .show > .btn-yellow-700.dropdown-toggle {
  color: #000;
  background-color: #ad9036;
  border-color: #a3821d;
}
.btn-check:checked + .btn-yellow-700:focus, .btn-check:active + .btn-yellow-700:focus, .btn-yellow-700:active:focus, .btn-yellow-700.active:focus, .show > .btn-yellow-700.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(130, 99, 3, 0.5);
}
.btn-yellow-700:disabled, .btn-yellow-700.disabled {
  color: #000;
  background-color: #997404;
  border-color: #997404;
}

.btn-yellow-800 {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-yellow-800:hover {
  color: #fff;
  background-color: #574103;
  border-color: #523e02;
}
.btn-check:focus + .btn-yellow-800, .btn-yellow-800:focus {
  color: #fff;
  background-color: #574103;
  border-color: #523e02;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(125, 104, 41, 0.5);
}
.btn-check:checked + .btn-yellow-800, .btn-check:active + .btn-yellow-800, .btn-yellow-800:active, .btn-yellow-800.active, .show > .btn-yellow-800.dropdown-toggle {
  color: #fff;
  background-color: #523e02;
  border-color: #4d3a02;
}
.btn-check:checked + .btn-yellow-800:focus, .btn-check:active + .btn-yellow-800:focus, .btn-yellow-800:active:focus, .btn-yellow-800.active:focus, .show > .btn-yellow-800.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(125, 104, 41, 0.5);
}
.btn-yellow-800:disabled, .btn-yellow-800.disabled {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.btn-yellow-900 {
  color: #fff;
  background-color: #332701;
  border-color: #332701;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-yellow-900:hover {
  color: #fff;
  background-color: #2b2101;
  border-color: #291f01;
}
.btn-check:focus + .btn-yellow-900, .btn-yellow-900:focus {
  color: #fff;
  background-color: #2b2101;
  border-color: #291f01;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(82, 71, 39, 0.5);
}
.btn-check:checked + .btn-yellow-900, .btn-check:active + .btn-yellow-900, .btn-yellow-900:active, .btn-yellow-900.active, .show > .btn-yellow-900.dropdown-toggle {
  color: #fff;
  background-color: #291f01;
  border-color: #261d01;
}
.btn-check:checked + .btn-yellow-900:focus, .btn-check:active + .btn-yellow-900:focus, .btn-yellow-900:active:focus, .btn-yellow-900.active:focus, .show > .btn-yellow-900.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(82, 71, 39, 0.5);
}
.btn-yellow-900:disabled, .btn-yellow-900.disabled {
  color: #fff;
  background-color: #332701;
  border-color: #332701;
}

.btn-green-100 {
  color: #000;
  background-color: #d1e7dd;
  border-color: #d1e7dd;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-green-100:hover {
  color: #000;
  background-color: #d8ebe2;
  border-color: #d6e9e0;
}
.btn-check:focus + .btn-green-100, .btn-green-100:focus {
  color: #000;
  background-color: #d8ebe2;
  border-color: #d6e9e0;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(178, 196, 188, 0.5);
}
.btn-check:checked + .btn-green-100, .btn-check:active + .btn-green-100, .btn-green-100:active, .btn-green-100.active, .show > .btn-green-100.dropdown-toggle {
  color: #000;
  background-color: #daece4;
  border-color: #d6e9e0;
}
.btn-check:checked + .btn-green-100:focus, .btn-check:active + .btn-green-100:focus, .btn-green-100:active:focus, .btn-green-100.active:focus, .show > .btn-green-100.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(178, 196, 188, 0.5);
}
.btn-green-100:disabled, .btn-green-100.disabled {
  color: #000;
  background-color: #d1e7dd;
  border-color: #d1e7dd;
}

.btn-green-200 {
  color: #000;
  background-color: #a3cfbb;
  border-color: #a3cfbb;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-green-200:hover {
  color: #000;
  background-color: #b1d6c5;
  border-color: #acd4c2;
}
.btn-check:focus + .btn-green-200, .btn-green-200:focus {
  color: #000;
  background-color: #b1d6c5;
  border-color: #acd4c2;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(139, 176, 159, 0.5);
}
.btn-check:checked + .btn-green-200, .btn-check:active + .btn-green-200, .btn-green-200:active, .btn-green-200.active, .show > .btn-green-200.dropdown-toggle {
  color: #000;
  background-color: #b5d9c9;
  border-color: #acd4c2;
}
.btn-check:checked + .btn-green-200:focus, .btn-check:active + .btn-green-200:focus, .btn-green-200:active:focus, .btn-green-200.active:focus, .show > .btn-green-200.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(139, 176, 159, 0.5);
}
.btn-green-200:disabled, .btn-green-200.disabled {
  color: #000;
  background-color: #a3cfbb;
  border-color: #a3cfbb;
}

.btn-green-300 {
  color: #000;
  background-color: #75b798;
  border-color: #75b798;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-green-300:hover {
  color: #000;
  background-color: #8ac2a7;
  border-color: #83bea2;
}
.btn-check:focus + .btn-green-300, .btn-green-300:focus {
  color: #000;
  background-color: #8ac2a7;
  border-color: #83bea2;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(99, 156, 129, 0.5);
}
.btn-check:checked + .btn-green-300, .btn-check:active + .btn-green-300, .btn-green-300:active, .btn-green-300.active, .show > .btn-green-300.dropdown-toggle {
  color: #000;
  background-color: #91c5ad;
  border-color: #83bea2;
}
.btn-check:checked + .btn-green-300:focus, .btn-check:active + .btn-green-300:focus, .btn-green-300:active:focus, .btn-green-300.active:focus, .show > .btn-green-300.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(99, 156, 129, 0.5);
}
.btn-green-300:disabled, .btn-green-300.disabled {
  color: #000;
  background-color: #75b798;
  border-color: #75b798;
}

.btn-green-400 {
  color: #000;
  background-color: #479f76;
  border-color: #479f76;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-green-400:hover {
  color: #000;
  background-color: #63ad8b;
  border-color: #59a984;
}
.btn-check:focus + .btn-green-400, .btn-green-400:focus {
  color: #000;
  background-color: #63ad8b;
  border-color: #59a984;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(60, 135, 100, 0.5);
}
.btn-check:checked + .btn-green-400, .btn-check:active + .btn-green-400, .btn-green-400:active, .btn-green-400.active, .show > .btn-green-400.dropdown-toggle {
  color: #000;
  background-color: #6cb291;
  border-color: #59a984;
}
.btn-check:checked + .btn-green-400:focus, .btn-check:active + .btn-green-400:focus, .btn-green-400:active:focus, .btn-green-400.active:focus, .show > .btn-green-400.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(60, 135, 100, 0.5);
}
.btn-green-400:disabled, .btn-green-400.disabled {
  color: #000;
  background-color: #479f76;
  border-color: #479f76;
}

.btn-green-500 {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-green-500:hover {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
}
.btn-check:focus + .btn-green-500, .btn-green-500:focus {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}
.btn-check:checked + .btn-green-500, .btn-check:active + .btn-green-500, .btn-green-500:active, .btn-green-500.active, .show > .btn-green-500.dropdown-toggle {
  color: #fff;
  background-color: #146c43;
  border-color: #13653f;
}
.btn-check:checked + .btn-green-500:focus, .btn-check:active + .btn-green-500:focus, .btn-green-500:active:focus, .btn-green-500.active:focus, .show > .btn-green-500.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}
.btn-green-500:disabled, .btn-green-500.disabled {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-green-600 {
  color: #fff;
  background-color: #146c43;
  border-color: #146c43;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-green-600:hover {
  color: #fff;
  background-color: #115c39;
  border-color: #105636;
}
.btn-check:focus + .btn-green-600, .btn-green-600:focus {
  color: #fff;
  background-color: #115c39;
  border-color: #105636;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(55, 130, 95, 0.5);
}
.btn-check:checked + .btn-green-600, .btn-check:active + .btn-green-600, .btn-green-600:active, .btn-green-600.active, .show > .btn-green-600.dropdown-toggle {
  color: #fff;
  background-color: #105636;
  border-color: #0f5132;
}
.btn-check:checked + .btn-green-600:focus, .btn-check:active + .btn-green-600:focus, .btn-green-600:active:focus, .btn-green-600.active:focus, .show > .btn-green-600.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(55, 130, 95, 0.5);
}
.btn-green-600:disabled, .btn-green-600.disabled {
  color: #fff;
  background-color: #146c43;
  border-color: #146c43;
}

.btn-green-700 {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-green-700:hover {
  color: #fff;
  background-color: #0d452b;
  border-color: #0c4128;
}
.btn-check:focus + .btn-green-700, .btn-green-700:focus {
  color: #fff;
  background-color: #0d452b;
  border-color: #0c4128;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(51, 107, 81, 0.5);
}
.btn-check:checked + .btn-green-700, .btn-check:active + .btn-green-700, .btn-green-700:active, .btn-green-700.active, .show > .btn-green-700.dropdown-toggle {
  color: #fff;
  background-color: #0c4128;
  border-color: #0b3d26;
}
.btn-check:checked + .btn-green-700:focus, .btn-check:active + .btn-green-700:focus, .btn-green-700:active:focus, .btn-green-700.active:focus, .show > .btn-green-700.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(51, 107, 81, 0.5);
}
.btn-green-700:disabled, .btn-green-700.disabled {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.btn-green-800 {
  color: #fff;
  background-color: #0a3622;
  border-color: #0a3622;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-green-800:hover {
  color: #fff;
  background-color: #092e1d;
  border-color: #082b1b;
}
.btn-check:focus + .btn-green-800, .btn-green-800:focus {
  color: #fff;
  background-color: #092e1d;
  border-color: #082b1b;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(47, 84, 67, 0.5);
}
.btn-check:checked + .btn-green-800, .btn-check:active + .btn-green-800, .btn-green-800:active, .btn-green-800.active, .show > .btn-green-800.dropdown-toggle {
  color: #fff;
  background-color: #082b1b;
  border-color: #08291a;
}
.btn-check:checked + .btn-green-800:focus, .btn-check:active + .btn-green-800:focus, .btn-green-800:active:focus, .btn-green-800.active:focus, .show > .btn-green-800.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(47, 84, 67, 0.5);
}
.btn-green-800:disabled, .btn-green-800.disabled {
  color: #fff;
  background-color: #0a3622;
  border-color: #0a3622;
}

.btn-green-900 {
  color: #fff;
  background-color: #051b11;
  border-color: #051b11;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-green-900:hover {
  color: #fff;
  background-color: #04170e;
  border-color: #04160e;
}
.btn-check:focus + .btn-green-900, .btn-green-900:focus {
  color: #fff;
  background-color: #04170e;
  border-color: #04160e;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(43, 61, 53, 0.5);
}
.btn-check:checked + .btn-green-900, .btn-check:active + .btn-green-900, .btn-green-900:active, .btn-green-900.active, .show > .btn-green-900.dropdown-toggle {
  color: #fff;
  background-color: #04160e;
  border-color: #04140d;
}
.btn-check:checked + .btn-green-900:focus, .btn-check:active + .btn-green-900:focus, .btn-green-900:active:focus, .btn-green-900.active:focus, .show > .btn-green-900.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(43, 61, 53, 0.5);
}
.btn-green-900:disabled, .btn-green-900.disabled {
  color: #fff;
  background-color: #051b11;
  border-color: #051b11;
}

.btn-teal-100 {
  color: #000;
  background-color: #d2f4ea;
  border-color: #d2f4ea;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-teal-100:hover {
  color: #000;
  background-color: #d9f6ed;
  border-color: #d7f5ec;
}
.btn-check:focus + .btn-teal-100, .btn-teal-100:focus {
  color: #000;
  background-color: #d9f6ed;
  border-color: #d7f5ec;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(179, 207, 199, 0.5);
}
.btn-check:checked + .btn-teal-100, .btn-check:active + .btn-teal-100, .btn-teal-100:active, .btn-teal-100.active, .show > .btn-teal-100.dropdown-toggle {
  color: #000;
  background-color: #dbf6ee;
  border-color: #d7f5ec;
}
.btn-check:checked + .btn-teal-100:focus, .btn-check:active + .btn-teal-100:focus, .btn-teal-100:active:focus, .btn-teal-100.active:focus, .show > .btn-teal-100.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(179, 207, 199, 0.5);
}
.btn-teal-100:disabled, .btn-teal-100.disabled {
  color: #000;
  background-color: #d2f4ea;
  border-color: #d2f4ea;
}

.btn-teal-200 {
  color: #000;
  background-color: #a6e9d5;
  border-color: #a6e9d5;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-teal-200:hover {
  color: #000;
  background-color: #b3ecdb;
  border-color: #afebd9;
}
.btn-check:focus + .btn-teal-200, .btn-teal-200:focus {
  color: #000;
  background-color: #b3ecdb;
  border-color: #afebd9;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(141, 198, 181, 0.5);
}
.btn-check:checked + .btn-teal-200, .btn-check:active + .btn-teal-200, .btn-teal-200:active, .btn-teal-200.active, .show > .btn-teal-200.dropdown-toggle {
  color: #000;
  background-color: #b8eddd;
  border-color: #afebd9;
}
.btn-check:checked + .btn-teal-200:focus, .btn-check:active + .btn-teal-200:focus, .btn-teal-200:active:focus, .btn-teal-200.active:focus, .show > .btn-teal-200.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(141, 198, 181, 0.5);
}
.btn-teal-200:disabled, .btn-teal-200.disabled {
  color: #000;
  background-color: #a6e9d5;
  border-color: #a6e9d5;
}

.btn-teal-300 {
  color: #000;
  background-color: #79dfc1;
  border-color: #79dfc1;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-teal-300:hover {
  color: #000;
  background-color: #8de4ca;
  border-color: #86e2c7;
}
.btn-check:focus + .btn-teal-300, .btn-teal-300:focus {
  color: #000;
  background-color: #8de4ca;
  border-color: #86e2c7;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(103, 190, 164, 0.5);
}
.btn-check:checked + .btn-teal-300, .btn-check:active + .btn-teal-300, .btn-teal-300:active, .btn-teal-300.active, .show > .btn-teal-300.dropdown-toggle {
  color: #000;
  background-color: #94e5cd;
  border-color: #86e2c7;
}
.btn-check:checked + .btn-teal-300:focus, .btn-check:active + .btn-teal-300:focus, .btn-teal-300:active:focus, .btn-teal-300.active:focus, .show > .btn-teal-300.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(103, 190, 164, 0.5);
}
.btn-teal-300:disabled, .btn-teal-300.disabled {
  color: #000;
  background-color: #79dfc1;
  border-color: #79dfc1;
}

.btn-teal-400 {
  color: #000;
  background-color: #4dd4ac;
  border-color: #4dd4ac;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-teal-400:hover {
  color: #000;
  background-color: #68dab8;
  border-color: #5fd8b4;
}
.btn-check:focus + .btn-teal-400, .btn-teal-400:focus {
  color: #000;
  background-color: #68dab8;
  border-color: #5fd8b4;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(65, 180, 146, 0.5);
}
.btn-check:checked + .btn-teal-400, .btn-check:active + .btn-teal-400, .btn-teal-400:active, .btn-teal-400.active, .show > .btn-teal-400.dropdown-toggle {
  color: #000;
  background-color: #71ddbd;
  border-color: #5fd8b4;
}
.btn-check:checked + .btn-teal-400:focus, .btn-check:active + .btn-teal-400:focus, .btn-teal-400:active:focus, .btn-teal-400.active:focus, .show > .btn-teal-400.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(65, 180, 146, 0.5);
}
.btn-teal-400:disabled, .btn-teal-400.disabled {
  color: #000;
  background-color: #4dd4ac;
  border-color: #4dd4ac;
}

.btn-teal-500 {
  color: #000;
  background-color: #20c997;
  border-color: #20c997;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-teal-500:hover {
  color: #000;
  background-color: #41d1a7;
  border-color: #36cea1;
}
.btn-check:focus + .btn-teal-500, .btn-teal-500:focus {
  color: #000;
  background-color: #41d1a7;
  border-color: #36cea1;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(27, 171, 128, 0.5);
}
.btn-check:checked + .btn-teal-500, .btn-check:active + .btn-teal-500, .btn-teal-500:active, .btn-teal-500.active, .show > .btn-teal-500.dropdown-toggle {
  color: #000;
  background-color: #4dd4ac;
  border-color: #36cea1;
}
.btn-check:checked + .btn-teal-500:focus, .btn-check:active + .btn-teal-500:focus, .btn-teal-500:active:focus, .btn-teal-500.active:focus, .show > .btn-teal-500.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(27, 171, 128, 0.5);
}
.btn-teal-500:disabled, .btn-teal-500.disabled {
  color: #000;
  background-color: #20c997;
  border-color: #20c997;
}

.btn-teal-600 {
  color: #000;
  background-color: #1aa179;
  border-color: #1aa179;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-teal-600:hover {
  color: #000;
  background-color: #3caf8d;
  border-color: #31aa86;
}
.btn-check:focus + .btn-teal-600, .btn-teal-600:focus {
  color: #000;
  background-color: #3caf8d;
  border-color: #31aa86;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(22, 137, 103, 0.5);
}
.btn-check:checked + .btn-teal-600, .btn-check:active + .btn-teal-600, .btn-teal-600:active, .btn-teal-600.active, .show > .btn-teal-600.dropdown-toggle {
  color: #000;
  background-color: #48b494;
  border-color: #31aa86;
}
.btn-check:checked + .btn-teal-600:focus, .btn-check:active + .btn-teal-600:focus, .btn-teal-600:active:focus, .btn-teal-600.active:focus, .show > .btn-teal-600.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(22, 137, 103, 0.5);
}
.btn-teal-600:disabled, .btn-teal-600.disabled {
  color: #000;
  background-color: #1aa179;
  border-color: #1aa179;
}

.btn-teal-700 {
  color: #fff;
  background-color: #13795b;
  border-color: #13795b;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-teal-700:hover {
  color: #fff;
  background-color: #10674d;
  border-color: #0f6149;
}
.btn-check:focus + .btn-teal-700, .btn-teal-700:focus {
  color: #fff;
  background-color: #10674d;
  border-color: #0f6149;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(54, 141, 116, 0.5);
}
.btn-check:checked + .btn-teal-700, .btn-check:active + .btn-teal-700, .btn-teal-700:active, .btn-teal-700.active, .show > .btn-teal-700.dropdown-toggle {
  color: #fff;
  background-color: #0f6149;
  border-color: #0e5b44;
}
.btn-check:checked + .btn-teal-700:focus, .btn-check:active + .btn-teal-700:focus, .btn-teal-700:active:focus, .btn-teal-700.active:focus, .show > .btn-teal-700.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(54, 141, 116, 0.5);
}
.btn-teal-700:disabled, .btn-teal-700.disabled {
  color: #fff;
  background-color: #13795b;
  border-color: #13795b;
}

.btn-teal-800 {
  color: #fff;
  background-color: #0d503c;
  border-color: #0d503c;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-teal-800:hover {
  color: #fff;
  background-color: #0b4433;
  border-color: #0a4030;
}
.btn-check:focus + .btn-teal-800, .btn-teal-800:focus {
  color: #fff;
  background-color: #0b4433;
  border-color: #0a4030;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(49, 106, 89, 0.5);
}
.btn-check:checked + .btn-teal-800, .btn-check:active + .btn-teal-800, .btn-teal-800:active, .btn-teal-800.active, .show > .btn-teal-800.dropdown-toggle {
  color: #fff;
  background-color: #0a4030;
  border-color: #0a3c2d;
}
.btn-check:checked + .btn-teal-800:focus, .btn-check:active + .btn-teal-800:focus, .btn-teal-800:active:focus, .btn-teal-800.active:focus, .show > .btn-teal-800.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(49, 106, 89, 0.5);
}
.btn-teal-800:disabled, .btn-teal-800.disabled {
  color: #fff;
  background-color: #0d503c;
  border-color: #0d503c;
}

.btn-teal-900 {
  color: #fff;
  background-color: #06281e;
  border-color: #06281e;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-teal-900:hover {
  color: #fff;
  background-color: #05221a;
  border-color: #052018;
}
.btn-check:focus + .btn-teal-900, .btn-teal-900:focus {
  color: #fff;
  background-color: #05221a;
  border-color: #052018;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(43, 72, 64, 0.5);
}
.btn-check:checked + .btn-teal-900, .btn-check:active + .btn-teal-900, .btn-teal-900:active, .btn-teal-900.active, .show > .btn-teal-900.dropdown-toggle {
  color: #fff;
  background-color: #052018;
  border-color: #051e17;
}
.btn-check:checked + .btn-teal-900:focus, .btn-check:active + .btn-teal-900:focus, .btn-teal-900:active:focus, .btn-teal-900.active:focus, .show > .btn-teal-900.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(43, 72, 64, 0.5);
}
.btn-teal-900:disabled, .btn-teal-900.disabled {
  color: #fff;
  background-color: #06281e;
  border-color: #06281e;
}

.btn-cyan-100 {
  color: #000;
  background-color: #cff4fc;
  border-color: #cff4fc;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-cyan-100:hover {
  color: #000;
  background-color: #d6f6fc;
  border-color: #d4f5fc;
}
.btn-check:focus + .btn-cyan-100, .btn-cyan-100:focus {
  color: #000;
  background-color: #d6f6fc;
  border-color: #d4f5fc;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(176, 207, 214, 0.5);
}
.btn-check:checked + .btn-cyan-100, .btn-check:active + .btn-cyan-100, .btn-cyan-100:active, .btn-cyan-100.active, .show > .btn-cyan-100.dropdown-toggle {
  color: #000;
  background-color: #d9f6fd;
  border-color: #d4f5fc;
}
.btn-check:checked + .btn-cyan-100:focus, .btn-check:active + .btn-cyan-100:focus, .btn-cyan-100:active:focus, .btn-cyan-100.active:focus, .show > .btn-cyan-100.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(176, 207, 214, 0.5);
}
.btn-cyan-100:disabled, .btn-cyan-100.disabled {
  color: #000;
  background-color: #cff4fc;
  border-color: #cff4fc;
}

.btn-cyan-200 {
  color: #000;
  background-color: #9eeaf9;
  border-color: #9eeaf9;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-cyan-200:hover {
  color: #000;
  background-color: #adedfa;
  border-color: #a8ecfa;
}
.btn-check:focus + .btn-cyan-200, .btn-cyan-200:focus {
  color: #000;
  background-color: #adedfa;
  border-color: #a8ecfa;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(134, 199, 212, 0.5);
}
.btn-check:checked + .btn-cyan-200, .btn-check:active + .btn-cyan-200, .btn-cyan-200:active, .btn-cyan-200.active, .show > .btn-cyan-200.dropdown-toggle {
  color: #000;
  background-color: #b1eefa;
  border-color: #a8ecfa;
}
.btn-check:checked + .btn-cyan-200:focus, .btn-check:active + .btn-cyan-200:focus, .btn-cyan-200:active:focus, .btn-cyan-200.active:focus, .show > .btn-cyan-200.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(134, 199, 212, 0.5);
}
.btn-cyan-200:disabled, .btn-cyan-200.disabled {
  color: #000;
  background-color: #9eeaf9;
  border-color: #9eeaf9;
}

.btn-cyan-300 {
  color: #000;
  background-color: #6edff6;
  border-color: #6edff6;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-cyan-300:hover {
  color: #000;
  background-color: #84e4f7;
  border-color: #7de2f7;
}
.btn-check:focus + .btn-cyan-300, .btn-cyan-300:focus {
  color: #000;
  background-color: #84e4f7;
  border-color: #7de2f7;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(94, 190, 209, 0.5);
}
.btn-check:checked + .btn-cyan-300, .btn-check:active + .btn-cyan-300, .btn-cyan-300:active, .btn-cyan-300.active, .show > .btn-cyan-300.dropdown-toggle {
  color: #000;
  background-color: #8be5f8;
  border-color: #7de2f7;
}
.btn-check:checked + .btn-cyan-300:focus, .btn-check:active + .btn-cyan-300:focus, .btn-cyan-300:active:focus, .btn-cyan-300.active:focus, .show > .btn-cyan-300.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(94, 190, 209, 0.5);
}
.btn-cyan-300:disabled, .btn-cyan-300.disabled {
  color: #000;
  background-color: #6edff6;
  border-color: #6edff6;
}

.btn-cyan-400 {
  color: #000;
  background-color: #3dd5f3;
  border-color: #3dd5f3;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-cyan-400:hover {
  color: #000;
  background-color: #5adbf5;
  border-color: #50d9f4;
}
.btn-check:focus + .btn-cyan-400, .btn-cyan-400:focus {
  color: #000;
  background-color: #5adbf5;
  border-color: #50d9f4;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(52, 181, 207, 0.5);
}
.btn-check:checked + .btn-cyan-400, .btn-check:active + .btn-cyan-400, .btn-cyan-400:active, .btn-cyan-400.active, .show > .btn-cyan-400.dropdown-toggle {
  color: #000;
  background-color: #64ddf5;
  border-color: #50d9f4;
}
.btn-check:checked + .btn-cyan-400:focus, .btn-check:active + .btn-cyan-400:focus, .btn-cyan-400:active:focus, .btn-cyan-400.active:focus, .show > .btn-cyan-400.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(52, 181, 207, 0.5);
}
.btn-cyan-400:disabled, .btn-cyan-400.disabled {
  color: #000;
  background-color: #3dd5f3;
  border-color: #3dd5f3;
}

.btn-cyan-500 {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-cyan-500:hover {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
}
.btn-check:focus + .btn-cyan-500, .btn-cyan-500:focus {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-check:checked + .btn-cyan-500, .btn-check:active + .btn-cyan-500, .btn-cyan-500:active, .btn-cyan-500.active, .show > .btn-cyan-500.dropdown-toggle {
  color: #000;
  background-color: #3dd5f3;
  border-color: #25cff2;
}
.btn-check:checked + .btn-cyan-500:focus, .btn-check:active + .btn-cyan-500:focus, .btn-cyan-500:active:focus, .btn-cyan-500.active:focus, .show > .btn-cyan-500.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-cyan-500:disabled, .btn-cyan-500.disabled {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-cyan-600 {
  color: #000;
  background-color: #0aa2c0;
  border-color: #0aa2c0;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-cyan-600:hover {
  color: #000;
  background-color: #2fb0c9;
  border-color: #23abc6;
}
.btn-check:focus + .btn-cyan-600, .btn-cyan-600:focus {
  color: #000;
  background-color: #2fb0c9;
  border-color: #23abc6;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(9, 138, 163, 0.5);
}
.btn-check:checked + .btn-cyan-600, .btn-check:active + .btn-cyan-600, .btn-cyan-600:active, .btn-cyan-600.active, .show > .btn-cyan-600.dropdown-toggle {
  color: #000;
  background-color: #3bb5cd;
  border-color: #23abc6;
}
.btn-check:checked + .btn-cyan-600:focus, .btn-check:active + .btn-cyan-600:focus, .btn-cyan-600:active:focus, .btn-cyan-600.active:focus, .show > .btn-cyan-600.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(9, 138, 163, 0.5);
}
.btn-cyan-600:disabled, .btn-cyan-600.disabled {
  color: #000;
  background-color: #0aa2c0;
  border-color: #0aa2c0;
}

.btn-cyan-700 {
  color: #fff;
  background-color: #087990;
  border-color: #087990;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-cyan-700:hover {
  color: #fff;
  background-color: #07677a;
  border-color: #066173;
}
.btn-check:focus + .btn-cyan-700, .btn-cyan-700:focus {
  color: #fff;
  background-color: #07677a;
  border-color: #066173;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(45, 141, 161, 0.5);
}
.btn-check:checked + .btn-cyan-700, .btn-check:active + .btn-cyan-700, .btn-cyan-700:active, .btn-cyan-700.active, .show > .btn-cyan-700.dropdown-toggle {
  color: #fff;
  background-color: #066173;
  border-color: #065b6c;
}
.btn-check:checked + .btn-cyan-700:focus, .btn-check:active + .btn-cyan-700:focus, .btn-cyan-700:active:focus, .btn-cyan-700.active:focus, .show > .btn-cyan-700.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(45, 141, 161, 0.5);
}
.btn-cyan-700:disabled, .btn-cyan-700.disabled {
  color: #fff;
  background-color: #087990;
  border-color: #087990;
}

.btn-cyan-800 {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-cyan-800:hover {
  color: #fff;
  background-color: #044552;
  border-color: #04414d;
}
.btn-check:focus + .btn-cyan-800, .btn-cyan-800:focus {
  color: #fff;
  background-color: #044552;
  border-color: #04414d;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(43, 107, 120, 0.5);
}
.btn-check:checked + .btn-cyan-800, .btn-check:active + .btn-cyan-800, .btn-cyan-800:active, .btn-cyan-800.active, .show > .btn-cyan-800.dropdown-toggle {
  color: #fff;
  background-color: #04414d;
  border-color: #043d48;
}
.btn-check:checked + .btn-cyan-800:focus, .btn-check:active + .btn-cyan-800:focus, .btn-cyan-800:active:focus, .btn-cyan-800.active:focus, .show > .btn-cyan-800.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(43, 107, 120, 0.5);
}
.btn-cyan-800:disabled, .btn-cyan-800.disabled {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.btn-cyan-900 {
  color: #fff;
  background-color: #032830;
  border-color: #032830;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
.btn-cyan-900:hover {
  color: #fff;
  background-color: #032229;
  border-color: #022026;
}
.btn-check:focus + .btn-cyan-900, .btn-cyan-900:focus {
  color: #fff;
  background-color: #032229;
  border-color: #022026;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(41, 72, 79, 0.5);
}
.btn-check:checked + .btn-cyan-900, .btn-check:active + .btn-cyan-900, .btn-cyan-900:active, .btn-cyan-900.active, .show > .btn-cyan-900.dropdown-toggle {
  color: #fff;
  background-color: #022026;
  border-color: #021e24;
}
.btn-check:checked + .btn-cyan-900:focus, .btn-check:active + .btn-cyan-900:focus, .btn-cyan-900:active:focus, .btn-cyan-900.active:focus, .show > .btn-cyan-900.dropdown-toggle:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(41, 72, 79, 0.5);
}
.btn-cyan-900:disabled, .btn-cyan-900.disabled {
  color: #fff;
  background-color: #032830;
  border-color: #032830;
}

.btn-outline-primary {
  color: #0d6efd;
  border-color: #0d6efd;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #0d6efd;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #7a8288;
  border-color: #7a8288;
}
.btn-outline-secondary:hover {
  color: #000;
  background-color: #7a8288;
  border-color: #7a8288;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(122, 130, 136, 0.5);
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #000;
  background-color: #7a8288;
  border-color: #7a8288;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(122, 130, 136, 0.5);
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #7a8288;
  background-color: transparent;
}

.btn-outline-success {
  color: #198754;
  border-color: #198754;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #198754;
  background-color: transparent;
}

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-outline-info:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #0dcaf0;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-dark {
  color: #272b30;
  border-color: #272b30;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #272b30;
  border-color: #272b30;
}
.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(39, 43, 48, 0.5);
}
.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #272b30;
  border-color: #272b30;
}
.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(39, 43, 48, 0.5);
}
.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #272b30;
  background-color: transparent;
}

.btn-outline-sfx {
  color: #0a94d9;
  border-color: #0a94d9;
}
.btn-outline-sfx:hover {
  color: #000;
  background-color: #0a94d9;
  border-color: #0a94d9;
}
.btn-check:focus + .btn-outline-sfx, .btn-outline-sfx:focus {
  box-shadow: 0 0 0 0.25rem rgba(10, 148, 217, 0.5);
}
.btn-check:checked + .btn-outline-sfx, .btn-check:active + .btn-outline-sfx, .btn-outline-sfx:active, .btn-outline-sfx.active, .btn-outline-sfx.dropdown-toggle.show {
  color: #000;
  background-color: #0a94d9;
  border-color: #0a94d9;
}
.btn-check:checked + .btn-outline-sfx:focus, .btn-check:active + .btn-outline-sfx:focus, .btn-outline-sfx:active:focus, .btn-outline-sfx.active:focus, .btn-outline-sfx.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(10, 148, 217, 0.5);
}
.btn-outline-sfx:disabled, .btn-outline-sfx.disabled {
  color: #0a94d9;
  background-color: transparent;
}

.btn-outline-that-dark-dark {
  color: #121416;
  border-color: #121416;
}
.btn-outline-that-dark-dark:hover {
  color: #fff;
  background-color: #121416;
  border-color: #121416;
}
.btn-check:focus + .btn-outline-that-dark-dark, .btn-outline-that-dark-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(18, 20, 22, 0.5);
}
.btn-check:checked + .btn-outline-that-dark-dark, .btn-check:active + .btn-outline-that-dark-dark, .btn-outline-that-dark-dark:active, .btn-outline-that-dark-dark.active, .btn-outline-that-dark-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #121416;
  border-color: #121416;
}
.btn-check:checked + .btn-outline-that-dark-dark:focus, .btn-check:active + .btn-outline-that-dark-dark:focus, .btn-outline-that-dark-dark:active:focus, .btn-outline-that-dark-dark.active:focus, .btn-outline-that-dark-dark.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(18, 20, 22, 0.5);
}
.btn-outline-that-dark-dark:disabled, .btn-outline-that-dark-dark.disabled {
  color: #121416;
  background-color: transparent;
}

.btn-outline-flat-f {
  color: #fff;
  border-color: #fff;
}
.btn-outline-flat-f:hover {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}
.btn-check:focus + .btn-outline-flat-f, .btn-outline-flat-f:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-outline-flat-f, .btn-check:active + .btn-outline-flat-f, .btn-outline-flat-f:active, .btn-outline-flat-f.active, .btn-outline-flat-f.dropdown-toggle.show {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}
.btn-check:checked + .btn-outline-flat-f:focus, .btn-check:active + .btn-outline-flat-f:focus, .btn-outline-flat-f:active:focus, .btn-outline-flat-f.active:focus, .btn-outline-flat-f.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-outline-flat-f:disabled, .btn-outline-flat-f.disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-flat-e {
  color: #eee;
  border-color: #eee;
}
.btn-outline-flat-e:hover {
  color: #000;
  background-color: #eee;
  border-color: #eee;
}
.btn-check:focus + .btn-outline-flat-e, .btn-outline-flat-e:focus {
  box-shadow: 0 0 0 0.25rem rgba(238, 238, 238, 0.5);
}
.btn-check:checked + .btn-outline-flat-e, .btn-check:active + .btn-outline-flat-e, .btn-outline-flat-e:active, .btn-outline-flat-e.active, .btn-outline-flat-e.dropdown-toggle.show {
  color: #000;
  background-color: #eee;
  border-color: #eee;
}
.btn-check:checked + .btn-outline-flat-e:focus, .btn-check:active + .btn-outline-flat-e:focus, .btn-outline-flat-e:active:focus, .btn-outline-flat-e.active:focus, .btn-outline-flat-e.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(238, 238, 238, 0.5);
}
.btn-outline-flat-e:disabled, .btn-outline-flat-e.disabled {
  color: #eee;
  background-color: transparent;
}

.btn-outline-flat-d {
  color: #ddd;
  border-color: #ddd;
}
.btn-outline-flat-d:hover {
  color: #000;
  background-color: #ddd;
  border-color: #ddd;
}
.btn-check:focus + .btn-outline-flat-d, .btn-outline-flat-d:focus {
  box-shadow: 0 0 0 0.25rem rgba(221, 221, 221, 0.5);
}
.btn-check:checked + .btn-outline-flat-d, .btn-check:active + .btn-outline-flat-d, .btn-outline-flat-d:active, .btn-outline-flat-d.active, .btn-outline-flat-d.dropdown-toggle.show {
  color: #000;
  background-color: #ddd;
  border-color: #ddd;
}
.btn-check:checked + .btn-outline-flat-d:focus, .btn-check:active + .btn-outline-flat-d:focus, .btn-outline-flat-d:active:focus, .btn-outline-flat-d.active:focus, .btn-outline-flat-d.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(221, 221, 221, 0.5);
}
.btn-outline-flat-d:disabled, .btn-outline-flat-d.disabled {
  color: #ddd;
  background-color: transparent;
}

.btn-outline-flat-c {
  color: #ccc;
  border-color: #ccc;
}
.btn-outline-flat-c:hover {
  color: #000;
  background-color: #ccc;
  border-color: #ccc;
}
.btn-check:focus + .btn-outline-flat-c, .btn-outline-flat-c:focus {
  box-shadow: 0 0 0 0.25rem rgba(204, 204, 204, 0.5);
}
.btn-check:checked + .btn-outline-flat-c, .btn-check:active + .btn-outline-flat-c, .btn-outline-flat-c:active, .btn-outline-flat-c.active, .btn-outline-flat-c.dropdown-toggle.show {
  color: #000;
  background-color: #ccc;
  border-color: #ccc;
}
.btn-check:checked + .btn-outline-flat-c:focus, .btn-check:active + .btn-outline-flat-c:focus, .btn-outline-flat-c:active:focus, .btn-outline-flat-c.active:focus, .btn-outline-flat-c.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(204, 204, 204, 0.5);
}
.btn-outline-flat-c:disabled, .btn-outline-flat-c.disabled {
  color: #ccc;
  background-color: transparent;
}

.btn-outline-flat-b {
  color: #bbb;
  border-color: #bbb;
}
.btn-outline-flat-b:hover {
  color: #000;
  background-color: #bbb;
  border-color: #bbb;
}
.btn-check:focus + .btn-outline-flat-b, .btn-outline-flat-b:focus {
  box-shadow: 0 0 0 0.25rem rgba(187, 187, 187, 0.5);
}
.btn-check:checked + .btn-outline-flat-b, .btn-check:active + .btn-outline-flat-b, .btn-outline-flat-b:active, .btn-outline-flat-b.active, .btn-outline-flat-b.dropdown-toggle.show {
  color: #000;
  background-color: #bbb;
  border-color: #bbb;
}
.btn-check:checked + .btn-outline-flat-b:focus, .btn-check:active + .btn-outline-flat-b:focus, .btn-outline-flat-b:active:focus, .btn-outline-flat-b.active:focus, .btn-outline-flat-b.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(187, 187, 187, 0.5);
}
.btn-outline-flat-b:disabled, .btn-outline-flat-b.disabled {
  color: #bbb;
  background-color: transparent;
}

.btn-outline-flat-a {
  color: #aaa;
  border-color: #aaa;
}
.btn-outline-flat-a:hover {
  color: #000;
  background-color: #aaa;
  border-color: #aaa;
}
.btn-check:focus + .btn-outline-flat-a, .btn-outline-flat-a:focus {
  box-shadow: 0 0 0 0.25rem rgba(170, 170, 170, 0.5);
}
.btn-check:checked + .btn-outline-flat-a, .btn-check:active + .btn-outline-flat-a, .btn-outline-flat-a:active, .btn-outline-flat-a.active, .btn-outline-flat-a.dropdown-toggle.show {
  color: #000;
  background-color: #aaa;
  border-color: #aaa;
}
.btn-check:checked + .btn-outline-flat-a:focus, .btn-check:active + .btn-outline-flat-a:focus, .btn-outline-flat-a:active:focus, .btn-outline-flat-a.active:focus, .btn-outline-flat-a.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(170, 170, 170, 0.5);
}
.btn-outline-flat-a:disabled, .btn-outline-flat-a.disabled {
  color: #aaa;
  background-color: transparent;
}

.btn-outline-flat-9 {
  color: #999;
  border-color: #999;
}
.btn-outline-flat-9:hover {
  color: #000;
  background-color: #999;
  border-color: #999;
}
.btn-check:focus + .btn-outline-flat-9, .btn-outline-flat-9:focus {
  box-shadow: 0 0 0 0.25rem rgba(153, 153, 153, 0.5);
}
.btn-check:checked + .btn-outline-flat-9, .btn-check:active + .btn-outline-flat-9, .btn-outline-flat-9:active, .btn-outline-flat-9.active, .btn-outline-flat-9.dropdown-toggle.show {
  color: #000;
  background-color: #999;
  border-color: #999;
}
.btn-check:checked + .btn-outline-flat-9:focus, .btn-check:active + .btn-outline-flat-9:focus, .btn-outline-flat-9:active:focus, .btn-outline-flat-9.active:focus, .btn-outline-flat-9.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(153, 153, 153, 0.5);
}
.btn-outline-flat-9:disabled, .btn-outline-flat-9.disabled {
  color: #999;
  background-color: transparent;
}

.btn-outline-flat-8 {
  color: #888;
  border-color: #888;
}
.btn-outline-flat-8:hover {
  color: #000;
  background-color: #888;
  border-color: #888;
}
.btn-check:focus + .btn-outline-flat-8, .btn-outline-flat-8:focus {
  box-shadow: 0 0 0 0.25rem rgba(136, 136, 136, 0.5);
}
.btn-check:checked + .btn-outline-flat-8, .btn-check:active + .btn-outline-flat-8, .btn-outline-flat-8:active, .btn-outline-flat-8.active, .btn-outline-flat-8.dropdown-toggle.show {
  color: #000;
  background-color: #888;
  border-color: #888;
}
.btn-check:checked + .btn-outline-flat-8:focus, .btn-check:active + .btn-outline-flat-8:focus, .btn-outline-flat-8:active:focus, .btn-outline-flat-8.active:focus, .btn-outline-flat-8.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(136, 136, 136, 0.5);
}
.btn-outline-flat-8:disabled, .btn-outline-flat-8.disabled {
  color: #888;
  background-color: transparent;
}

.btn-outline-flat-7 {
  color: #777;
  border-color: #777;
}
.btn-outline-flat-7:hover {
  color: #000;
  background-color: #777;
  border-color: #777;
}
.btn-check:focus + .btn-outline-flat-7, .btn-outline-flat-7:focus {
  box-shadow: 0 0 0 0.25rem rgba(119, 119, 119, 0.5);
}
.btn-check:checked + .btn-outline-flat-7, .btn-check:active + .btn-outline-flat-7, .btn-outline-flat-7:active, .btn-outline-flat-7.active, .btn-outline-flat-7.dropdown-toggle.show {
  color: #000;
  background-color: #777;
  border-color: #777;
}
.btn-check:checked + .btn-outline-flat-7:focus, .btn-check:active + .btn-outline-flat-7:focus, .btn-outline-flat-7:active:focus, .btn-outline-flat-7.active:focus, .btn-outline-flat-7.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(119, 119, 119, 0.5);
}
.btn-outline-flat-7:disabled, .btn-outline-flat-7.disabled {
  color: #777;
  background-color: transparent;
}

.btn-outline-flat-6 {
  color: #666;
  border-color: #666;
}
.btn-outline-flat-6:hover {
  color: #fff;
  background-color: #666;
  border-color: #666;
}
.btn-check:focus + .btn-outline-flat-6, .btn-outline-flat-6:focus {
  box-shadow: 0 0 0 0.25rem rgba(102, 102, 102, 0.5);
}
.btn-check:checked + .btn-outline-flat-6, .btn-check:active + .btn-outline-flat-6, .btn-outline-flat-6:active, .btn-outline-flat-6.active, .btn-outline-flat-6.dropdown-toggle.show {
  color: #fff;
  background-color: #666;
  border-color: #666;
}
.btn-check:checked + .btn-outline-flat-6:focus, .btn-check:active + .btn-outline-flat-6:focus, .btn-outline-flat-6:active:focus, .btn-outline-flat-6.active:focus, .btn-outline-flat-6.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(102, 102, 102, 0.5);
}
.btn-outline-flat-6:disabled, .btn-outline-flat-6.disabled {
  color: #666;
  background-color: transparent;
}

.btn-outline-flat-5 {
  color: #555;
  border-color: #555;
}
.btn-outline-flat-5:hover {
  color: #fff;
  background-color: #555;
  border-color: #555;
}
.btn-check:focus + .btn-outline-flat-5, .btn-outline-flat-5:focus {
  box-shadow: 0 0 0 0.25rem rgba(85, 85, 85, 0.5);
}
.btn-check:checked + .btn-outline-flat-5, .btn-check:active + .btn-outline-flat-5, .btn-outline-flat-5:active, .btn-outline-flat-5.active, .btn-outline-flat-5.dropdown-toggle.show {
  color: #fff;
  background-color: #555;
  border-color: #555;
}
.btn-check:checked + .btn-outline-flat-5:focus, .btn-check:active + .btn-outline-flat-5:focus, .btn-outline-flat-5:active:focus, .btn-outline-flat-5.active:focus, .btn-outline-flat-5.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(85, 85, 85, 0.5);
}
.btn-outline-flat-5:disabled, .btn-outline-flat-5.disabled {
  color: #555;
  background-color: transparent;
}

.btn-outline-flat-4 {
  color: #444;
  border-color: #444;
}
.btn-outline-flat-4:hover {
  color: #fff;
  background-color: #444;
  border-color: #444;
}
.btn-check:focus + .btn-outline-flat-4, .btn-outline-flat-4:focus {
  box-shadow: 0 0 0 0.25rem rgba(68, 68, 68, 0.5);
}
.btn-check:checked + .btn-outline-flat-4, .btn-check:active + .btn-outline-flat-4, .btn-outline-flat-4:active, .btn-outline-flat-4.active, .btn-outline-flat-4.dropdown-toggle.show {
  color: #fff;
  background-color: #444;
  border-color: #444;
}
.btn-check:checked + .btn-outline-flat-4:focus, .btn-check:active + .btn-outline-flat-4:focus, .btn-outline-flat-4:active:focus, .btn-outline-flat-4.active:focus, .btn-outline-flat-4.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(68, 68, 68, 0.5);
}
.btn-outline-flat-4:disabled, .btn-outline-flat-4.disabled {
  color: #444;
  background-color: transparent;
}

.btn-outline-flat-3 {
  color: #333;
  border-color: #333;
}
.btn-outline-flat-3:hover {
  color: #fff;
  background-color: #333;
  border-color: #333;
}
.btn-check:focus + .btn-outline-flat-3, .btn-outline-flat-3:focus {
  box-shadow: 0 0 0 0.25rem rgba(51, 51, 51, 0.5);
}
.btn-check:checked + .btn-outline-flat-3, .btn-check:active + .btn-outline-flat-3, .btn-outline-flat-3:active, .btn-outline-flat-3.active, .btn-outline-flat-3.dropdown-toggle.show {
  color: #fff;
  background-color: #333;
  border-color: #333;
}
.btn-check:checked + .btn-outline-flat-3:focus, .btn-check:active + .btn-outline-flat-3:focus, .btn-outline-flat-3:active:focus, .btn-outline-flat-3.active:focus, .btn-outline-flat-3.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(51, 51, 51, 0.5);
}
.btn-outline-flat-3:disabled, .btn-outline-flat-3.disabled {
  color: #333;
  background-color: transparent;
}

.btn-outline-flat-2 {
  color: #222;
  border-color: #222;
}
.btn-outline-flat-2:hover {
  color: #fff;
  background-color: #222;
  border-color: #222;
}
.btn-check:focus + .btn-outline-flat-2, .btn-outline-flat-2:focus {
  box-shadow: 0 0 0 0.25rem rgba(34, 34, 34, 0.5);
}
.btn-check:checked + .btn-outline-flat-2, .btn-check:active + .btn-outline-flat-2, .btn-outline-flat-2:active, .btn-outline-flat-2.active, .btn-outline-flat-2.dropdown-toggle.show {
  color: #fff;
  background-color: #222;
  border-color: #222;
}
.btn-check:checked + .btn-outline-flat-2:focus, .btn-check:active + .btn-outline-flat-2:focus, .btn-outline-flat-2:active:focus, .btn-outline-flat-2.active:focus, .btn-outline-flat-2.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(34, 34, 34, 0.5);
}
.btn-outline-flat-2:disabled, .btn-outline-flat-2.disabled {
  color: #222;
  background-color: transparent;
}

.btn-outline-flat-1 {
  color: #111;
  border-color: #111;
}
.btn-outline-flat-1:hover {
  color: #fff;
  background-color: #111;
  border-color: #111;
}
.btn-check:focus + .btn-outline-flat-1, .btn-outline-flat-1:focus {
  box-shadow: 0 0 0 0.25rem rgba(17, 17, 17, 0.5);
}
.btn-check:checked + .btn-outline-flat-1, .btn-check:active + .btn-outline-flat-1, .btn-outline-flat-1:active, .btn-outline-flat-1.active, .btn-outline-flat-1.dropdown-toggle.show {
  color: #fff;
  background-color: #111;
  border-color: #111;
}
.btn-check:checked + .btn-outline-flat-1:focus, .btn-check:active + .btn-outline-flat-1:focus, .btn-outline-flat-1:active:focus, .btn-outline-flat-1.active:focus, .btn-outline-flat-1.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(17, 17, 17, 0.5);
}
.btn-outline-flat-1:disabled, .btn-outline-flat-1.disabled {
  color: #111;
  background-color: transparent;
}

.btn-outline-flat-0 {
  color: #000;
  border-color: #000;
}
.btn-outline-flat-0:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-check:focus + .btn-outline-flat-0, .btn-outline-flat-0:focus {
  box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.5);
}
.btn-check:checked + .btn-outline-flat-0, .btn-check:active + .btn-outline-flat-0, .btn-outline-flat-0:active, .btn-outline-flat-0.active, .btn-outline-flat-0.dropdown-toggle.show {
  color: #fff;
  background-color: #000;
  border-color: #000;
}
.btn-check:checked + .btn-outline-flat-0:focus, .btn-check:active + .btn-outline-flat-0:focus, .btn-outline-flat-0:active:focus, .btn-outline-flat-0.active:focus, .btn-outline-flat-0.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(0, 0, 0, 0.5);
}
.btn-outline-flat-0:disabled, .btn-outline-flat-0.disabled {
  color: #000;
  background-color: transparent;
}

.btn-outline-blue {
  color: #0d6efd;
  border-color: #0d6efd;
}
.btn-outline-blue:hover {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.btn-check:focus + .btn-outline-blue, .btn-outline-blue:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}
.btn-check:checked + .btn-outline-blue, .btn-check:active + .btn-outline-blue, .btn-outline-blue:active, .btn-outline-blue.active, .btn-outline-blue.dropdown-toggle.show {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.btn-check:checked + .btn-outline-blue:focus, .btn-check:active + .btn-outline-blue:focus, .btn-outline-blue:active:focus, .btn-outline-blue.active:focus, .btn-outline-blue.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}
.btn-outline-blue:disabled, .btn-outline-blue.disabled {
  color: #0d6efd;
  background-color: transparent;
}

.btn-outline-indigo {
  color: #6610f2;
  border-color: #6610f2;
}
.btn-outline-indigo:hover {
  color: #fff;
  background-color: #6610f2;
  border-color: #6610f2;
}
.btn-check:focus + .btn-outline-indigo, .btn-outline-indigo:focus {
  box-shadow: 0 0 0 0.25rem rgba(102, 16, 242, 0.5);
}
.btn-check:checked + .btn-outline-indigo, .btn-check:active + .btn-outline-indigo, .btn-outline-indigo:active, .btn-outline-indigo.active, .btn-outline-indigo.dropdown-toggle.show {
  color: #fff;
  background-color: #6610f2;
  border-color: #6610f2;
}
.btn-check:checked + .btn-outline-indigo:focus, .btn-check:active + .btn-outline-indigo:focus, .btn-outline-indigo:active:focus, .btn-outline-indigo.active:focus, .btn-outline-indigo.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(102, 16, 242, 0.5);
}
.btn-outline-indigo:disabled, .btn-outline-indigo.disabled {
  color: #6610f2;
  background-color: transparent;
}

.btn-outline-purple {
  color: #6f42c1;
  border-color: #6f42c1;
}
.btn-outline-purple:hover {
  color: #fff;
  background-color: #6f42c1;
  border-color: #6f42c1;
}
.btn-check:focus + .btn-outline-purple, .btn-outline-purple:focus {
  box-shadow: 0 0 0 0.25rem rgba(111, 66, 193, 0.5);
}
.btn-check:checked + .btn-outline-purple, .btn-check:active + .btn-outline-purple, .btn-outline-purple:active, .btn-outline-purple.active, .btn-outline-purple.dropdown-toggle.show {
  color: #fff;
  background-color: #6f42c1;
  border-color: #6f42c1;
}
.btn-check:checked + .btn-outline-purple:focus, .btn-check:active + .btn-outline-purple:focus, .btn-outline-purple:active:focus, .btn-outline-purple.active:focus, .btn-outline-purple.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(111, 66, 193, 0.5);
}
.btn-outline-purple:disabled, .btn-outline-purple.disabled {
  color: #6f42c1;
  background-color: transparent;
}

.btn-outline-pink {
  color: #d63384;
  border-color: #d63384;
}
.btn-outline-pink:hover {
  color: #fff;
  background-color: #d63384;
  border-color: #d63384;
}
.btn-check:focus + .btn-outline-pink, .btn-outline-pink:focus {
  box-shadow: 0 0 0 0.25rem rgba(214, 51, 132, 0.5);
}
.btn-check:checked + .btn-outline-pink, .btn-check:active + .btn-outline-pink, .btn-outline-pink:active, .btn-outline-pink.active, .btn-outline-pink.dropdown-toggle.show {
  color: #fff;
  background-color: #d63384;
  border-color: #d63384;
}
.btn-check:checked + .btn-outline-pink:focus, .btn-check:active + .btn-outline-pink:focus, .btn-outline-pink:active:focus, .btn-outline-pink.active:focus, .btn-outline-pink.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(214, 51, 132, 0.5);
}
.btn-outline-pink:disabled, .btn-outline-pink.disabled {
  color: #d63384;
  background-color: transparent;
}

.btn-outline-red {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-red:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:focus + .btn-outline-red, .btn-outline-red:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-check:checked + .btn-outline-red, .btn-check:active + .btn-outline-red, .btn-outline-red:active, .btn-outline-red.active, .btn-outline-red.dropdown-toggle.show {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:checked + .btn-outline-red:focus, .btn-check:active + .btn-outline-red:focus, .btn-outline-red:active:focus, .btn-outline-red.active:focus, .btn-outline-red.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-outline-red:disabled, .btn-outline-red.disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-orange {
  color: #fd7e14;
  border-color: #fd7e14;
}
.btn-outline-orange:hover {
  color: #000;
  background-color: #fd7e14;
  border-color: #fd7e14;
}
.btn-check:focus + .btn-outline-orange, .btn-outline-orange:focus {
  box-shadow: 0 0 0 0.25rem rgba(253, 126, 20, 0.5);
}
.btn-check:checked + .btn-outline-orange, .btn-check:active + .btn-outline-orange, .btn-outline-orange:active, .btn-outline-orange.active, .btn-outline-orange.dropdown-toggle.show {
  color: #000;
  background-color: #fd7e14;
  border-color: #fd7e14;
}
.btn-check:checked + .btn-outline-orange:focus, .btn-check:active + .btn-outline-orange:focus, .btn-outline-orange:active:focus, .btn-outline-orange.active:focus, .btn-outline-orange.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(253, 126, 20, 0.5);
}
.btn-outline-orange:disabled, .btn-outline-orange.disabled {
  color: #fd7e14;
  background-color: transparent;
}

.btn-outline-yellow {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-yellow:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:focus + .btn-outline-yellow, .btn-outline-yellow:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-check:checked + .btn-outline-yellow, .btn-check:active + .btn-outline-yellow, .btn-outline-yellow:active, .btn-outline-yellow.active, .btn-outline-yellow.dropdown-toggle.show {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:checked + .btn-outline-yellow:focus, .btn-check:active + .btn-outline-yellow:focus, .btn-outline-yellow:active:focus, .btn-outline-yellow.active:focus, .btn-outline-yellow.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-outline-yellow:disabled, .btn-outline-yellow.disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-green {
  color: #198754;
  border-color: #198754;
}
.btn-outline-green:hover {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-check:focus + .btn-outline-green, .btn-outline-green:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}
.btn-check:checked + .btn-outline-green, .btn-check:active + .btn-outline-green, .btn-outline-green:active, .btn-outline-green.active, .btn-outline-green.dropdown-toggle.show {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-check:checked + .btn-outline-green:focus, .btn-check:active + .btn-outline-green:focus, .btn-outline-green:active:focus, .btn-outline-green.active:focus, .btn-outline-green.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}
.btn-outline-green:disabled, .btn-outline-green.disabled {
  color: #198754;
  background-color: transparent;
}

.btn-outline-teal {
  color: #20c997;
  border-color: #20c997;
}
.btn-outline-teal:hover {
  color: #000;
  background-color: #20c997;
  border-color: #20c997;
}
.btn-check:focus + .btn-outline-teal, .btn-outline-teal:focus {
  box-shadow: 0 0 0 0.25rem rgba(32, 201, 151, 0.5);
}
.btn-check:checked + .btn-outline-teal, .btn-check:active + .btn-outline-teal, .btn-outline-teal:active, .btn-outline-teal.active, .btn-outline-teal.dropdown-toggle.show {
  color: #000;
  background-color: #20c997;
  border-color: #20c997;
}
.btn-check:checked + .btn-outline-teal:focus, .btn-check:active + .btn-outline-teal:focus, .btn-outline-teal:active:focus, .btn-outline-teal.active:focus, .btn-outline-teal.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(32, 201, 151, 0.5);
}
.btn-outline-teal:disabled, .btn-outline-teal.disabled {
  color: #20c997;
  background-color: transparent;
}

.btn-outline-cyan {
  color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-outline-cyan:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:focus + .btn-outline-cyan, .btn-outline-cyan:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-check:checked + .btn-outline-cyan, .btn-check:active + .btn-outline-cyan, .btn-outline-cyan:active, .btn-outline-cyan.active, .btn-outline-cyan.dropdown-toggle.show {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:checked + .btn-outline-cyan:focus, .btn-check:active + .btn-outline-cyan:focus, .btn-outline-cyan:active:focus, .btn-outline-cyan.active:focus, .btn-outline-cyan.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-outline-cyan:disabled, .btn-outline-cyan.disabled {
  color: #0dcaf0;
  background-color: transparent;
}

.btn-outline-white {
  color: #fff;
  border-color: #fff;
}
.btn-outline-white:hover {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}
.btn-check:focus + .btn-outline-white, .btn-outline-white:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-check:checked + .btn-outline-white, .btn-check:active + .btn-outline-white, .btn-outline-white:active, .btn-outline-white.active, .btn-outline-white.dropdown-toggle.show {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}
.btn-check:checked + .btn-outline-white:focus, .btn-check:active + .btn-outline-white:focus, .btn-outline-white:active:focus, .btn-outline-white.active:focus, .btn-outline-white.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(255, 255, 255, 0.5);
}
.btn-outline-white:disabled, .btn-outline-white.disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-gray {
  color: #7a8288;
  border-color: #7a8288;
}
.btn-outline-gray:hover {
  color: #000;
  background-color: #7a8288;
  border-color: #7a8288;
}
.btn-check:focus + .btn-outline-gray, .btn-outline-gray:focus {
  box-shadow: 0 0 0 0.25rem rgba(122, 130, 136, 0.5);
}
.btn-check:checked + .btn-outline-gray, .btn-check:active + .btn-outline-gray, .btn-outline-gray:active, .btn-outline-gray.active, .btn-outline-gray.dropdown-toggle.show {
  color: #000;
  background-color: #7a8288;
  border-color: #7a8288;
}
.btn-check:checked + .btn-outline-gray:focus, .btn-check:active + .btn-outline-gray:focus, .btn-outline-gray:active:focus, .btn-outline-gray.active:focus, .btn-outline-gray.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(122, 130, 136, 0.5);
}
.btn-outline-gray:disabled, .btn-outline-gray.disabled {
  color: #7a8288;
  background-color: transparent;
}

.btn-outline-gray-dark {
  color: #3a3f44;
  border-color: #3a3f44;
}
.btn-outline-gray-dark:hover {
  color: #fff;
  background-color: #3a3f44;
  border-color: #3a3f44;
}
.btn-check:focus + .btn-outline-gray-dark, .btn-outline-gray-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(58, 63, 68, 0.5);
}
.btn-check:checked + .btn-outline-gray-dark, .btn-check:active + .btn-outline-gray-dark, .btn-outline-gray-dark:active, .btn-outline-gray-dark.active, .btn-outline-gray-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #3a3f44;
  border-color: #3a3f44;
}
.btn-check:checked + .btn-outline-gray-dark:focus, .btn-check:active + .btn-outline-gray-dark:focus, .btn-outline-gray-dark:active:focus, .btn-outline-gray-dark.active:focus, .btn-outline-gray-dark.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(58, 63, 68, 0.5);
}
.btn-outline-gray-dark:disabled, .btn-outline-gray-dark.disabled {
  color: #3a3f44;
  background-color: transparent;
}

.btn-outline-blue-100 {
  color: #cfe2ff;
  border-color: #cfe2ff;
}
.btn-outline-blue-100:hover {
  color: #000;
  background-color: #cfe2ff;
  border-color: #cfe2ff;
}
.btn-check:focus + .btn-outline-blue-100, .btn-outline-blue-100:focus {
  box-shadow: 0 0 0 0.25rem rgba(207, 226, 255, 0.5);
}
.btn-check:checked + .btn-outline-blue-100, .btn-check:active + .btn-outline-blue-100, .btn-outline-blue-100:active, .btn-outline-blue-100.active, .btn-outline-blue-100.dropdown-toggle.show {
  color: #000;
  background-color: #cfe2ff;
  border-color: #cfe2ff;
}
.btn-check:checked + .btn-outline-blue-100:focus, .btn-check:active + .btn-outline-blue-100:focus, .btn-outline-blue-100:active:focus, .btn-outline-blue-100.active:focus, .btn-outline-blue-100.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(207, 226, 255, 0.5);
}
.btn-outline-blue-100:disabled, .btn-outline-blue-100.disabled {
  color: #cfe2ff;
  background-color: transparent;
}

.btn-outline-blue-200 {
  color: #9ec5fe;
  border-color: #9ec5fe;
}
.btn-outline-blue-200:hover {
  color: #000;
  background-color: #9ec5fe;
  border-color: #9ec5fe;
}
.btn-check:focus + .btn-outline-blue-200, .btn-outline-blue-200:focus {
  box-shadow: 0 0 0 0.25rem rgba(158, 197, 254, 0.5);
}
.btn-check:checked + .btn-outline-blue-200, .btn-check:active + .btn-outline-blue-200, .btn-outline-blue-200:active, .btn-outline-blue-200.active, .btn-outline-blue-200.dropdown-toggle.show {
  color: #000;
  background-color: #9ec5fe;
  border-color: #9ec5fe;
}
.btn-check:checked + .btn-outline-blue-200:focus, .btn-check:active + .btn-outline-blue-200:focus, .btn-outline-blue-200:active:focus, .btn-outline-blue-200.active:focus, .btn-outline-blue-200.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(158, 197, 254, 0.5);
}
.btn-outline-blue-200:disabled, .btn-outline-blue-200.disabled {
  color: #9ec5fe;
  background-color: transparent;
}

.btn-outline-blue-300 {
  color: #6ea8fe;
  border-color: #6ea8fe;
}
.btn-outline-blue-300:hover {
  color: #000;
  background-color: #6ea8fe;
  border-color: #6ea8fe;
}
.btn-check:focus + .btn-outline-blue-300, .btn-outline-blue-300:focus {
  box-shadow: 0 0 0 0.25rem rgba(110, 168, 254, 0.5);
}
.btn-check:checked + .btn-outline-blue-300, .btn-check:active + .btn-outline-blue-300, .btn-outline-blue-300:active, .btn-outline-blue-300.active, .btn-outline-blue-300.dropdown-toggle.show {
  color: #000;
  background-color: #6ea8fe;
  border-color: #6ea8fe;
}
.btn-check:checked + .btn-outline-blue-300:focus, .btn-check:active + .btn-outline-blue-300:focus, .btn-outline-blue-300:active:focus, .btn-outline-blue-300.active:focus, .btn-outline-blue-300.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(110, 168, 254, 0.5);
}
.btn-outline-blue-300:disabled, .btn-outline-blue-300.disabled {
  color: #6ea8fe;
  background-color: transparent;
}

.btn-outline-blue-400 {
  color: #3d8bfd;
  border-color: #3d8bfd;
}
.btn-outline-blue-400:hover {
  color: #000;
  background-color: #3d8bfd;
  border-color: #3d8bfd;
}
.btn-check:focus + .btn-outline-blue-400, .btn-outline-blue-400:focus {
  box-shadow: 0 0 0 0.25rem rgba(61, 139, 253, 0.5);
}
.btn-check:checked + .btn-outline-blue-400, .btn-check:active + .btn-outline-blue-400, .btn-outline-blue-400:active, .btn-outline-blue-400.active, .btn-outline-blue-400.dropdown-toggle.show {
  color: #000;
  background-color: #3d8bfd;
  border-color: #3d8bfd;
}
.btn-check:checked + .btn-outline-blue-400:focus, .btn-check:active + .btn-outline-blue-400:focus, .btn-outline-blue-400:active:focus, .btn-outline-blue-400.active:focus, .btn-outline-blue-400.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(61, 139, 253, 0.5);
}
.btn-outline-blue-400:disabled, .btn-outline-blue-400.disabled {
  color: #3d8bfd;
  background-color: transparent;
}

.btn-outline-blue-500 {
  color: #0d6efd;
  border-color: #0d6efd;
}
.btn-outline-blue-500:hover {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.btn-check:focus + .btn-outline-blue-500, .btn-outline-blue-500:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}
.btn-check:checked + .btn-outline-blue-500, .btn-check:active + .btn-outline-blue-500, .btn-outline-blue-500:active, .btn-outline-blue-500.active, .btn-outline-blue-500.dropdown-toggle.show {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.btn-check:checked + .btn-outline-blue-500:focus, .btn-check:active + .btn-outline-blue-500:focus, .btn-outline-blue-500:active:focus, .btn-outline-blue-500.active:focus, .btn-outline-blue-500.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}
.btn-outline-blue-500:disabled, .btn-outline-blue-500.disabled {
  color: #0d6efd;
  background-color: transparent;
}

.btn-outline-blue-600 {
  color: #0a58ca;
  border-color: #0a58ca;
}
.btn-outline-blue-600:hover {
  color: #fff;
  background-color: #0a58ca;
  border-color: #0a58ca;
}
.btn-check:focus + .btn-outline-blue-600, .btn-outline-blue-600:focus {
  box-shadow: 0 0 0 0.25rem rgba(10, 88, 202, 0.5);
}
.btn-check:checked + .btn-outline-blue-600, .btn-check:active + .btn-outline-blue-600, .btn-outline-blue-600:active, .btn-outline-blue-600.active, .btn-outline-blue-600.dropdown-toggle.show {
  color: #fff;
  background-color: #0a58ca;
  border-color: #0a58ca;
}
.btn-check:checked + .btn-outline-blue-600:focus, .btn-check:active + .btn-outline-blue-600:focus, .btn-outline-blue-600:active:focus, .btn-outline-blue-600.active:focus, .btn-outline-blue-600.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(10, 88, 202, 0.5);
}
.btn-outline-blue-600:disabled, .btn-outline-blue-600.disabled {
  color: #0a58ca;
  background-color: transparent;
}

.btn-outline-blue-700 {
  color: #084298;
  border-color: #084298;
}
.btn-outline-blue-700:hover {
  color: #fff;
  background-color: #084298;
  border-color: #084298;
}
.btn-check:focus + .btn-outline-blue-700, .btn-outline-blue-700:focus {
  box-shadow: 0 0 0 0.25rem rgba(8, 66, 152, 0.5);
}
.btn-check:checked + .btn-outline-blue-700, .btn-check:active + .btn-outline-blue-700, .btn-outline-blue-700:active, .btn-outline-blue-700.active, .btn-outline-blue-700.dropdown-toggle.show {
  color: #fff;
  background-color: #084298;
  border-color: #084298;
}
.btn-check:checked + .btn-outline-blue-700:focus, .btn-check:active + .btn-outline-blue-700:focus, .btn-outline-blue-700:active:focus, .btn-outline-blue-700.active:focus, .btn-outline-blue-700.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(8, 66, 152, 0.5);
}
.btn-outline-blue-700:disabled, .btn-outline-blue-700.disabled {
  color: #084298;
  background-color: transparent;
}

.btn-outline-blue-800 {
  color: #052c65;
  border-color: #052c65;
}
.btn-outline-blue-800:hover {
  color: #fff;
  background-color: #052c65;
  border-color: #052c65;
}
.btn-check:focus + .btn-outline-blue-800, .btn-outline-blue-800:focus {
  box-shadow: 0 0 0 0.25rem rgba(5, 44, 101, 0.5);
}
.btn-check:checked + .btn-outline-blue-800, .btn-check:active + .btn-outline-blue-800, .btn-outline-blue-800:active, .btn-outline-blue-800.active, .btn-outline-blue-800.dropdown-toggle.show {
  color: #fff;
  background-color: #052c65;
  border-color: #052c65;
}
.btn-check:checked + .btn-outline-blue-800:focus, .btn-check:active + .btn-outline-blue-800:focus, .btn-outline-blue-800:active:focus, .btn-outline-blue-800.active:focus, .btn-outline-blue-800.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(5, 44, 101, 0.5);
}
.btn-outline-blue-800:disabled, .btn-outline-blue-800.disabled {
  color: #052c65;
  background-color: transparent;
}

.btn-outline-blue-900 {
  color: #031633;
  border-color: #031633;
}
.btn-outline-blue-900:hover {
  color: #fff;
  background-color: #031633;
  border-color: #031633;
}
.btn-check:focus + .btn-outline-blue-900, .btn-outline-blue-900:focus {
  box-shadow: 0 0 0 0.25rem rgba(3, 22, 51, 0.5);
}
.btn-check:checked + .btn-outline-blue-900, .btn-check:active + .btn-outline-blue-900, .btn-outline-blue-900:active, .btn-outline-blue-900.active, .btn-outline-blue-900.dropdown-toggle.show {
  color: #fff;
  background-color: #031633;
  border-color: #031633;
}
.btn-check:checked + .btn-outline-blue-900:focus, .btn-check:active + .btn-outline-blue-900:focus, .btn-outline-blue-900:active:focus, .btn-outline-blue-900.active:focus, .btn-outline-blue-900.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(3, 22, 51, 0.5);
}
.btn-outline-blue-900:disabled, .btn-outline-blue-900.disabled {
  color: #031633;
  background-color: transparent;
}

.btn-outline-indigo-100 {
  color: #e0cffc;
  border-color: #e0cffc;
}
.btn-outline-indigo-100:hover {
  color: #000;
  background-color: #e0cffc;
  border-color: #e0cffc;
}
.btn-check:focus + .btn-outline-indigo-100, .btn-outline-indigo-100:focus {
  box-shadow: 0 0 0 0.25rem rgba(224, 207, 252, 0.5);
}
.btn-check:checked + .btn-outline-indigo-100, .btn-check:active + .btn-outline-indigo-100, .btn-outline-indigo-100:active, .btn-outline-indigo-100.active, .btn-outline-indigo-100.dropdown-toggle.show {
  color: #000;
  background-color: #e0cffc;
  border-color: #e0cffc;
}
.btn-check:checked + .btn-outline-indigo-100:focus, .btn-check:active + .btn-outline-indigo-100:focus, .btn-outline-indigo-100:active:focus, .btn-outline-indigo-100.active:focus, .btn-outline-indigo-100.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(224, 207, 252, 0.5);
}
.btn-outline-indigo-100:disabled, .btn-outline-indigo-100.disabled {
  color: #e0cffc;
  background-color: transparent;
}

.btn-outline-indigo-200 {
  color: #c29ffa;
  border-color: #c29ffa;
}
.btn-outline-indigo-200:hover {
  color: #000;
  background-color: #c29ffa;
  border-color: #c29ffa;
}
.btn-check:focus + .btn-outline-indigo-200, .btn-outline-indigo-200:focus {
  box-shadow: 0 0 0 0.25rem rgba(194, 159, 250, 0.5);
}
.btn-check:checked + .btn-outline-indigo-200, .btn-check:active + .btn-outline-indigo-200, .btn-outline-indigo-200:active, .btn-outline-indigo-200.active, .btn-outline-indigo-200.dropdown-toggle.show {
  color: #000;
  background-color: #c29ffa;
  border-color: #c29ffa;
}
.btn-check:checked + .btn-outline-indigo-200:focus, .btn-check:active + .btn-outline-indigo-200:focus, .btn-outline-indigo-200:active:focus, .btn-outline-indigo-200.active:focus, .btn-outline-indigo-200.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(194, 159, 250, 0.5);
}
.btn-outline-indigo-200:disabled, .btn-outline-indigo-200.disabled {
  color: #c29ffa;
  background-color: transparent;
}

.btn-outline-indigo-300 {
  color: #a370f7;
  border-color: #a370f7;
}
.btn-outline-indigo-300:hover {
  color: #000;
  background-color: #a370f7;
  border-color: #a370f7;
}
.btn-check:focus + .btn-outline-indigo-300, .btn-outline-indigo-300:focus {
  box-shadow: 0 0 0 0.25rem rgba(163, 112, 247, 0.5);
}
.btn-check:checked + .btn-outline-indigo-300, .btn-check:active + .btn-outline-indigo-300, .btn-outline-indigo-300:active, .btn-outline-indigo-300.active, .btn-outline-indigo-300.dropdown-toggle.show {
  color: #000;
  background-color: #a370f7;
  border-color: #a370f7;
}
.btn-check:checked + .btn-outline-indigo-300:focus, .btn-check:active + .btn-outline-indigo-300:focus, .btn-outline-indigo-300:active:focus, .btn-outline-indigo-300.active:focus, .btn-outline-indigo-300.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(163, 112, 247, 0.5);
}
.btn-outline-indigo-300:disabled, .btn-outline-indigo-300.disabled {
  color: #a370f7;
  background-color: transparent;
}

.btn-outline-indigo-400 {
  color: #8540f5;
  border-color: #8540f5;
}
.btn-outline-indigo-400:hover {
  color: #fff;
  background-color: #8540f5;
  border-color: #8540f5;
}
.btn-check:focus + .btn-outline-indigo-400, .btn-outline-indigo-400:focus {
  box-shadow: 0 0 0 0.25rem rgba(133, 64, 245, 0.5);
}
.btn-check:checked + .btn-outline-indigo-400, .btn-check:active + .btn-outline-indigo-400, .btn-outline-indigo-400:active, .btn-outline-indigo-400.active, .btn-outline-indigo-400.dropdown-toggle.show {
  color: #fff;
  background-color: #8540f5;
  border-color: #8540f5;
}
.btn-check:checked + .btn-outline-indigo-400:focus, .btn-check:active + .btn-outline-indigo-400:focus, .btn-outline-indigo-400:active:focus, .btn-outline-indigo-400.active:focus, .btn-outline-indigo-400.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(133, 64, 245, 0.5);
}
.btn-outline-indigo-400:disabled, .btn-outline-indigo-400.disabled {
  color: #8540f5;
  background-color: transparent;
}

.btn-outline-indigo-500 {
  color: #6610f2;
  border-color: #6610f2;
}
.btn-outline-indigo-500:hover {
  color: #fff;
  background-color: #6610f2;
  border-color: #6610f2;
}
.btn-check:focus + .btn-outline-indigo-500, .btn-outline-indigo-500:focus {
  box-shadow: 0 0 0 0.25rem rgba(102, 16, 242, 0.5);
}
.btn-check:checked + .btn-outline-indigo-500, .btn-check:active + .btn-outline-indigo-500, .btn-outline-indigo-500:active, .btn-outline-indigo-500.active, .btn-outline-indigo-500.dropdown-toggle.show {
  color: #fff;
  background-color: #6610f2;
  border-color: #6610f2;
}
.btn-check:checked + .btn-outline-indigo-500:focus, .btn-check:active + .btn-outline-indigo-500:focus, .btn-outline-indigo-500:active:focus, .btn-outline-indigo-500.active:focus, .btn-outline-indigo-500.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(102, 16, 242, 0.5);
}
.btn-outline-indigo-500:disabled, .btn-outline-indigo-500.disabled {
  color: #6610f2;
  background-color: transparent;
}

.btn-outline-indigo-600 {
  color: #520dc2;
  border-color: #520dc2;
}
.btn-outline-indigo-600:hover {
  color: #fff;
  background-color: #520dc2;
  border-color: #520dc2;
}
.btn-check:focus + .btn-outline-indigo-600, .btn-outline-indigo-600:focus {
  box-shadow: 0 0 0 0.25rem rgba(82, 13, 194, 0.5);
}
.btn-check:checked + .btn-outline-indigo-600, .btn-check:active + .btn-outline-indigo-600, .btn-outline-indigo-600:active, .btn-outline-indigo-600.active, .btn-outline-indigo-600.dropdown-toggle.show {
  color: #fff;
  background-color: #520dc2;
  border-color: #520dc2;
}
.btn-check:checked + .btn-outline-indigo-600:focus, .btn-check:active + .btn-outline-indigo-600:focus, .btn-outline-indigo-600:active:focus, .btn-outline-indigo-600.active:focus, .btn-outline-indigo-600.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(82, 13, 194, 0.5);
}
.btn-outline-indigo-600:disabled, .btn-outline-indigo-600.disabled {
  color: #520dc2;
  background-color: transparent;
}

.btn-outline-indigo-700 {
  color: #3d0a91;
  border-color: #3d0a91;
}
.btn-outline-indigo-700:hover {
  color: #fff;
  background-color: #3d0a91;
  border-color: #3d0a91;
}
.btn-check:focus + .btn-outline-indigo-700, .btn-outline-indigo-700:focus {
  box-shadow: 0 0 0 0.25rem rgba(61, 10, 145, 0.5);
}
.btn-check:checked + .btn-outline-indigo-700, .btn-check:active + .btn-outline-indigo-700, .btn-outline-indigo-700:active, .btn-outline-indigo-700.active, .btn-outline-indigo-700.dropdown-toggle.show {
  color: #fff;
  background-color: #3d0a91;
  border-color: #3d0a91;
}
.btn-check:checked + .btn-outline-indigo-700:focus, .btn-check:active + .btn-outline-indigo-700:focus, .btn-outline-indigo-700:active:focus, .btn-outline-indigo-700.active:focus, .btn-outline-indigo-700.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(61, 10, 145, 0.5);
}
.btn-outline-indigo-700:disabled, .btn-outline-indigo-700.disabled {
  color: #3d0a91;
  background-color: transparent;
}

.btn-outline-indigo-800 {
  color: #290661;
  border-color: #290661;
}
.btn-outline-indigo-800:hover {
  color: #fff;
  background-color: #290661;
  border-color: #290661;
}
.btn-check:focus + .btn-outline-indigo-800, .btn-outline-indigo-800:focus {
  box-shadow: 0 0 0 0.25rem rgba(41, 6, 97, 0.5);
}
.btn-check:checked + .btn-outline-indigo-800, .btn-check:active + .btn-outline-indigo-800, .btn-outline-indigo-800:active, .btn-outline-indigo-800.active, .btn-outline-indigo-800.dropdown-toggle.show {
  color: #fff;
  background-color: #290661;
  border-color: #290661;
}
.btn-check:checked + .btn-outline-indigo-800:focus, .btn-check:active + .btn-outline-indigo-800:focus, .btn-outline-indigo-800:active:focus, .btn-outline-indigo-800.active:focus, .btn-outline-indigo-800.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(41, 6, 97, 0.5);
}
.btn-outline-indigo-800:disabled, .btn-outline-indigo-800.disabled {
  color: #290661;
  background-color: transparent;
}

.btn-outline-indigo-900 {
  color: #140330;
  border-color: #140330;
}
.btn-outline-indigo-900:hover {
  color: #fff;
  background-color: #140330;
  border-color: #140330;
}
.btn-check:focus + .btn-outline-indigo-900, .btn-outline-indigo-900:focus {
  box-shadow: 0 0 0 0.25rem rgba(20, 3, 48, 0.5);
}
.btn-check:checked + .btn-outline-indigo-900, .btn-check:active + .btn-outline-indigo-900, .btn-outline-indigo-900:active, .btn-outline-indigo-900.active, .btn-outline-indigo-900.dropdown-toggle.show {
  color: #fff;
  background-color: #140330;
  border-color: #140330;
}
.btn-check:checked + .btn-outline-indigo-900:focus, .btn-check:active + .btn-outline-indigo-900:focus, .btn-outline-indigo-900:active:focus, .btn-outline-indigo-900.active:focus, .btn-outline-indigo-900.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(20, 3, 48, 0.5);
}
.btn-outline-indigo-900:disabled, .btn-outline-indigo-900.disabled {
  color: #140330;
  background-color: transparent;
}

.btn-outline-purple-100 {
  color: #e2d9f3;
  border-color: #e2d9f3;
}
.btn-outline-purple-100:hover {
  color: #000;
  background-color: #e2d9f3;
  border-color: #e2d9f3;
}
.btn-check:focus + .btn-outline-purple-100, .btn-outline-purple-100:focus {
  box-shadow: 0 0 0 0.25rem rgba(226, 217, 243, 0.5);
}
.btn-check:checked + .btn-outline-purple-100, .btn-check:active + .btn-outline-purple-100, .btn-outline-purple-100:active, .btn-outline-purple-100.active, .btn-outline-purple-100.dropdown-toggle.show {
  color: #000;
  background-color: #e2d9f3;
  border-color: #e2d9f3;
}
.btn-check:checked + .btn-outline-purple-100:focus, .btn-check:active + .btn-outline-purple-100:focus, .btn-outline-purple-100:active:focus, .btn-outline-purple-100.active:focus, .btn-outline-purple-100.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(226, 217, 243, 0.5);
}
.btn-outline-purple-100:disabled, .btn-outline-purple-100.disabled {
  color: #e2d9f3;
  background-color: transparent;
}

.btn-outline-purple-200 {
  color: #c5b3e6;
  border-color: #c5b3e6;
}
.btn-outline-purple-200:hover {
  color: #000;
  background-color: #c5b3e6;
  border-color: #c5b3e6;
}
.btn-check:focus + .btn-outline-purple-200, .btn-outline-purple-200:focus {
  box-shadow: 0 0 0 0.25rem rgba(197, 179, 230, 0.5);
}
.btn-check:checked + .btn-outline-purple-200, .btn-check:active + .btn-outline-purple-200, .btn-outline-purple-200:active, .btn-outline-purple-200.active, .btn-outline-purple-200.dropdown-toggle.show {
  color: #000;
  background-color: #c5b3e6;
  border-color: #c5b3e6;
}
.btn-check:checked + .btn-outline-purple-200:focus, .btn-check:active + .btn-outline-purple-200:focus, .btn-outline-purple-200:active:focus, .btn-outline-purple-200.active:focus, .btn-outline-purple-200.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(197, 179, 230, 0.5);
}
.btn-outline-purple-200:disabled, .btn-outline-purple-200.disabled {
  color: #c5b3e6;
  background-color: transparent;
}

.btn-outline-purple-300 {
  color: #a98eda;
  border-color: #a98eda;
}
.btn-outline-purple-300:hover {
  color: #000;
  background-color: #a98eda;
  border-color: #a98eda;
}
.btn-check:focus + .btn-outline-purple-300, .btn-outline-purple-300:focus {
  box-shadow: 0 0 0 0.25rem rgba(169, 142, 218, 0.5);
}
.btn-check:checked + .btn-outline-purple-300, .btn-check:active + .btn-outline-purple-300, .btn-outline-purple-300:active, .btn-outline-purple-300.active, .btn-outline-purple-300.dropdown-toggle.show {
  color: #000;
  background-color: #a98eda;
  border-color: #a98eda;
}
.btn-check:checked + .btn-outline-purple-300:focus, .btn-check:active + .btn-outline-purple-300:focus, .btn-outline-purple-300:active:focus, .btn-outline-purple-300.active:focus, .btn-outline-purple-300.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(169, 142, 218, 0.5);
}
.btn-outline-purple-300:disabled, .btn-outline-purple-300.disabled {
  color: #a98eda;
  background-color: transparent;
}

.btn-outline-purple-400 {
  color: #8c68cd;
  border-color: #8c68cd;
}
.btn-outline-purple-400:hover {
  color: #000;
  background-color: #8c68cd;
  border-color: #8c68cd;
}
.btn-check:focus + .btn-outline-purple-400, .btn-outline-purple-400:focus {
  box-shadow: 0 0 0 0.25rem rgba(140, 104, 205, 0.5);
}
.btn-check:checked + .btn-outline-purple-400, .btn-check:active + .btn-outline-purple-400, .btn-outline-purple-400:active, .btn-outline-purple-400.active, .btn-outline-purple-400.dropdown-toggle.show {
  color: #000;
  background-color: #8c68cd;
  border-color: #8c68cd;
}
.btn-check:checked + .btn-outline-purple-400:focus, .btn-check:active + .btn-outline-purple-400:focus, .btn-outline-purple-400:active:focus, .btn-outline-purple-400.active:focus, .btn-outline-purple-400.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(140, 104, 205, 0.5);
}
.btn-outline-purple-400:disabled, .btn-outline-purple-400.disabled {
  color: #8c68cd;
  background-color: transparent;
}

.btn-outline-purple-500 {
  color: #6f42c1;
  border-color: #6f42c1;
}
.btn-outline-purple-500:hover {
  color: #fff;
  background-color: #6f42c1;
  border-color: #6f42c1;
}
.btn-check:focus + .btn-outline-purple-500, .btn-outline-purple-500:focus {
  box-shadow: 0 0 0 0.25rem rgba(111, 66, 193, 0.5);
}
.btn-check:checked + .btn-outline-purple-500, .btn-check:active + .btn-outline-purple-500, .btn-outline-purple-500:active, .btn-outline-purple-500.active, .btn-outline-purple-500.dropdown-toggle.show {
  color: #fff;
  background-color: #6f42c1;
  border-color: #6f42c1;
}
.btn-check:checked + .btn-outline-purple-500:focus, .btn-check:active + .btn-outline-purple-500:focus, .btn-outline-purple-500:active:focus, .btn-outline-purple-500.active:focus, .btn-outline-purple-500.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(111, 66, 193, 0.5);
}
.btn-outline-purple-500:disabled, .btn-outline-purple-500.disabled {
  color: #6f42c1;
  background-color: transparent;
}

.btn-outline-purple-600 {
  color: #59359a;
  border-color: #59359a;
}
.btn-outline-purple-600:hover {
  color: #fff;
  background-color: #59359a;
  border-color: #59359a;
}
.btn-check:focus + .btn-outline-purple-600, .btn-outline-purple-600:focus {
  box-shadow: 0 0 0 0.25rem rgba(89, 53, 154, 0.5);
}
.btn-check:checked + .btn-outline-purple-600, .btn-check:active + .btn-outline-purple-600, .btn-outline-purple-600:active, .btn-outline-purple-600.active, .btn-outline-purple-600.dropdown-toggle.show {
  color: #fff;
  background-color: #59359a;
  border-color: #59359a;
}
.btn-check:checked + .btn-outline-purple-600:focus, .btn-check:active + .btn-outline-purple-600:focus, .btn-outline-purple-600:active:focus, .btn-outline-purple-600.active:focus, .btn-outline-purple-600.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(89, 53, 154, 0.5);
}
.btn-outline-purple-600:disabled, .btn-outline-purple-600.disabled {
  color: #59359a;
  background-color: transparent;
}

.btn-outline-purple-700 {
  color: #432874;
  border-color: #432874;
}
.btn-outline-purple-700:hover {
  color: #fff;
  background-color: #432874;
  border-color: #432874;
}
.btn-check:focus + .btn-outline-purple-700, .btn-outline-purple-700:focus {
  box-shadow: 0 0 0 0.25rem rgba(67, 40, 116, 0.5);
}
.btn-check:checked + .btn-outline-purple-700, .btn-check:active + .btn-outline-purple-700, .btn-outline-purple-700:active, .btn-outline-purple-700.active, .btn-outline-purple-700.dropdown-toggle.show {
  color: #fff;
  background-color: #432874;
  border-color: #432874;
}
.btn-check:checked + .btn-outline-purple-700:focus, .btn-check:active + .btn-outline-purple-700:focus, .btn-outline-purple-700:active:focus, .btn-outline-purple-700.active:focus, .btn-outline-purple-700.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(67, 40, 116, 0.5);
}
.btn-outline-purple-700:disabled, .btn-outline-purple-700.disabled {
  color: #432874;
  background-color: transparent;
}

.btn-outline-purple-800 {
  color: #2c1a4d;
  border-color: #2c1a4d;
}
.btn-outline-purple-800:hover {
  color: #fff;
  background-color: #2c1a4d;
  border-color: #2c1a4d;
}
.btn-check:focus + .btn-outline-purple-800, .btn-outline-purple-800:focus {
  box-shadow: 0 0 0 0.25rem rgba(44, 26, 77, 0.5);
}
.btn-check:checked + .btn-outline-purple-800, .btn-check:active + .btn-outline-purple-800, .btn-outline-purple-800:active, .btn-outline-purple-800.active, .btn-outline-purple-800.dropdown-toggle.show {
  color: #fff;
  background-color: #2c1a4d;
  border-color: #2c1a4d;
}
.btn-check:checked + .btn-outline-purple-800:focus, .btn-check:active + .btn-outline-purple-800:focus, .btn-outline-purple-800:active:focus, .btn-outline-purple-800.active:focus, .btn-outline-purple-800.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(44, 26, 77, 0.5);
}
.btn-outline-purple-800:disabled, .btn-outline-purple-800.disabled {
  color: #2c1a4d;
  background-color: transparent;
}

.btn-outline-purple-900 {
  color: #160d27;
  border-color: #160d27;
}
.btn-outline-purple-900:hover {
  color: #fff;
  background-color: #160d27;
  border-color: #160d27;
}
.btn-check:focus + .btn-outline-purple-900, .btn-outline-purple-900:focus {
  box-shadow: 0 0 0 0.25rem rgba(22, 13, 39, 0.5);
}
.btn-check:checked + .btn-outline-purple-900, .btn-check:active + .btn-outline-purple-900, .btn-outline-purple-900:active, .btn-outline-purple-900.active, .btn-outline-purple-900.dropdown-toggle.show {
  color: #fff;
  background-color: #160d27;
  border-color: #160d27;
}
.btn-check:checked + .btn-outline-purple-900:focus, .btn-check:active + .btn-outline-purple-900:focus, .btn-outline-purple-900:active:focus, .btn-outline-purple-900.active:focus, .btn-outline-purple-900.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(22, 13, 39, 0.5);
}
.btn-outline-purple-900:disabled, .btn-outline-purple-900.disabled {
  color: #160d27;
  background-color: transparent;
}

.btn-outline-pink-100 {
  color: #f7d6e6;
  border-color: #f7d6e6;
}
.btn-outline-pink-100:hover {
  color: #000;
  background-color: #f7d6e6;
  border-color: #f7d6e6;
}
.btn-check:focus + .btn-outline-pink-100, .btn-outline-pink-100:focus {
  box-shadow: 0 0 0 0.25rem rgba(247, 214, 230, 0.5);
}
.btn-check:checked + .btn-outline-pink-100, .btn-check:active + .btn-outline-pink-100, .btn-outline-pink-100:active, .btn-outline-pink-100.active, .btn-outline-pink-100.dropdown-toggle.show {
  color: #000;
  background-color: #f7d6e6;
  border-color: #f7d6e6;
}
.btn-check:checked + .btn-outline-pink-100:focus, .btn-check:active + .btn-outline-pink-100:focus, .btn-outline-pink-100:active:focus, .btn-outline-pink-100.active:focus, .btn-outline-pink-100.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(247, 214, 230, 0.5);
}
.btn-outline-pink-100:disabled, .btn-outline-pink-100.disabled {
  color: #f7d6e6;
  background-color: transparent;
}

.btn-outline-pink-200 {
  color: #efadce;
  border-color: #efadce;
}
.btn-outline-pink-200:hover {
  color: #000;
  background-color: #efadce;
  border-color: #efadce;
}
.btn-check:focus + .btn-outline-pink-200, .btn-outline-pink-200:focus {
  box-shadow: 0 0 0 0.25rem rgba(239, 173, 206, 0.5);
}
.btn-check:checked + .btn-outline-pink-200, .btn-check:active + .btn-outline-pink-200, .btn-outline-pink-200:active, .btn-outline-pink-200.active, .btn-outline-pink-200.dropdown-toggle.show {
  color: #000;
  background-color: #efadce;
  border-color: #efadce;
}
.btn-check:checked + .btn-outline-pink-200:focus, .btn-check:active + .btn-outline-pink-200:focus, .btn-outline-pink-200:active:focus, .btn-outline-pink-200.active:focus, .btn-outline-pink-200.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(239, 173, 206, 0.5);
}
.btn-outline-pink-200:disabled, .btn-outline-pink-200.disabled {
  color: #efadce;
  background-color: transparent;
}

.btn-outline-pink-300 {
  color: #e685b5;
  border-color: #e685b5;
}
.btn-outline-pink-300:hover {
  color: #000;
  background-color: #e685b5;
  border-color: #e685b5;
}
.btn-check:focus + .btn-outline-pink-300, .btn-outline-pink-300:focus {
  box-shadow: 0 0 0 0.25rem rgba(230, 133, 181, 0.5);
}
.btn-check:checked + .btn-outline-pink-300, .btn-check:active + .btn-outline-pink-300, .btn-outline-pink-300:active, .btn-outline-pink-300.active, .btn-outline-pink-300.dropdown-toggle.show {
  color: #000;
  background-color: #e685b5;
  border-color: #e685b5;
}
.btn-check:checked + .btn-outline-pink-300:focus, .btn-check:active + .btn-outline-pink-300:focus, .btn-outline-pink-300:active:focus, .btn-outline-pink-300.active:focus, .btn-outline-pink-300.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(230, 133, 181, 0.5);
}
.btn-outline-pink-300:disabled, .btn-outline-pink-300.disabled {
  color: #e685b5;
  background-color: transparent;
}

.btn-outline-pink-400 {
  color: #de5c9d;
  border-color: #de5c9d;
}
.btn-outline-pink-400:hover {
  color: #000;
  background-color: #de5c9d;
  border-color: #de5c9d;
}
.btn-check:focus + .btn-outline-pink-400, .btn-outline-pink-400:focus {
  box-shadow: 0 0 0 0.25rem rgba(222, 92, 157, 0.5);
}
.btn-check:checked + .btn-outline-pink-400, .btn-check:active + .btn-outline-pink-400, .btn-outline-pink-400:active, .btn-outline-pink-400.active, .btn-outline-pink-400.dropdown-toggle.show {
  color: #000;
  background-color: #de5c9d;
  border-color: #de5c9d;
}
.btn-check:checked + .btn-outline-pink-400:focus, .btn-check:active + .btn-outline-pink-400:focus, .btn-outline-pink-400:active:focus, .btn-outline-pink-400.active:focus, .btn-outline-pink-400.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(222, 92, 157, 0.5);
}
.btn-outline-pink-400:disabled, .btn-outline-pink-400.disabled {
  color: #de5c9d;
  background-color: transparent;
}

.btn-outline-pink-500 {
  color: #d63384;
  border-color: #d63384;
}
.btn-outline-pink-500:hover {
  color: #fff;
  background-color: #d63384;
  border-color: #d63384;
}
.btn-check:focus + .btn-outline-pink-500, .btn-outline-pink-500:focus {
  box-shadow: 0 0 0 0.25rem rgba(214, 51, 132, 0.5);
}
.btn-check:checked + .btn-outline-pink-500, .btn-check:active + .btn-outline-pink-500, .btn-outline-pink-500:active, .btn-outline-pink-500.active, .btn-outline-pink-500.dropdown-toggle.show {
  color: #fff;
  background-color: #d63384;
  border-color: #d63384;
}
.btn-check:checked + .btn-outline-pink-500:focus, .btn-check:active + .btn-outline-pink-500:focus, .btn-outline-pink-500:active:focus, .btn-outline-pink-500.active:focus, .btn-outline-pink-500.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(214, 51, 132, 0.5);
}
.btn-outline-pink-500:disabled, .btn-outline-pink-500.disabled {
  color: #d63384;
  background-color: transparent;
}

.btn-outline-pink-600 {
  color: #ab296a;
  border-color: #ab296a;
}
.btn-outline-pink-600:hover {
  color: #fff;
  background-color: #ab296a;
  border-color: #ab296a;
}
.btn-check:focus + .btn-outline-pink-600, .btn-outline-pink-600:focus {
  box-shadow: 0 0 0 0.25rem rgba(171, 41, 106, 0.5);
}
.btn-check:checked + .btn-outline-pink-600, .btn-check:active + .btn-outline-pink-600, .btn-outline-pink-600:active, .btn-outline-pink-600.active, .btn-outline-pink-600.dropdown-toggle.show {
  color: #fff;
  background-color: #ab296a;
  border-color: #ab296a;
}
.btn-check:checked + .btn-outline-pink-600:focus, .btn-check:active + .btn-outline-pink-600:focus, .btn-outline-pink-600:active:focus, .btn-outline-pink-600.active:focus, .btn-outline-pink-600.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(171, 41, 106, 0.5);
}
.btn-outline-pink-600:disabled, .btn-outline-pink-600.disabled {
  color: #ab296a;
  background-color: transparent;
}

.btn-outline-pink-700 {
  color: #801f4f;
  border-color: #801f4f;
}
.btn-outline-pink-700:hover {
  color: #fff;
  background-color: #801f4f;
  border-color: #801f4f;
}
.btn-check:focus + .btn-outline-pink-700, .btn-outline-pink-700:focus {
  box-shadow: 0 0 0 0.25rem rgba(128, 31, 79, 0.5);
}
.btn-check:checked + .btn-outline-pink-700, .btn-check:active + .btn-outline-pink-700, .btn-outline-pink-700:active, .btn-outline-pink-700.active, .btn-outline-pink-700.dropdown-toggle.show {
  color: #fff;
  background-color: #801f4f;
  border-color: #801f4f;
}
.btn-check:checked + .btn-outline-pink-700:focus, .btn-check:active + .btn-outline-pink-700:focus, .btn-outline-pink-700:active:focus, .btn-outline-pink-700.active:focus, .btn-outline-pink-700.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(128, 31, 79, 0.5);
}
.btn-outline-pink-700:disabled, .btn-outline-pink-700.disabled {
  color: #801f4f;
  background-color: transparent;
}

.btn-outline-pink-800 {
  color: #561435;
  border-color: #561435;
}
.btn-outline-pink-800:hover {
  color: #fff;
  background-color: #561435;
  border-color: #561435;
}
.btn-check:focus + .btn-outline-pink-800, .btn-outline-pink-800:focus {
  box-shadow: 0 0 0 0.25rem rgba(86, 20, 53, 0.5);
}
.btn-check:checked + .btn-outline-pink-800, .btn-check:active + .btn-outline-pink-800, .btn-outline-pink-800:active, .btn-outline-pink-800.active, .btn-outline-pink-800.dropdown-toggle.show {
  color: #fff;
  background-color: #561435;
  border-color: #561435;
}
.btn-check:checked + .btn-outline-pink-800:focus, .btn-check:active + .btn-outline-pink-800:focus, .btn-outline-pink-800:active:focus, .btn-outline-pink-800.active:focus, .btn-outline-pink-800.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(86, 20, 53, 0.5);
}
.btn-outline-pink-800:disabled, .btn-outline-pink-800.disabled {
  color: #561435;
  background-color: transparent;
}

.btn-outline-pink-900 {
  color: #2b0a1a;
  border-color: #2b0a1a;
}
.btn-outline-pink-900:hover {
  color: #fff;
  background-color: #2b0a1a;
  border-color: #2b0a1a;
}
.btn-check:focus + .btn-outline-pink-900, .btn-outline-pink-900:focus {
  box-shadow: 0 0 0 0.25rem rgba(43, 10, 26, 0.5);
}
.btn-check:checked + .btn-outline-pink-900, .btn-check:active + .btn-outline-pink-900, .btn-outline-pink-900:active, .btn-outline-pink-900.active, .btn-outline-pink-900.dropdown-toggle.show {
  color: #fff;
  background-color: #2b0a1a;
  border-color: #2b0a1a;
}
.btn-check:checked + .btn-outline-pink-900:focus, .btn-check:active + .btn-outline-pink-900:focus, .btn-outline-pink-900:active:focus, .btn-outline-pink-900.active:focus, .btn-outline-pink-900.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(43, 10, 26, 0.5);
}
.btn-outline-pink-900:disabled, .btn-outline-pink-900.disabled {
  color: #2b0a1a;
  background-color: transparent;
}

.btn-outline-red-100 {
  color: #f8d7da;
  border-color: #f8d7da;
}
.btn-outline-red-100:hover {
  color: #000;
  background-color: #f8d7da;
  border-color: #f8d7da;
}
.btn-check:focus + .btn-outline-red-100, .btn-outline-red-100:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 215, 218, 0.5);
}
.btn-check:checked + .btn-outline-red-100, .btn-check:active + .btn-outline-red-100, .btn-outline-red-100:active, .btn-outline-red-100.active, .btn-outline-red-100.dropdown-toggle.show {
  color: #000;
  background-color: #f8d7da;
  border-color: #f8d7da;
}
.btn-check:checked + .btn-outline-red-100:focus, .btn-check:active + .btn-outline-red-100:focus, .btn-outline-red-100:active:focus, .btn-outline-red-100.active:focus, .btn-outline-red-100.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(248, 215, 218, 0.5);
}
.btn-outline-red-100:disabled, .btn-outline-red-100.disabled {
  color: #f8d7da;
  background-color: transparent;
}

.btn-outline-red-200 {
  color: #f1aeb5;
  border-color: #f1aeb5;
}
.btn-outline-red-200:hover {
  color: #000;
  background-color: #f1aeb5;
  border-color: #f1aeb5;
}
.btn-check:focus + .btn-outline-red-200, .btn-outline-red-200:focus {
  box-shadow: 0 0 0 0.25rem rgba(241, 174, 181, 0.5);
}
.btn-check:checked + .btn-outline-red-200, .btn-check:active + .btn-outline-red-200, .btn-outline-red-200:active, .btn-outline-red-200.active, .btn-outline-red-200.dropdown-toggle.show {
  color: #000;
  background-color: #f1aeb5;
  border-color: #f1aeb5;
}
.btn-check:checked + .btn-outline-red-200:focus, .btn-check:active + .btn-outline-red-200:focus, .btn-outline-red-200:active:focus, .btn-outline-red-200.active:focus, .btn-outline-red-200.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(241, 174, 181, 0.5);
}
.btn-outline-red-200:disabled, .btn-outline-red-200.disabled {
  color: #f1aeb5;
  background-color: transparent;
}

.btn-outline-red-300 {
  color: #ea868f;
  border-color: #ea868f;
}
.btn-outline-red-300:hover {
  color: #000;
  background-color: #ea868f;
  border-color: #ea868f;
}
.btn-check:focus + .btn-outline-red-300, .btn-outline-red-300:focus {
  box-shadow: 0 0 0 0.25rem rgba(234, 134, 143, 0.5);
}
.btn-check:checked + .btn-outline-red-300, .btn-check:active + .btn-outline-red-300, .btn-outline-red-300:active, .btn-outline-red-300.active, .btn-outline-red-300.dropdown-toggle.show {
  color: #000;
  background-color: #ea868f;
  border-color: #ea868f;
}
.btn-check:checked + .btn-outline-red-300:focus, .btn-check:active + .btn-outline-red-300:focus, .btn-outline-red-300:active:focus, .btn-outline-red-300.active:focus, .btn-outline-red-300.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(234, 134, 143, 0.5);
}
.btn-outline-red-300:disabled, .btn-outline-red-300.disabled {
  color: #ea868f;
  background-color: transparent;
}

.btn-outline-red-400 {
  color: #e35d6a;
  border-color: #e35d6a;
}
.btn-outline-red-400:hover {
  color: #000;
  background-color: #e35d6a;
  border-color: #e35d6a;
}
.btn-check:focus + .btn-outline-red-400, .btn-outline-red-400:focus {
  box-shadow: 0 0 0 0.25rem rgba(227, 93, 106, 0.5);
}
.btn-check:checked + .btn-outline-red-400, .btn-check:active + .btn-outline-red-400, .btn-outline-red-400:active, .btn-outline-red-400.active, .btn-outline-red-400.dropdown-toggle.show {
  color: #000;
  background-color: #e35d6a;
  border-color: #e35d6a;
}
.btn-check:checked + .btn-outline-red-400:focus, .btn-check:active + .btn-outline-red-400:focus, .btn-outline-red-400:active:focus, .btn-outline-red-400.active:focus, .btn-outline-red-400.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(227, 93, 106, 0.5);
}
.btn-outline-red-400:disabled, .btn-outline-red-400.disabled {
  color: #e35d6a;
  background-color: transparent;
}

.btn-outline-red-500 {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-red-500:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:focus + .btn-outline-red-500, .btn-outline-red-500:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-check:checked + .btn-outline-red-500, .btn-check:active + .btn-outline-red-500, .btn-outline-red-500:active, .btn-outline-red-500.active, .btn-outline-red-500.dropdown-toggle.show {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:checked + .btn-outline-red-500:focus, .btn-check:active + .btn-outline-red-500:focus, .btn-outline-red-500:active:focus, .btn-outline-red-500.active:focus, .btn-outline-red-500.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-outline-red-500:disabled, .btn-outline-red-500.disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-red-600 {
  color: #b02a37;
  border-color: #b02a37;
}
.btn-outline-red-600:hover {
  color: #fff;
  background-color: #b02a37;
  border-color: #b02a37;
}
.btn-check:focus + .btn-outline-red-600, .btn-outline-red-600:focus {
  box-shadow: 0 0 0 0.25rem rgba(176, 42, 55, 0.5);
}
.btn-check:checked + .btn-outline-red-600, .btn-check:active + .btn-outline-red-600, .btn-outline-red-600:active, .btn-outline-red-600.active, .btn-outline-red-600.dropdown-toggle.show {
  color: #fff;
  background-color: #b02a37;
  border-color: #b02a37;
}
.btn-check:checked + .btn-outline-red-600:focus, .btn-check:active + .btn-outline-red-600:focus, .btn-outline-red-600:active:focus, .btn-outline-red-600.active:focus, .btn-outline-red-600.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(176, 42, 55, 0.5);
}
.btn-outline-red-600:disabled, .btn-outline-red-600.disabled {
  color: #b02a37;
  background-color: transparent;
}

.btn-outline-red-700 {
  color: #842029;
  border-color: #842029;
}
.btn-outline-red-700:hover {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}
.btn-check:focus + .btn-outline-red-700, .btn-outline-red-700:focus {
  box-shadow: 0 0 0 0.25rem rgba(132, 32, 41, 0.5);
}
.btn-check:checked + .btn-outline-red-700, .btn-check:active + .btn-outline-red-700, .btn-outline-red-700:active, .btn-outline-red-700.active, .btn-outline-red-700.dropdown-toggle.show {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}
.btn-check:checked + .btn-outline-red-700:focus, .btn-check:active + .btn-outline-red-700:focus, .btn-outline-red-700:active:focus, .btn-outline-red-700.active:focus, .btn-outline-red-700.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(132, 32, 41, 0.5);
}
.btn-outline-red-700:disabled, .btn-outline-red-700.disabled {
  color: #842029;
  background-color: transparent;
}

.btn-outline-red-800 {
  color: #58151c;
  border-color: #58151c;
}
.btn-outline-red-800:hover {
  color: #fff;
  background-color: #58151c;
  border-color: #58151c;
}
.btn-check:focus + .btn-outline-red-800, .btn-outline-red-800:focus {
  box-shadow: 0 0 0 0.25rem rgba(88, 21, 28, 0.5);
}
.btn-check:checked + .btn-outline-red-800, .btn-check:active + .btn-outline-red-800, .btn-outline-red-800:active, .btn-outline-red-800.active, .btn-outline-red-800.dropdown-toggle.show {
  color: #fff;
  background-color: #58151c;
  border-color: #58151c;
}
.btn-check:checked + .btn-outline-red-800:focus, .btn-check:active + .btn-outline-red-800:focus, .btn-outline-red-800:active:focus, .btn-outline-red-800.active:focus, .btn-outline-red-800.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(88, 21, 28, 0.5);
}
.btn-outline-red-800:disabled, .btn-outline-red-800.disabled {
  color: #58151c;
  background-color: transparent;
}

.btn-outline-red-900 {
  color: #2c0b0e;
  border-color: #2c0b0e;
}
.btn-outline-red-900:hover {
  color: #fff;
  background-color: #2c0b0e;
  border-color: #2c0b0e;
}
.btn-check:focus + .btn-outline-red-900, .btn-outline-red-900:focus {
  box-shadow: 0 0 0 0.25rem rgba(44, 11, 14, 0.5);
}
.btn-check:checked + .btn-outline-red-900, .btn-check:active + .btn-outline-red-900, .btn-outline-red-900:active, .btn-outline-red-900.active, .btn-outline-red-900.dropdown-toggle.show {
  color: #fff;
  background-color: #2c0b0e;
  border-color: #2c0b0e;
}
.btn-check:checked + .btn-outline-red-900:focus, .btn-check:active + .btn-outline-red-900:focus, .btn-outline-red-900:active:focus, .btn-outline-red-900.active:focus, .btn-outline-red-900.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(44, 11, 14, 0.5);
}
.btn-outline-red-900:disabled, .btn-outline-red-900.disabled {
  color: #2c0b0e;
  background-color: transparent;
}

.btn-outline-orange-100 {
  color: #ffe5d0;
  border-color: #ffe5d0;
}
.btn-outline-orange-100:hover {
  color: #000;
  background-color: #ffe5d0;
  border-color: #ffe5d0;
}
.btn-check:focus + .btn-outline-orange-100, .btn-outline-orange-100:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 229, 208, 0.5);
}
.btn-check:checked + .btn-outline-orange-100, .btn-check:active + .btn-outline-orange-100, .btn-outline-orange-100:active, .btn-outline-orange-100.active, .btn-outline-orange-100.dropdown-toggle.show {
  color: #000;
  background-color: #ffe5d0;
  border-color: #ffe5d0;
}
.btn-check:checked + .btn-outline-orange-100:focus, .btn-check:active + .btn-outline-orange-100:focus, .btn-outline-orange-100:active:focus, .btn-outline-orange-100.active:focus, .btn-outline-orange-100.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(255, 229, 208, 0.5);
}
.btn-outline-orange-100:disabled, .btn-outline-orange-100.disabled {
  color: #ffe5d0;
  background-color: transparent;
}

.btn-outline-orange-200 {
  color: #fecba1;
  border-color: #fecba1;
}
.btn-outline-orange-200:hover {
  color: #000;
  background-color: #fecba1;
  border-color: #fecba1;
}
.btn-check:focus + .btn-outline-orange-200, .btn-outline-orange-200:focus {
  box-shadow: 0 0 0 0.25rem rgba(254, 203, 161, 0.5);
}
.btn-check:checked + .btn-outline-orange-200, .btn-check:active + .btn-outline-orange-200, .btn-outline-orange-200:active, .btn-outline-orange-200.active, .btn-outline-orange-200.dropdown-toggle.show {
  color: #000;
  background-color: #fecba1;
  border-color: #fecba1;
}
.btn-check:checked + .btn-outline-orange-200:focus, .btn-check:active + .btn-outline-orange-200:focus, .btn-outline-orange-200:active:focus, .btn-outline-orange-200.active:focus, .btn-outline-orange-200.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(254, 203, 161, 0.5);
}
.btn-outline-orange-200:disabled, .btn-outline-orange-200.disabled {
  color: #fecba1;
  background-color: transparent;
}

.btn-outline-orange-300 {
  color: #feb272;
  border-color: #feb272;
}
.btn-outline-orange-300:hover {
  color: #000;
  background-color: #feb272;
  border-color: #feb272;
}
.btn-check:focus + .btn-outline-orange-300, .btn-outline-orange-300:focus {
  box-shadow: 0 0 0 0.25rem rgba(254, 178, 114, 0.5);
}
.btn-check:checked + .btn-outline-orange-300, .btn-check:active + .btn-outline-orange-300, .btn-outline-orange-300:active, .btn-outline-orange-300.active, .btn-outline-orange-300.dropdown-toggle.show {
  color: #000;
  background-color: #feb272;
  border-color: #feb272;
}
.btn-check:checked + .btn-outline-orange-300:focus, .btn-check:active + .btn-outline-orange-300:focus, .btn-outline-orange-300:active:focus, .btn-outline-orange-300.active:focus, .btn-outline-orange-300.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(254, 178, 114, 0.5);
}
.btn-outline-orange-300:disabled, .btn-outline-orange-300.disabled {
  color: #feb272;
  background-color: transparent;
}

.btn-outline-orange-400 {
  color: #fd9843;
  border-color: #fd9843;
}
.btn-outline-orange-400:hover {
  color: #000;
  background-color: #fd9843;
  border-color: #fd9843;
}
.btn-check:focus + .btn-outline-orange-400, .btn-outline-orange-400:focus {
  box-shadow: 0 0 0 0.25rem rgba(253, 152, 67, 0.5);
}
.btn-check:checked + .btn-outline-orange-400, .btn-check:active + .btn-outline-orange-400, .btn-outline-orange-400:active, .btn-outline-orange-400.active, .btn-outline-orange-400.dropdown-toggle.show {
  color: #000;
  background-color: #fd9843;
  border-color: #fd9843;
}
.btn-check:checked + .btn-outline-orange-400:focus, .btn-check:active + .btn-outline-orange-400:focus, .btn-outline-orange-400:active:focus, .btn-outline-orange-400.active:focus, .btn-outline-orange-400.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(253, 152, 67, 0.5);
}
.btn-outline-orange-400:disabled, .btn-outline-orange-400.disabled {
  color: #fd9843;
  background-color: transparent;
}

.btn-outline-orange-500 {
  color: #fd7e14;
  border-color: #fd7e14;
}
.btn-outline-orange-500:hover {
  color: #000;
  background-color: #fd7e14;
  border-color: #fd7e14;
}
.btn-check:focus + .btn-outline-orange-500, .btn-outline-orange-500:focus {
  box-shadow: 0 0 0 0.25rem rgba(253, 126, 20, 0.5);
}
.btn-check:checked + .btn-outline-orange-500, .btn-check:active + .btn-outline-orange-500, .btn-outline-orange-500:active, .btn-outline-orange-500.active, .btn-outline-orange-500.dropdown-toggle.show {
  color: #000;
  background-color: #fd7e14;
  border-color: #fd7e14;
}
.btn-check:checked + .btn-outline-orange-500:focus, .btn-check:active + .btn-outline-orange-500:focus, .btn-outline-orange-500:active:focus, .btn-outline-orange-500.active:focus, .btn-outline-orange-500.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(253, 126, 20, 0.5);
}
.btn-outline-orange-500:disabled, .btn-outline-orange-500.disabled {
  color: #fd7e14;
  background-color: transparent;
}

.btn-outline-orange-600 {
  color: #ca6510;
  border-color: #ca6510;
}
.btn-outline-orange-600:hover {
  color: #000;
  background-color: #ca6510;
  border-color: #ca6510;
}
.btn-check:focus + .btn-outline-orange-600, .btn-outline-orange-600:focus {
  box-shadow: 0 0 0 0.25rem rgba(202, 101, 16, 0.5);
}
.btn-check:checked + .btn-outline-orange-600, .btn-check:active + .btn-outline-orange-600, .btn-outline-orange-600:active, .btn-outline-orange-600.active, .btn-outline-orange-600.dropdown-toggle.show {
  color: #000;
  background-color: #ca6510;
  border-color: #ca6510;
}
.btn-check:checked + .btn-outline-orange-600:focus, .btn-check:active + .btn-outline-orange-600:focus, .btn-outline-orange-600:active:focus, .btn-outline-orange-600.active:focus, .btn-outline-orange-600.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(202, 101, 16, 0.5);
}
.btn-outline-orange-600:disabled, .btn-outline-orange-600.disabled {
  color: #ca6510;
  background-color: transparent;
}

.btn-outline-orange-700 {
  color: #984c0c;
  border-color: #984c0c;
}
.btn-outline-orange-700:hover {
  color: #fff;
  background-color: #984c0c;
  border-color: #984c0c;
}
.btn-check:focus + .btn-outline-orange-700, .btn-outline-orange-700:focus {
  box-shadow: 0 0 0 0.25rem rgba(152, 76, 12, 0.5);
}
.btn-check:checked + .btn-outline-orange-700, .btn-check:active + .btn-outline-orange-700, .btn-outline-orange-700:active, .btn-outline-orange-700.active, .btn-outline-orange-700.dropdown-toggle.show {
  color: #fff;
  background-color: #984c0c;
  border-color: #984c0c;
}
.btn-check:checked + .btn-outline-orange-700:focus, .btn-check:active + .btn-outline-orange-700:focus, .btn-outline-orange-700:active:focus, .btn-outline-orange-700.active:focus, .btn-outline-orange-700.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(152, 76, 12, 0.5);
}
.btn-outline-orange-700:disabled, .btn-outline-orange-700.disabled {
  color: #984c0c;
  background-color: transparent;
}

.btn-outline-orange-800 {
  color: #653208;
  border-color: #653208;
}
.btn-outline-orange-800:hover {
  color: #fff;
  background-color: #653208;
  border-color: #653208;
}
.btn-check:focus + .btn-outline-orange-800, .btn-outline-orange-800:focus {
  box-shadow: 0 0 0 0.25rem rgba(101, 50, 8, 0.5);
}
.btn-check:checked + .btn-outline-orange-800, .btn-check:active + .btn-outline-orange-800, .btn-outline-orange-800:active, .btn-outline-orange-800.active, .btn-outline-orange-800.dropdown-toggle.show {
  color: #fff;
  background-color: #653208;
  border-color: #653208;
}
.btn-check:checked + .btn-outline-orange-800:focus, .btn-check:active + .btn-outline-orange-800:focus, .btn-outline-orange-800:active:focus, .btn-outline-orange-800.active:focus, .btn-outline-orange-800.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(101, 50, 8, 0.5);
}
.btn-outline-orange-800:disabled, .btn-outline-orange-800.disabled {
  color: #653208;
  background-color: transparent;
}

.btn-outline-orange-900 {
  color: #331904;
  border-color: #331904;
}
.btn-outline-orange-900:hover {
  color: #fff;
  background-color: #331904;
  border-color: #331904;
}
.btn-check:focus + .btn-outline-orange-900, .btn-outline-orange-900:focus {
  box-shadow: 0 0 0 0.25rem rgba(51, 25, 4, 0.5);
}
.btn-check:checked + .btn-outline-orange-900, .btn-check:active + .btn-outline-orange-900, .btn-outline-orange-900:active, .btn-outline-orange-900.active, .btn-outline-orange-900.dropdown-toggle.show {
  color: #fff;
  background-color: #331904;
  border-color: #331904;
}
.btn-check:checked + .btn-outline-orange-900:focus, .btn-check:active + .btn-outline-orange-900:focus, .btn-outline-orange-900:active:focus, .btn-outline-orange-900.active:focus, .btn-outline-orange-900.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(51, 25, 4, 0.5);
}
.btn-outline-orange-900:disabled, .btn-outline-orange-900.disabled {
  color: #331904;
  background-color: transparent;
}

.btn-outline-yellow-100 {
  color: #fff3cd;
  border-color: #fff3cd;
}
.btn-outline-yellow-100:hover {
  color: #000;
  background-color: #fff3cd;
  border-color: #fff3cd;
}
.btn-check:focus + .btn-outline-yellow-100, .btn-outline-yellow-100:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 243, 205, 0.5);
}
.btn-check:checked + .btn-outline-yellow-100, .btn-check:active + .btn-outline-yellow-100, .btn-outline-yellow-100:active, .btn-outline-yellow-100.active, .btn-outline-yellow-100.dropdown-toggle.show {
  color: #000;
  background-color: #fff3cd;
  border-color: #fff3cd;
}
.btn-check:checked + .btn-outline-yellow-100:focus, .btn-check:active + .btn-outline-yellow-100:focus, .btn-outline-yellow-100:active:focus, .btn-outline-yellow-100.active:focus, .btn-outline-yellow-100.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(255, 243, 205, 0.5);
}
.btn-outline-yellow-100:disabled, .btn-outline-yellow-100.disabled {
  color: #fff3cd;
  background-color: transparent;
}

.btn-outline-yellow-200 {
  color: #ffe69c;
  border-color: #ffe69c;
}
.btn-outline-yellow-200:hover {
  color: #000;
  background-color: #ffe69c;
  border-color: #ffe69c;
}
.btn-check:focus + .btn-outline-yellow-200, .btn-outline-yellow-200:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 230, 156, 0.5);
}
.btn-check:checked + .btn-outline-yellow-200, .btn-check:active + .btn-outline-yellow-200, .btn-outline-yellow-200:active, .btn-outline-yellow-200.active, .btn-outline-yellow-200.dropdown-toggle.show {
  color: #000;
  background-color: #ffe69c;
  border-color: #ffe69c;
}
.btn-check:checked + .btn-outline-yellow-200:focus, .btn-check:active + .btn-outline-yellow-200:focus, .btn-outline-yellow-200:active:focus, .btn-outline-yellow-200.active:focus, .btn-outline-yellow-200.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(255, 230, 156, 0.5);
}
.btn-outline-yellow-200:disabled, .btn-outline-yellow-200.disabled {
  color: #ffe69c;
  background-color: transparent;
}

.btn-outline-yellow-300 {
  color: #ffda6a;
  border-color: #ffda6a;
}
.btn-outline-yellow-300:hover {
  color: #000;
  background-color: #ffda6a;
  border-color: #ffda6a;
}
.btn-check:focus + .btn-outline-yellow-300, .btn-outline-yellow-300:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 218, 106, 0.5);
}
.btn-check:checked + .btn-outline-yellow-300, .btn-check:active + .btn-outline-yellow-300, .btn-outline-yellow-300:active, .btn-outline-yellow-300.active, .btn-outline-yellow-300.dropdown-toggle.show {
  color: #000;
  background-color: #ffda6a;
  border-color: #ffda6a;
}
.btn-check:checked + .btn-outline-yellow-300:focus, .btn-check:active + .btn-outline-yellow-300:focus, .btn-outline-yellow-300:active:focus, .btn-outline-yellow-300.active:focus, .btn-outline-yellow-300.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(255, 218, 106, 0.5);
}
.btn-outline-yellow-300:disabled, .btn-outline-yellow-300.disabled {
  color: #ffda6a;
  background-color: transparent;
}

.btn-outline-yellow-400 {
  color: #ffcd39;
  border-color: #ffcd39;
}
.btn-outline-yellow-400:hover {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffcd39;
}
.btn-check:focus + .btn-outline-yellow-400, .btn-outline-yellow-400:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 205, 57, 0.5);
}
.btn-check:checked + .btn-outline-yellow-400, .btn-check:active + .btn-outline-yellow-400, .btn-outline-yellow-400:active, .btn-outline-yellow-400.active, .btn-outline-yellow-400.dropdown-toggle.show {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffcd39;
}
.btn-check:checked + .btn-outline-yellow-400:focus, .btn-check:active + .btn-outline-yellow-400:focus, .btn-outline-yellow-400:active:focus, .btn-outline-yellow-400.active:focus, .btn-outline-yellow-400.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(255, 205, 57, 0.5);
}
.btn-outline-yellow-400:disabled, .btn-outline-yellow-400.disabled {
  color: #ffcd39;
  background-color: transparent;
}

.btn-outline-yellow-500 {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-yellow-500:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:focus + .btn-outline-yellow-500, .btn-outline-yellow-500:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-check:checked + .btn-outline-yellow-500, .btn-check:active + .btn-outline-yellow-500, .btn-outline-yellow-500:active, .btn-outline-yellow-500.active, .btn-outline-yellow-500.dropdown-toggle.show {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:checked + .btn-outline-yellow-500:focus, .btn-check:active + .btn-outline-yellow-500:focus, .btn-outline-yellow-500:active:focus, .btn-outline-yellow-500.active:focus, .btn-outline-yellow-500.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-outline-yellow-500:disabled, .btn-outline-yellow-500.disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-yellow-600 {
  color: #cc9a06;
  border-color: #cc9a06;
}
.btn-outline-yellow-600:hover {
  color: #000;
  background-color: #cc9a06;
  border-color: #cc9a06;
}
.btn-check:focus + .btn-outline-yellow-600, .btn-outline-yellow-600:focus {
  box-shadow: 0 0 0 0.25rem rgba(204, 154, 6, 0.5);
}
.btn-check:checked + .btn-outline-yellow-600, .btn-check:active + .btn-outline-yellow-600, .btn-outline-yellow-600:active, .btn-outline-yellow-600.active, .btn-outline-yellow-600.dropdown-toggle.show {
  color: #000;
  background-color: #cc9a06;
  border-color: #cc9a06;
}
.btn-check:checked + .btn-outline-yellow-600:focus, .btn-check:active + .btn-outline-yellow-600:focus, .btn-outline-yellow-600:active:focus, .btn-outline-yellow-600.active:focus, .btn-outline-yellow-600.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(204, 154, 6, 0.5);
}
.btn-outline-yellow-600:disabled, .btn-outline-yellow-600.disabled {
  color: #cc9a06;
  background-color: transparent;
}

.btn-outline-yellow-700 {
  color: #997404;
  border-color: #997404;
}
.btn-outline-yellow-700:hover {
  color: #000;
  background-color: #997404;
  border-color: #997404;
}
.btn-check:focus + .btn-outline-yellow-700, .btn-outline-yellow-700:focus {
  box-shadow: 0 0 0 0.25rem rgba(153, 116, 4, 0.5);
}
.btn-check:checked + .btn-outline-yellow-700, .btn-check:active + .btn-outline-yellow-700, .btn-outline-yellow-700:active, .btn-outline-yellow-700.active, .btn-outline-yellow-700.dropdown-toggle.show {
  color: #000;
  background-color: #997404;
  border-color: #997404;
}
.btn-check:checked + .btn-outline-yellow-700:focus, .btn-check:active + .btn-outline-yellow-700:focus, .btn-outline-yellow-700:active:focus, .btn-outline-yellow-700.active:focus, .btn-outline-yellow-700.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(153, 116, 4, 0.5);
}
.btn-outline-yellow-700:disabled, .btn-outline-yellow-700.disabled {
  color: #997404;
  background-color: transparent;
}

.btn-outline-yellow-800 {
  color: #664d03;
  border-color: #664d03;
}
.btn-outline-yellow-800:hover {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}
.btn-check:focus + .btn-outline-yellow-800, .btn-outline-yellow-800:focus {
  box-shadow: 0 0 0 0.25rem rgba(102, 77, 3, 0.5);
}
.btn-check:checked + .btn-outline-yellow-800, .btn-check:active + .btn-outline-yellow-800, .btn-outline-yellow-800:active, .btn-outline-yellow-800.active, .btn-outline-yellow-800.dropdown-toggle.show {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}
.btn-check:checked + .btn-outline-yellow-800:focus, .btn-check:active + .btn-outline-yellow-800:focus, .btn-outline-yellow-800:active:focus, .btn-outline-yellow-800.active:focus, .btn-outline-yellow-800.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(102, 77, 3, 0.5);
}
.btn-outline-yellow-800:disabled, .btn-outline-yellow-800.disabled {
  color: #664d03;
  background-color: transparent;
}

.btn-outline-yellow-900 {
  color: #332701;
  border-color: #332701;
}
.btn-outline-yellow-900:hover {
  color: #fff;
  background-color: #332701;
  border-color: #332701;
}
.btn-check:focus + .btn-outline-yellow-900, .btn-outline-yellow-900:focus {
  box-shadow: 0 0 0 0.25rem rgba(51, 39, 1, 0.5);
}
.btn-check:checked + .btn-outline-yellow-900, .btn-check:active + .btn-outline-yellow-900, .btn-outline-yellow-900:active, .btn-outline-yellow-900.active, .btn-outline-yellow-900.dropdown-toggle.show {
  color: #fff;
  background-color: #332701;
  border-color: #332701;
}
.btn-check:checked + .btn-outline-yellow-900:focus, .btn-check:active + .btn-outline-yellow-900:focus, .btn-outline-yellow-900:active:focus, .btn-outline-yellow-900.active:focus, .btn-outline-yellow-900.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(51, 39, 1, 0.5);
}
.btn-outline-yellow-900:disabled, .btn-outline-yellow-900.disabled {
  color: #332701;
  background-color: transparent;
}

.btn-outline-green-100 {
  color: #d1e7dd;
  border-color: #d1e7dd;
}
.btn-outline-green-100:hover {
  color: #000;
  background-color: #d1e7dd;
  border-color: #d1e7dd;
}
.btn-check:focus + .btn-outline-green-100, .btn-outline-green-100:focus {
  box-shadow: 0 0 0 0.25rem rgba(209, 231, 221, 0.5);
}
.btn-check:checked + .btn-outline-green-100, .btn-check:active + .btn-outline-green-100, .btn-outline-green-100:active, .btn-outline-green-100.active, .btn-outline-green-100.dropdown-toggle.show {
  color: #000;
  background-color: #d1e7dd;
  border-color: #d1e7dd;
}
.btn-check:checked + .btn-outline-green-100:focus, .btn-check:active + .btn-outline-green-100:focus, .btn-outline-green-100:active:focus, .btn-outline-green-100.active:focus, .btn-outline-green-100.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(209, 231, 221, 0.5);
}
.btn-outline-green-100:disabled, .btn-outline-green-100.disabled {
  color: #d1e7dd;
  background-color: transparent;
}

.btn-outline-green-200 {
  color: #a3cfbb;
  border-color: #a3cfbb;
}
.btn-outline-green-200:hover {
  color: #000;
  background-color: #a3cfbb;
  border-color: #a3cfbb;
}
.btn-check:focus + .btn-outline-green-200, .btn-outline-green-200:focus {
  box-shadow: 0 0 0 0.25rem rgba(163, 207, 187, 0.5);
}
.btn-check:checked + .btn-outline-green-200, .btn-check:active + .btn-outline-green-200, .btn-outline-green-200:active, .btn-outline-green-200.active, .btn-outline-green-200.dropdown-toggle.show {
  color: #000;
  background-color: #a3cfbb;
  border-color: #a3cfbb;
}
.btn-check:checked + .btn-outline-green-200:focus, .btn-check:active + .btn-outline-green-200:focus, .btn-outline-green-200:active:focus, .btn-outline-green-200.active:focus, .btn-outline-green-200.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(163, 207, 187, 0.5);
}
.btn-outline-green-200:disabled, .btn-outline-green-200.disabled {
  color: #a3cfbb;
  background-color: transparent;
}

.btn-outline-green-300 {
  color: #75b798;
  border-color: #75b798;
}
.btn-outline-green-300:hover {
  color: #000;
  background-color: #75b798;
  border-color: #75b798;
}
.btn-check:focus + .btn-outline-green-300, .btn-outline-green-300:focus {
  box-shadow: 0 0 0 0.25rem rgba(117, 183, 152, 0.5);
}
.btn-check:checked + .btn-outline-green-300, .btn-check:active + .btn-outline-green-300, .btn-outline-green-300:active, .btn-outline-green-300.active, .btn-outline-green-300.dropdown-toggle.show {
  color: #000;
  background-color: #75b798;
  border-color: #75b798;
}
.btn-check:checked + .btn-outline-green-300:focus, .btn-check:active + .btn-outline-green-300:focus, .btn-outline-green-300:active:focus, .btn-outline-green-300.active:focus, .btn-outline-green-300.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(117, 183, 152, 0.5);
}
.btn-outline-green-300:disabled, .btn-outline-green-300.disabled {
  color: #75b798;
  background-color: transparent;
}

.btn-outline-green-400 {
  color: #479f76;
  border-color: #479f76;
}
.btn-outline-green-400:hover {
  color: #000;
  background-color: #479f76;
  border-color: #479f76;
}
.btn-check:focus + .btn-outline-green-400, .btn-outline-green-400:focus {
  box-shadow: 0 0 0 0.25rem rgba(71, 159, 118, 0.5);
}
.btn-check:checked + .btn-outline-green-400, .btn-check:active + .btn-outline-green-400, .btn-outline-green-400:active, .btn-outline-green-400.active, .btn-outline-green-400.dropdown-toggle.show {
  color: #000;
  background-color: #479f76;
  border-color: #479f76;
}
.btn-check:checked + .btn-outline-green-400:focus, .btn-check:active + .btn-outline-green-400:focus, .btn-outline-green-400:active:focus, .btn-outline-green-400.active:focus, .btn-outline-green-400.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(71, 159, 118, 0.5);
}
.btn-outline-green-400:disabled, .btn-outline-green-400.disabled {
  color: #479f76;
  background-color: transparent;
}

.btn-outline-green-500 {
  color: #198754;
  border-color: #198754;
}
.btn-outline-green-500:hover {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-check:focus + .btn-outline-green-500, .btn-outline-green-500:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}
.btn-check:checked + .btn-outline-green-500, .btn-check:active + .btn-outline-green-500, .btn-outline-green-500:active, .btn-outline-green-500.active, .btn-outline-green-500.dropdown-toggle.show {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-check:checked + .btn-outline-green-500:focus, .btn-check:active + .btn-outline-green-500:focus, .btn-outline-green-500:active:focus, .btn-outline-green-500.active:focus, .btn-outline-green-500.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}
.btn-outline-green-500:disabled, .btn-outline-green-500.disabled {
  color: #198754;
  background-color: transparent;
}

.btn-outline-green-600 {
  color: #146c43;
  border-color: #146c43;
}
.btn-outline-green-600:hover {
  color: #fff;
  background-color: #146c43;
  border-color: #146c43;
}
.btn-check:focus + .btn-outline-green-600, .btn-outline-green-600:focus {
  box-shadow: 0 0 0 0.25rem rgba(20, 108, 67, 0.5);
}
.btn-check:checked + .btn-outline-green-600, .btn-check:active + .btn-outline-green-600, .btn-outline-green-600:active, .btn-outline-green-600.active, .btn-outline-green-600.dropdown-toggle.show {
  color: #fff;
  background-color: #146c43;
  border-color: #146c43;
}
.btn-check:checked + .btn-outline-green-600:focus, .btn-check:active + .btn-outline-green-600:focus, .btn-outline-green-600:active:focus, .btn-outline-green-600.active:focus, .btn-outline-green-600.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(20, 108, 67, 0.5);
}
.btn-outline-green-600:disabled, .btn-outline-green-600.disabled {
  color: #146c43;
  background-color: transparent;
}

.btn-outline-green-700 {
  color: #0f5132;
  border-color: #0f5132;
}
.btn-outline-green-700:hover {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}
.btn-check:focus + .btn-outline-green-700, .btn-outline-green-700:focus {
  box-shadow: 0 0 0 0.25rem rgba(15, 81, 50, 0.5);
}
.btn-check:checked + .btn-outline-green-700, .btn-check:active + .btn-outline-green-700, .btn-outline-green-700:active, .btn-outline-green-700.active, .btn-outline-green-700.dropdown-toggle.show {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}
.btn-check:checked + .btn-outline-green-700:focus, .btn-check:active + .btn-outline-green-700:focus, .btn-outline-green-700:active:focus, .btn-outline-green-700.active:focus, .btn-outline-green-700.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(15, 81, 50, 0.5);
}
.btn-outline-green-700:disabled, .btn-outline-green-700.disabled {
  color: #0f5132;
  background-color: transparent;
}

.btn-outline-green-800 {
  color: #0a3622;
  border-color: #0a3622;
}
.btn-outline-green-800:hover {
  color: #fff;
  background-color: #0a3622;
  border-color: #0a3622;
}
.btn-check:focus + .btn-outline-green-800, .btn-outline-green-800:focus {
  box-shadow: 0 0 0 0.25rem rgba(10, 54, 34, 0.5);
}
.btn-check:checked + .btn-outline-green-800, .btn-check:active + .btn-outline-green-800, .btn-outline-green-800:active, .btn-outline-green-800.active, .btn-outline-green-800.dropdown-toggle.show {
  color: #fff;
  background-color: #0a3622;
  border-color: #0a3622;
}
.btn-check:checked + .btn-outline-green-800:focus, .btn-check:active + .btn-outline-green-800:focus, .btn-outline-green-800:active:focus, .btn-outline-green-800.active:focus, .btn-outline-green-800.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(10, 54, 34, 0.5);
}
.btn-outline-green-800:disabled, .btn-outline-green-800.disabled {
  color: #0a3622;
  background-color: transparent;
}

.btn-outline-green-900 {
  color: #051b11;
  border-color: #051b11;
}
.btn-outline-green-900:hover {
  color: #fff;
  background-color: #051b11;
  border-color: #051b11;
}
.btn-check:focus + .btn-outline-green-900, .btn-outline-green-900:focus {
  box-shadow: 0 0 0 0.25rem rgba(5, 27, 17, 0.5);
}
.btn-check:checked + .btn-outline-green-900, .btn-check:active + .btn-outline-green-900, .btn-outline-green-900:active, .btn-outline-green-900.active, .btn-outline-green-900.dropdown-toggle.show {
  color: #fff;
  background-color: #051b11;
  border-color: #051b11;
}
.btn-check:checked + .btn-outline-green-900:focus, .btn-check:active + .btn-outline-green-900:focus, .btn-outline-green-900:active:focus, .btn-outline-green-900.active:focus, .btn-outline-green-900.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(5, 27, 17, 0.5);
}
.btn-outline-green-900:disabled, .btn-outline-green-900.disabled {
  color: #051b11;
  background-color: transparent;
}

.btn-outline-teal-100 {
  color: #d2f4ea;
  border-color: #d2f4ea;
}
.btn-outline-teal-100:hover {
  color: #000;
  background-color: #d2f4ea;
  border-color: #d2f4ea;
}
.btn-check:focus + .btn-outline-teal-100, .btn-outline-teal-100:focus {
  box-shadow: 0 0 0 0.25rem rgba(210, 244, 234, 0.5);
}
.btn-check:checked + .btn-outline-teal-100, .btn-check:active + .btn-outline-teal-100, .btn-outline-teal-100:active, .btn-outline-teal-100.active, .btn-outline-teal-100.dropdown-toggle.show {
  color: #000;
  background-color: #d2f4ea;
  border-color: #d2f4ea;
}
.btn-check:checked + .btn-outline-teal-100:focus, .btn-check:active + .btn-outline-teal-100:focus, .btn-outline-teal-100:active:focus, .btn-outline-teal-100.active:focus, .btn-outline-teal-100.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(210, 244, 234, 0.5);
}
.btn-outline-teal-100:disabled, .btn-outline-teal-100.disabled {
  color: #d2f4ea;
  background-color: transparent;
}

.btn-outline-teal-200 {
  color: #a6e9d5;
  border-color: #a6e9d5;
}
.btn-outline-teal-200:hover {
  color: #000;
  background-color: #a6e9d5;
  border-color: #a6e9d5;
}
.btn-check:focus + .btn-outline-teal-200, .btn-outline-teal-200:focus {
  box-shadow: 0 0 0 0.25rem rgba(166, 233, 213, 0.5);
}
.btn-check:checked + .btn-outline-teal-200, .btn-check:active + .btn-outline-teal-200, .btn-outline-teal-200:active, .btn-outline-teal-200.active, .btn-outline-teal-200.dropdown-toggle.show {
  color: #000;
  background-color: #a6e9d5;
  border-color: #a6e9d5;
}
.btn-check:checked + .btn-outline-teal-200:focus, .btn-check:active + .btn-outline-teal-200:focus, .btn-outline-teal-200:active:focus, .btn-outline-teal-200.active:focus, .btn-outline-teal-200.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(166, 233, 213, 0.5);
}
.btn-outline-teal-200:disabled, .btn-outline-teal-200.disabled {
  color: #a6e9d5;
  background-color: transparent;
}

.btn-outline-teal-300 {
  color: #79dfc1;
  border-color: #79dfc1;
}
.btn-outline-teal-300:hover {
  color: #000;
  background-color: #79dfc1;
  border-color: #79dfc1;
}
.btn-check:focus + .btn-outline-teal-300, .btn-outline-teal-300:focus {
  box-shadow: 0 0 0 0.25rem rgba(121, 223, 193, 0.5);
}
.btn-check:checked + .btn-outline-teal-300, .btn-check:active + .btn-outline-teal-300, .btn-outline-teal-300:active, .btn-outline-teal-300.active, .btn-outline-teal-300.dropdown-toggle.show {
  color: #000;
  background-color: #79dfc1;
  border-color: #79dfc1;
}
.btn-check:checked + .btn-outline-teal-300:focus, .btn-check:active + .btn-outline-teal-300:focus, .btn-outline-teal-300:active:focus, .btn-outline-teal-300.active:focus, .btn-outline-teal-300.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(121, 223, 193, 0.5);
}
.btn-outline-teal-300:disabled, .btn-outline-teal-300.disabled {
  color: #79dfc1;
  background-color: transparent;
}

.btn-outline-teal-400 {
  color: #4dd4ac;
  border-color: #4dd4ac;
}
.btn-outline-teal-400:hover {
  color: #000;
  background-color: #4dd4ac;
  border-color: #4dd4ac;
}
.btn-check:focus + .btn-outline-teal-400, .btn-outline-teal-400:focus {
  box-shadow: 0 0 0 0.25rem rgba(77, 212, 172, 0.5);
}
.btn-check:checked + .btn-outline-teal-400, .btn-check:active + .btn-outline-teal-400, .btn-outline-teal-400:active, .btn-outline-teal-400.active, .btn-outline-teal-400.dropdown-toggle.show {
  color: #000;
  background-color: #4dd4ac;
  border-color: #4dd4ac;
}
.btn-check:checked + .btn-outline-teal-400:focus, .btn-check:active + .btn-outline-teal-400:focus, .btn-outline-teal-400:active:focus, .btn-outline-teal-400.active:focus, .btn-outline-teal-400.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(77, 212, 172, 0.5);
}
.btn-outline-teal-400:disabled, .btn-outline-teal-400.disabled {
  color: #4dd4ac;
  background-color: transparent;
}

.btn-outline-teal-500 {
  color: #20c997;
  border-color: #20c997;
}
.btn-outline-teal-500:hover {
  color: #000;
  background-color: #20c997;
  border-color: #20c997;
}
.btn-check:focus + .btn-outline-teal-500, .btn-outline-teal-500:focus {
  box-shadow: 0 0 0 0.25rem rgba(32, 201, 151, 0.5);
}
.btn-check:checked + .btn-outline-teal-500, .btn-check:active + .btn-outline-teal-500, .btn-outline-teal-500:active, .btn-outline-teal-500.active, .btn-outline-teal-500.dropdown-toggle.show {
  color: #000;
  background-color: #20c997;
  border-color: #20c997;
}
.btn-check:checked + .btn-outline-teal-500:focus, .btn-check:active + .btn-outline-teal-500:focus, .btn-outline-teal-500:active:focus, .btn-outline-teal-500.active:focus, .btn-outline-teal-500.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(32, 201, 151, 0.5);
}
.btn-outline-teal-500:disabled, .btn-outline-teal-500.disabled {
  color: #20c997;
  background-color: transparent;
}

.btn-outline-teal-600 {
  color: #1aa179;
  border-color: #1aa179;
}
.btn-outline-teal-600:hover {
  color: #000;
  background-color: #1aa179;
  border-color: #1aa179;
}
.btn-check:focus + .btn-outline-teal-600, .btn-outline-teal-600:focus {
  box-shadow: 0 0 0 0.25rem rgba(26, 161, 121, 0.5);
}
.btn-check:checked + .btn-outline-teal-600, .btn-check:active + .btn-outline-teal-600, .btn-outline-teal-600:active, .btn-outline-teal-600.active, .btn-outline-teal-600.dropdown-toggle.show {
  color: #000;
  background-color: #1aa179;
  border-color: #1aa179;
}
.btn-check:checked + .btn-outline-teal-600:focus, .btn-check:active + .btn-outline-teal-600:focus, .btn-outline-teal-600:active:focus, .btn-outline-teal-600.active:focus, .btn-outline-teal-600.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(26, 161, 121, 0.5);
}
.btn-outline-teal-600:disabled, .btn-outline-teal-600.disabled {
  color: #1aa179;
  background-color: transparent;
}

.btn-outline-teal-700 {
  color: #13795b;
  border-color: #13795b;
}
.btn-outline-teal-700:hover {
  color: #fff;
  background-color: #13795b;
  border-color: #13795b;
}
.btn-check:focus + .btn-outline-teal-700, .btn-outline-teal-700:focus {
  box-shadow: 0 0 0 0.25rem rgba(19, 121, 91, 0.5);
}
.btn-check:checked + .btn-outline-teal-700, .btn-check:active + .btn-outline-teal-700, .btn-outline-teal-700:active, .btn-outline-teal-700.active, .btn-outline-teal-700.dropdown-toggle.show {
  color: #fff;
  background-color: #13795b;
  border-color: #13795b;
}
.btn-check:checked + .btn-outline-teal-700:focus, .btn-check:active + .btn-outline-teal-700:focus, .btn-outline-teal-700:active:focus, .btn-outline-teal-700.active:focus, .btn-outline-teal-700.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(19, 121, 91, 0.5);
}
.btn-outline-teal-700:disabled, .btn-outline-teal-700.disabled {
  color: #13795b;
  background-color: transparent;
}

.btn-outline-teal-800 {
  color: #0d503c;
  border-color: #0d503c;
}
.btn-outline-teal-800:hover {
  color: #fff;
  background-color: #0d503c;
  border-color: #0d503c;
}
.btn-check:focus + .btn-outline-teal-800, .btn-outline-teal-800:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 80, 60, 0.5);
}
.btn-check:checked + .btn-outline-teal-800, .btn-check:active + .btn-outline-teal-800, .btn-outline-teal-800:active, .btn-outline-teal-800.active, .btn-outline-teal-800.dropdown-toggle.show {
  color: #fff;
  background-color: #0d503c;
  border-color: #0d503c;
}
.btn-check:checked + .btn-outline-teal-800:focus, .btn-check:active + .btn-outline-teal-800:focus, .btn-outline-teal-800:active:focus, .btn-outline-teal-800.active:focus, .btn-outline-teal-800.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(13, 80, 60, 0.5);
}
.btn-outline-teal-800:disabled, .btn-outline-teal-800.disabled {
  color: #0d503c;
  background-color: transparent;
}

.btn-outline-teal-900 {
  color: #06281e;
  border-color: #06281e;
}
.btn-outline-teal-900:hover {
  color: #fff;
  background-color: #06281e;
  border-color: #06281e;
}
.btn-check:focus + .btn-outline-teal-900, .btn-outline-teal-900:focus {
  box-shadow: 0 0 0 0.25rem rgba(6, 40, 30, 0.5);
}
.btn-check:checked + .btn-outline-teal-900, .btn-check:active + .btn-outline-teal-900, .btn-outline-teal-900:active, .btn-outline-teal-900.active, .btn-outline-teal-900.dropdown-toggle.show {
  color: #fff;
  background-color: #06281e;
  border-color: #06281e;
}
.btn-check:checked + .btn-outline-teal-900:focus, .btn-check:active + .btn-outline-teal-900:focus, .btn-outline-teal-900:active:focus, .btn-outline-teal-900.active:focus, .btn-outline-teal-900.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(6, 40, 30, 0.5);
}
.btn-outline-teal-900:disabled, .btn-outline-teal-900.disabled {
  color: #06281e;
  background-color: transparent;
}

.btn-outline-cyan-100 {
  color: #cff4fc;
  border-color: #cff4fc;
}
.btn-outline-cyan-100:hover {
  color: #000;
  background-color: #cff4fc;
  border-color: #cff4fc;
}
.btn-check:focus + .btn-outline-cyan-100, .btn-outline-cyan-100:focus {
  box-shadow: 0 0 0 0.25rem rgba(207, 244, 252, 0.5);
}
.btn-check:checked + .btn-outline-cyan-100, .btn-check:active + .btn-outline-cyan-100, .btn-outline-cyan-100:active, .btn-outline-cyan-100.active, .btn-outline-cyan-100.dropdown-toggle.show {
  color: #000;
  background-color: #cff4fc;
  border-color: #cff4fc;
}
.btn-check:checked + .btn-outline-cyan-100:focus, .btn-check:active + .btn-outline-cyan-100:focus, .btn-outline-cyan-100:active:focus, .btn-outline-cyan-100.active:focus, .btn-outline-cyan-100.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(207, 244, 252, 0.5);
}
.btn-outline-cyan-100:disabled, .btn-outline-cyan-100.disabled {
  color: #cff4fc;
  background-color: transparent;
}

.btn-outline-cyan-200 {
  color: #9eeaf9;
  border-color: #9eeaf9;
}
.btn-outline-cyan-200:hover {
  color: #000;
  background-color: #9eeaf9;
  border-color: #9eeaf9;
}
.btn-check:focus + .btn-outline-cyan-200, .btn-outline-cyan-200:focus {
  box-shadow: 0 0 0 0.25rem rgba(158, 234, 249, 0.5);
}
.btn-check:checked + .btn-outline-cyan-200, .btn-check:active + .btn-outline-cyan-200, .btn-outline-cyan-200:active, .btn-outline-cyan-200.active, .btn-outline-cyan-200.dropdown-toggle.show {
  color: #000;
  background-color: #9eeaf9;
  border-color: #9eeaf9;
}
.btn-check:checked + .btn-outline-cyan-200:focus, .btn-check:active + .btn-outline-cyan-200:focus, .btn-outline-cyan-200:active:focus, .btn-outline-cyan-200.active:focus, .btn-outline-cyan-200.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(158, 234, 249, 0.5);
}
.btn-outline-cyan-200:disabled, .btn-outline-cyan-200.disabled {
  color: #9eeaf9;
  background-color: transparent;
}

.btn-outline-cyan-300 {
  color: #6edff6;
  border-color: #6edff6;
}
.btn-outline-cyan-300:hover {
  color: #000;
  background-color: #6edff6;
  border-color: #6edff6;
}
.btn-check:focus + .btn-outline-cyan-300, .btn-outline-cyan-300:focus {
  box-shadow: 0 0 0 0.25rem rgba(110, 223, 246, 0.5);
}
.btn-check:checked + .btn-outline-cyan-300, .btn-check:active + .btn-outline-cyan-300, .btn-outline-cyan-300:active, .btn-outline-cyan-300.active, .btn-outline-cyan-300.dropdown-toggle.show {
  color: #000;
  background-color: #6edff6;
  border-color: #6edff6;
}
.btn-check:checked + .btn-outline-cyan-300:focus, .btn-check:active + .btn-outline-cyan-300:focus, .btn-outline-cyan-300:active:focus, .btn-outline-cyan-300.active:focus, .btn-outline-cyan-300.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(110, 223, 246, 0.5);
}
.btn-outline-cyan-300:disabled, .btn-outline-cyan-300.disabled {
  color: #6edff6;
  background-color: transparent;
}

.btn-outline-cyan-400 {
  color: #3dd5f3;
  border-color: #3dd5f3;
}
.btn-outline-cyan-400:hover {
  color: #000;
  background-color: #3dd5f3;
  border-color: #3dd5f3;
}
.btn-check:focus + .btn-outline-cyan-400, .btn-outline-cyan-400:focus {
  box-shadow: 0 0 0 0.25rem rgba(61, 213, 243, 0.5);
}
.btn-check:checked + .btn-outline-cyan-400, .btn-check:active + .btn-outline-cyan-400, .btn-outline-cyan-400:active, .btn-outline-cyan-400.active, .btn-outline-cyan-400.dropdown-toggle.show {
  color: #000;
  background-color: #3dd5f3;
  border-color: #3dd5f3;
}
.btn-check:checked + .btn-outline-cyan-400:focus, .btn-check:active + .btn-outline-cyan-400:focus, .btn-outline-cyan-400:active:focus, .btn-outline-cyan-400.active:focus, .btn-outline-cyan-400.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(61, 213, 243, 0.5);
}
.btn-outline-cyan-400:disabled, .btn-outline-cyan-400.disabled {
  color: #3dd5f3;
  background-color: transparent;
}

.btn-outline-cyan-500 {
  color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-outline-cyan-500:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:focus + .btn-outline-cyan-500, .btn-outline-cyan-500:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-check:checked + .btn-outline-cyan-500, .btn-check:active + .btn-outline-cyan-500, .btn-outline-cyan-500:active, .btn-outline-cyan-500.active, .btn-outline-cyan-500.dropdown-toggle.show {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:checked + .btn-outline-cyan-500:focus, .btn-check:active + .btn-outline-cyan-500:focus, .btn-outline-cyan-500:active:focus, .btn-outline-cyan-500.active:focus, .btn-outline-cyan-500.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-outline-cyan-500:disabled, .btn-outline-cyan-500.disabled {
  color: #0dcaf0;
  background-color: transparent;
}

.btn-outline-cyan-600 {
  color: #0aa2c0;
  border-color: #0aa2c0;
}
.btn-outline-cyan-600:hover {
  color: #000;
  background-color: #0aa2c0;
  border-color: #0aa2c0;
}
.btn-check:focus + .btn-outline-cyan-600, .btn-outline-cyan-600:focus {
  box-shadow: 0 0 0 0.25rem rgba(10, 162, 192, 0.5);
}
.btn-check:checked + .btn-outline-cyan-600, .btn-check:active + .btn-outline-cyan-600, .btn-outline-cyan-600:active, .btn-outline-cyan-600.active, .btn-outline-cyan-600.dropdown-toggle.show {
  color: #000;
  background-color: #0aa2c0;
  border-color: #0aa2c0;
}
.btn-check:checked + .btn-outline-cyan-600:focus, .btn-check:active + .btn-outline-cyan-600:focus, .btn-outline-cyan-600:active:focus, .btn-outline-cyan-600.active:focus, .btn-outline-cyan-600.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(10, 162, 192, 0.5);
}
.btn-outline-cyan-600:disabled, .btn-outline-cyan-600.disabled {
  color: #0aa2c0;
  background-color: transparent;
}

.btn-outline-cyan-700 {
  color: #087990;
  border-color: #087990;
}
.btn-outline-cyan-700:hover {
  color: #fff;
  background-color: #087990;
  border-color: #087990;
}
.btn-check:focus + .btn-outline-cyan-700, .btn-outline-cyan-700:focus {
  box-shadow: 0 0 0 0.25rem rgba(8, 121, 144, 0.5);
}
.btn-check:checked + .btn-outline-cyan-700, .btn-check:active + .btn-outline-cyan-700, .btn-outline-cyan-700:active, .btn-outline-cyan-700.active, .btn-outline-cyan-700.dropdown-toggle.show {
  color: #fff;
  background-color: #087990;
  border-color: #087990;
}
.btn-check:checked + .btn-outline-cyan-700:focus, .btn-check:active + .btn-outline-cyan-700:focus, .btn-outline-cyan-700:active:focus, .btn-outline-cyan-700.active:focus, .btn-outline-cyan-700.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(8, 121, 144, 0.5);
}
.btn-outline-cyan-700:disabled, .btn-outline-cyan-700.disabled {
  color: #087990;
  background-color: transparent;
}

.btn-outline-cyan-800 {
  color: #055160;
  border-color: #055160;
}
.btn-outline-cyan-800:hover {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}
.btn-check:focus + .btn-outline-cyan-800, .btn-outline-cyan-800:focus {
  box-shadow: 0 0 0 0.25rem rgba(5, 81, 96, 0.5);
}
.btn-check:checked + .btn-outline-cyan-800, .btn-check:active + .btn-outline-cyan-800, .btn-outline-cyan-800:active, .btn-outline-cyan-800.active, .btn-outline-cyan-800.dropdown-toggle.show {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}
.btn-check:checked + .btn-outline-cyan-800:focus, .btn-check:active + .btn-outline-cyan-800:focus, .btn-outline-cyan-800:active:focus, .btn-outline-cyan-800.active:focus, .btn-outline-cyan-800.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(5, 81, 96, 0.5);
}
.btn-outline-cyan-800:disabled, .btn-outline-cyan-800.disabled {
  color: #055160;
  background-color: transparent;
}

.btn-outline-cyan-900 {
  color: #032830;
  border-color: #032830;
}
.btn-outline-cyan-900:hover {
  color: #fff;
  background-color: #032830;
  border-color: #032830;
}
.btn-check:focus + .btn-outline-cyan-900, .btn-outline-cyan-900:focus {
  box-shadow: 0 0 0 0.25rem rgba(3, 40, 48, 0.5);
}
.btn-check:checked + .btn-outline-cyan-900, .btn-check:active + .btn-outline-cyan-900, .btn-outline-cyan-900:active, .btn-outline-cyan-900.active, .btn-outline-cyan-900.dropdown-toggle.show {
  color: #fff;
  background-color: #032830;
  border-color: #032830;
}
.btn-check:checked + .btn-outline-cyan-900:focus, .btn-check:active + .btn-outline-cyan-900:focus, .btn-outline-cyan-900:active:focus, .btn-outline-cyan-900.active:focus, .btn-outline-cyan-900.dropdown-toggle.show:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125), 0 0 0 0.25rem rgba(3, 40, 48, 0.5);
}
.btn-outline-cyan-900:disabled, .btn-outline-cyan-900.disabled {
  color: #032830;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #d6d6d6;
  text-decoration: none;
}
.btn-link:hover {
  color: white;
  -webkit-text-decoration: 1px dotted #d6d6d6;
  text-decoration: 1px dotted #d6d6d6;
}
.btn-link:focus {
  -webkit-text-decoration: 1px dotted #d6d6d6;
  text-decoration: 1px dotted #d6d6d6;
}
.btn-link:disabled, .btn-link.disabled {
  color: #7a8288;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.15625rem;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.809375rem;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 0.925rem;
  color: #999;
  text-align: left;
  list-style: none;
  background-color: #101010;
  background-clip: padding-box;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #e9ecef;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #23272b;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #ced4da;
  text-decoration: none;
  background-color: #272b30;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #999;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.809375rem;
  color: #7a8288;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #e9ecef;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #3a3f44;
  border-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #ced4da;
  background-color: #272b30;
}
.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #999;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-header {
  color: #999;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group.show .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #d6d6d6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: white;
}
.nav-link.disabled {
  color: #7a8288;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid rgba(255, 255, 255, 0.125);
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: rgba(222, 226, 230, 0.75) rgba(222, 226, 230, 0.75) rgba(255, 255, 255, 0.125);
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #7a8288;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #dee2e6;
  background-color: #101010;
  border-color: rgba(255, 255, 255, 0.125) rgba(255, 255, 255, 0.125) #101010;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ced4da;
  background-color: #272b30;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0.3265625rem;
  padding-bottom: 0.3265625rem;
  margin-right: 1rem;
  font-size: 1.15625rem;
  white-space: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.15625rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #1c1c1c;
  background-clip: border-box;
  border: 1px solid rgba(255, 255, 255, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.125);
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(255, 255, 255, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: #1c1c1c;
  border-bottom-color: #1c1c1c;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 0.925rem;
  color: #999;
  text-align: left;
  background-color: #101010;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #b9bfc4;
  background-color: #32363a;
  box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 0.125);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23b9bfc4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23999'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: rgba(10, 148, 217, 0.8);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(39, 43, 48, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #101010;
  border: 1px solid rgba(255, 255, 255, 0.125);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #7a8288;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: #7a8288;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #d6d6d6;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: white;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  color: white;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(39, 43, 48, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #ced4da;
  background-color: #272b30;
  border-color: #272b30;
}
.page-item.disabled .page-link {
  color: #7a8288;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.15625rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.809375rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #0d6efd;
  background-color: #101010;
  border-color: #0d6efd;
}
.alert-primary .alert-link {
  color: #0a58ca;
}

.alert-primary-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-primary-dark .alert-link {
  color: #0d0d0d;
}

.alert-secondary {
  color: #7a8288;
  background-color: #101010;
  border-color: #7a8288;
}
.alert-secondary .alert-link {
  color: #62686d;
}

.alert-secondary-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-secondary-dark .alert-link {
  color: #0d0d0d;
}

.alert-success {
  color: #198754;
  background-color: #101010;
  border-color: #198754;
}
.alert-success .alert-link {
  color: #146c43;
}

.alert-success-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-success-dark .alert-link {
  color: #0d0d0d;
}

.alert-info {
  color: #0dcaf0;
  background-color: #101010;
  border-color: #0dcaf0;
}
.alert-info .alert-link {
  color: #0aa2c0;
}

.alert-info-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-info-dark .alert-link {
  color: #0d0d0d;
}

.alert-warning {
  color: #ffc107;
  background-color: #101010;
  border-color: #ffc107;
}
.alert-warning .alert-link {
  color: #cc9a06;
}

.alert-warning-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-warning-dark .alert-link {
  color: #0d0d0d;
}

.alert-danger {
  color: #dc3545;
  background-color: #101010;
  border-color: #dc3545;
}
.alert-danger .alert-link {
  color: #b02a37;
}

.alert-danger-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-danger-dark .alert-link {
  color: #0d0d0d;
}

.alert-light {
  color: #f8f9fa;
  background-color: #101010;
  border-color: #f8f9fa;
}
.alert-light .alert-link {
  color: #c6c7c8;
}

.alert-light-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-light-dark .alert-link {
  color: #0d0d0d;
}

.alert-dark {
  color: #272b30;
  background-color: #101010;
  border-color: #272b30;
}
.alert-dark .alert-link {
  color: #1f2226;
}

.alert-dark-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-dark-dark .alert-link {
  color: #0d0d0d;
}

.alert-sfx {
  color: #0a94d9;
  background-color: #101010;
  border-color: #0a94d9;
}
.alert-sfx .alert-link {
  color: #0876ae;
}

.alert-sfx-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-sfx-dark .alert-link {
  color: #0d0d0d;
}

.alert-that-dark-dark {
  color: #121416;
  background-color: #101010;
  border-color: #121416;
}
.alert-that-dark-dark .alert-link {
  color: #0e1012;
}

.alert-that-dark-dark-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-that-dark-dark-dark .alert-link {
  color: #0d0d0d;
}

.alert-flat-f {
  color: #fff;
  background-color: #101010;
  border-color: #fff;
}
.alert-flat-f .alert-link {
  color: #cccccc;
}

.alert-flat-f-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-flat-f-dark .alert-link {
  color: #0d0d0d;
}

.alert-flat-e {
  color: #eee;
  background-color: #101010;
  border-color: #eee;
}
.alert-flat-e .alert-link {
  color: #bebebe;
}

.alert-flat-e-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-flat-e-dark .alert-link {
  color: #0d0d0d;
}

.alert-flat-d {
  color: #ddd;
  background-color: #101010;
  border-color: #ddd;
}
.alert-flat-d .alert-link {
  color: #b1b1b1;
}

.alert-flat-d-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-flat-d-dark .alert-link {
  color: #0d0d0d;
}

.alert-flat-c {
  color: #ccc;
  background-color: #101010;
  border-color: #ccc;
}
.alert-flat-c .alert-link {
  color: #a3a3a3;
}

.alert-flat-c-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-flat-c-dark .alert-link {
  color: #0d0d0d;
}

.alert-flat-b {
  color: #bbb;
  background-color: #101010;
  border-color: #bbb;
}
.alert-flat-b .alert-link {
  color: #969696;
}

.alert-flat-b-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-flat-b-dark .alert-link {
  color: #0d0d0d;
}

.alert-flat-a {
  color: #aaa;
  background-color: #101010;
  border-color: #aaa;
}
.alert-flat-a .alert-link {
  color: #888888;
}

.alert-flat-a-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-flat-a-dark .alert-link {
  color: #0d0d0d;
}

.alert-flat-9 {
  color: #999;
  background-color: #101010;
  border-color: #999;
}
.alert-flat-9 .alert-link {
  color: #7a7a7a;
}

.alert-flat-9-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-flat-9-dark .alert-link {
  color: #0d0d0d;
}

.alert-flat-8 {
  color: #888;
  background-color: #101010;
  border-color: #888;
}
.alert-flat-8 .alert-link {
  color: #6d6d6d;
}

.alert-flat-8-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-flat-8-dark .alert-link {
  color: #0d0d0d;
}

.alert-flat-7 {
  color: #777;
  background-color: #101010;
  border-color: #777;
}
.alert-flat-7 .alert-link {
  color: #5f5f5f;
}

.alert-flat-7-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-flat-7-dark .alert-link {
  color: #0d0d0d;
}

.alert-flat-6 {
  color: #666;
  background-color: #101010;
  border-color: #666;
}
.alert-flat-6 .alert-link {
  color: #525252;
}

.alert-flat-6-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-flat-6-dark .alert-link {
  color: #0d0d0d;
}

.alert-flat-5 {
  color: #555;
  background-color: #101010;
  border-color: #555;
}
.alert-flat-5 .alert-link {
  color: #444444;
}

.alert-flat-5-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-flat-5-dark .alert-link {
  color: #0d0d0d;
}

.alert-flat-4 {
  color: #444;
  background-color: #101010;
  border-color: #444;
}
.alert-flat-4 .alert-link {
  color: #363636;
}

.alert-flat-4-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-flat-4-dark .alert-link {
  color: #0d0d0d;
}

.alert-flat-3 {
  color: #333;
  background-color: #101010;
  border-color: #333;
}
.alert-flat-3 .alert-link {
  color: #292929;
}

.alert-flat-3-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-flat-3-dark .alert-link {
  color: #0d0d0d;
}

.alert-flat-2 {
  color: #222;
  background-color: #101010;
  border-color: #222;
}
.alert-flat-2 .alert-link {
  color: #1b1b1b;
}

.alert-flat-2-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-flat-2-dark .alert-link {
  color: #0d0d0d;
}

.alert-flat-1 {
  color: #111;
  background-color: #101010;
  border-color: #111;
}
.alert-flat-1 .alert-link {
  color: #0e0e0e;
}

.alert-flat-1-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-flat-1-dark .alert-link {
  color: #0d0d0d;
}

.alert-flat-0 {
  color: #000;
  background-color: #101010;
  border-color: #000;
}
.alert-flat-0 .alert-link {
  color: black;
}

.alert-flat-0-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-flat-0-dark .alert-link {
  color: #0d0d0d;
}

.alert-blue {
  color: #0d6efd;
  background-color: #101010;
  border-color: #0d6efd;
}
.alert-blue .alert-link {
  color: #0a58ca;
}

.alert-blue-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-blue-dark .alert-link {
  color: #0d0d0d;
}

.alert-indigo {
  color: #6610f2;
  background-color: #101010;
  border-color: #6610f2;
}
.alert-indigo .alert-link {
  color: #520dc2;
}

.alert-indigo-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-indigo-dark .alert-link {
  color: #0d0d0d;
}

.alert-purple {
  color: #6f42c1;
  background-color: #101010;
  border-color: #6f42c1;
}
.alert-purple .alert-link {
  color: #59359a;
}

.alert-purple-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-purple-dark .alert-link {
  color: #0d0d0d;
}

.alert-pink {
  color: #d63384;
  background-color: #101010;
  border-color: #d63384;
}
.alert-pink .alert-link {
  color: #ab296a;
}

.alert-pink-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-pink-dark .alert-link {
  color: #0d0d0d;
}

.alert-red {
  color: #dc3545;
  background-color: #101010;
  border-color: #dc3545;
}
.alert-red .alert-link {
  color: #b02a37;
}

.alert-red-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-red-dark .alert-link {
  color: #0d0d0d;
}

.alert-orange {
  color: #fd7e14;
  background-color: #101010;
  border-color: #fd7e14;
}
.alert-orange .alert-link {
  color: #ca6510;
}

.alert-orange-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-orange-dark .alert-link {
  color: #0d0d0d;
}

.alert-yellow {
  color: #ffc107;
  background-color: #101010;
  border-color: #ffc107;
}
.alert-yellow .alert-link {
  color: #cc9a06;
}

.alert-yellow-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-yellow-dark .alert-link {
  color: #0d0d0d;
}

.alert-green {
  color: #198754;
  background-color: #101010;
  border-color: #198754;
}
.alert-green .alert-link {
  color: #146c43;
}

.alert-green-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-green-dark .alert-link {
  color: #0d0d0d;
}

.alert-teal {
  color: #20c997;
  background-color: #101010;
  border-color: #20c997;
}
.alert-teal .alert-link {
  color: #1aa179;
}

.alert-teal-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-teal-dark .alert-link {
  color: #0d0d0d;
}

.alert-cyan {
  color: #0dcaf0;
  background-color: #101010;
  border-color: #0dcaf0;
}
.alert-cyan .alert-link {
  color: #0aa2c0;
}

.alert-cyan-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-cyan-dark .alert-link {
  color: #0d0d0d;
}

.alert-white {
  color: #fff;
  background-color: #101010;
  border-color: #fff;
}
.alert-white .alert-link {
  color: #cccccc;
}

.alert-white-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-white-dark .alert-link {
  color: #0d0d0d;
}

.alert-gray {
  color: #7a8288;
  background-color: #101010;
  border-color: #7a8288;
}
.alert-gray .alert-link {
  color: #62686d;
}

.alert-gray-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-gray-dark .alert-link {
  color: #0d0d0d;
}

.alert-gray-dark {
  color: #3a3f44;
  background-color: #101010;
  border-color: #3a3f44;
}
.alert-gray-dark .alert-link {
  color: #2e3236;
}

.alert-gray-dark-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-gray-dark-dark .alert-link {
  color: #0d0d0d;
}

.alert-blue-100 {
  color: #cfe2ff;
  background-color: #101010;
  border-color: #cfe2ff;
}
.alert-blue-100 .alert-link {
  color: #a6b5cc;
}

.alert-blue-100-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-blue-100-dark .alert-link {
  color: #0d0d0d;
}

.alert-blue-200 {
  color: #9ec5fe;
  background-color: #101010;
  border-color: #9ec5fe;
}
.alert-blue-200 .alert-link {
  color: #7e9ecb;
}

.alert-blue-200-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-blue-200-dark .alert-link {
  color: #0d0d0d;
}

.alert-blue-300 {
  color: #6ea8fe;
  background-color: #101010;
  border-color: #6ea8fe;
}
.alert-blue-300 .alert-link {
  color: #5886cb;
}

.alert-blue-300-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-blue-300-dark .alert-link {
  color: #0d0d0d;
}

.alert-blue-400 {
  color: #3d8bfd;
  background-color: #101010;
  border-color: #3d8bfd;
}
.alert-blue-400 .alert-link {
  color: #316fca;
}

.alert-blue-400-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-blue-400-dark .alert-link {
  color: #0d0d0d;
}

.alert-blue-500 {
  color: #0d6efd;
  background-color: #101010;
  border-color: #0d6efd;
}
.alert-blue-500 .alert-link {
  color: #0a58ca;
}

.alert-blue-500-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-blue-500-dark .alert-link {
  color: #0d0d0d;
}

.alert-blue-600 {
  color: #0a58ca;
  background-color: #101010;
  border-color: #0a58ca;
}
.alert-blue-600 .alert-link {
  color: #0846a2;
}

.alert-blue-600-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-blue-600-dark .alert-link {
  color: #0d0d0d;
}

.alert-blue-700 {
  color: #084298;
  background-color: #101010;
  border-color: #084298;
}
.alert-blue-700 .alert-link {
  color: #06357a;
}

.alert-blue-700-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-blue-700-dark .alert-link {
  color: #0d0d0d;
}

.alert-blue-800 {
  color: #052c65;
  background-color: #101010;
  border-color: #052c65;
}
.alert-blue-800 .alert-link {
  color: #042351;
}

.alert-blue-800-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-blue-800-dark .alert-link {
  color: #0d0d0d;
}

.alert-blue-900 {
  color: #031633;
  background-color: #101010;
  border-color: #031633;
}
.alert-blue-900 .alert-link {
  color: #021229;
}

.alert-blue-900-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-blue-900-dark .alert-link {
  color: #0d0d0d;
}

.alert-indigo-100 {
  color: #e0cffc;
  background-color: #101010;
  border-color: #e0cffc;
}
.alert-indigo-100 .alert-link {
  color: #b3a6ca;
}

.alert-indigo-100-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-indigo-100-dark .alert-link {
  color: #0d0d0d;
}

.alert-indigo-200 {
  color: #c29ffa;
  background-color: #101010;
  border-color: #c29ffa;
}
.alert-indigo-200 .alert-link {
  color: #9b7fc8;
}

.alert-indigo-200-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-indigo-200-dark .alert-link {
  color: #0d0d0d;
}

.alert-indigo-300 {
  color: #a370f7;
  background-color: #101010;
  border-color: #a370f7;
}
.alert-indigo-300 .alert-link {
  color: #825ac6;
}

.alert-indigo-300-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-indigo-300-dark .alert-link {
  color: #0d0d0d;
}

.alert-indigo-400 {
  color: #8540f5;
  background-color: #101010;
  border-color: #8540f5;
}
.alert-indigo-400 .alert-link {
  color: #6a33c4;
}

.alert-indigo-400-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-indigo-400-dark .alert-link {
  color: #0d0d0d;
}

.alert-indigo-500 {
  color: #6610f2;
  background-color: #101010;
  border-color: #6610f2;
}
.alert-indigo-500 .alert-link {
  color: #520dc2;
}

.alert-indigo-500-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-indigo-500-dark .alert-link {
  color: #0d0d0d;
}

.alert-indigo-600 {
  color: #520dc2;
  background-color: #101010;
  border-color: #520dc2;
}
.alert-indigo-600 .alert-link {
  color: #420a9b;
}

.alert-indigo-600-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-indigo-600-dark .alert-link {
  color: #0d0d0d;
}

.alert-indigo-700 {
  color: #3d0a91;
  background-color: #101010;
  border-color: #3d0a91;
}
.alert-indigo-700 .alert-link {
  color: #310874;
}

.alert-indigo-700-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-indigo-700-dark .alert-link {
  color: #0d0d0d;
}

.alert-indigo-800 {
  color: #290661;
  background-color: #101010;
  border-color: #290661;
}
.alert-indigo-800 .alert-link {
  color: #21054e;
}

.alert-indigo-800-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-indigo-800-dark .alert-link {
  color: #0d0d0d;
}

.alert-indigo-900 {
  color: #140330;
  background-color: #101010;
  border-color: #140330;
}
.alert-indigo-900 .alert-link {
  color: #100226;
}

.alert-indigo-900-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-indigo-900-dark .alert-link {
  color: #0d0d0d;
}

.alert-purple-100 {
  color: #e2d9f3;
  background-color: #101010;
  border-color: #e2d9f3;
}
.alert-purple-100 .alert-link {
  color: #b5aec2;
}

.alert-purple-100-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-purple-100-dark .alert-link {
  color: #0d0d0d;
}

.alert-purple-200 {
  color: #c5b3e6;
  background-color: #101010;
  border-color: #c5b3e6;
}
.alert-purple-200 .alert-link {
  color: #9e8fb8;
}

.alert-purple-200-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-purple-200-dark .alert-link {
  color: #0d0d0d;
}

.alert-purple-300 {
  color: #a98eda;
  background-color: #101010;
  border-color: #a98eda;
}
.alert-purple-300 .alert-link {
  color: #8772ae;
}

.alert-purple-300-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-purple-300-dark .alert-link {
  color: #0d0d0d;
}

.alert-purple-400 {
  color: #8c68cd;
  background-color: #101010;
  border-color: #8c68cd;
}
.alert-purple-400 .alert-link {
  color: #7053a4;
}

.alert-purple-400-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-purple-400-dark .alert-link {
  color: #0d0d0d;
}

.alert-purple-500 {
  color: #6f42c1;
  background-color: #101010;
  border-color: #6f42c1;
}
.alert-purple-500 .alert-link {
  color: #59359a;
}

.alert-purple-500-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-purple-500-dark .alert-link {
  color: #0d0d0d;
}

.alert-purple-600 {
  color: #59359a;
  background-color: #101010;
  border-color: #59359a;
}
.alert-purple-600 .alert-link {
  color: #472a7b;
}

.alert-purple-600-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-purple-600-dark .alert-link {
  color: #0d0d0d;
}

.alert-purple-700 {
  color: #432874;
  background-color: #101010;
  border-color: #432874;
}
.alert-purple-700 .alert-link {
  color: #36205d;
}

.alert-purple-700-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-purple-700-dark .alert-link {
  color: #0d0d0d;
}

.alert-purple-800 {
  color: #2c1a4d;
  background-color: #101010;
  border-color: #2c1a4d;
}
.alert-purple-800 .alert-link {
  color: #23153e;
}

.alert-purple-800-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-purple-800-dark .alert-link {
  color: #0d0d0d;
}

.alert-purple-900 {
  color: #160d27;
  background-color: #101010;
  border-color: #160d27;
}
.alert-purple-900 .alert-link {
  color: #120a1f;
}

.alert-purple-900-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-purple-900-dark .alert-link {
  color: #0d0d0d;
}

.alert-pink-100 {
  color: #f7d6e6;
  background-color: #101010;
  border-color: #f7d6e6;
}
.alert-pink-100 .alert-link {
  color: #c6abb8;
}

.alert-pink-100-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-pink-100-dark .alert-link {
  color: #0d0d0d;
}

.alert-pink-200 {
  color: #efadce;
  background-color: #101010;
  border-color: #efadce;
}
.alert-pink-200 .alert-link {
  color: #bf8aa5;
}

.alert-pink-200-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-pink-200-dark .alert-link {
  color: #0d0d0d;
}

.alert-pink-300 {
  color: #e685b5;
  background-color: #101010;
  border-color: #e685b5;
}
.alert-pink-300 .alert-link {
  color: #b86a91;
}

.alert-pink-300-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-pink-300-dark .alert-link {
  color: #0d0d0d;
}

.alert-pink-400 {
  color: #de5c9d;
  background-color: #101010;
  border-color: #de5c9d;
}
.alert-pink-400 .alert-link {
  color: #b24a7e;
}

.alert-pink-400-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-pink-400-dark .alert-link {
  color: #0d0d0d;
}

.alert-pink-500 {
  color: #d63384;
  background-color: #101010;
  border-color: #d63384;
}
.alert-pink-500 .alert-link {
  color: #ab296a;
}

.alert-pink-500-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-pink-500-dark .alert-link {
  color: #0d0d0d;
}

.alert-pink-600 {
  color: #ab296a;
  background-color: #101010;
  border-color: #ab296a;
}
.alert-pink-600 .alert-link {
  color: #892155;
}

.alert-pink-600-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-pink-600-dark .alert-link {
  color: #0d0d0d;
}

.alert-pink-700 {
  color: #801f4f;
  background-color: #101010;
  border-color: #801f4f;
}
.alert-pink-700 .alert-link {
  color: #66193f;
}

.alert-pink-700-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-pink-700-dark .alert-link {
  color: #0d0d0d;
}

.alert-pink-800 {
  color: #561435;
  background-color: #101010;
  border-color: #561435;
}
.alert-pink-800 .alert-link {
  color: #45102a;
}

.alert-pink-800-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-pink-800-dark .alert-link {
  color: #0d0d0d;
}

.alert-pink-900 {
  color: #2b0a1a;
  background-color: #101010;
  border-color: #2b0a1a;
}
.alert-pink-900 .alert-link {
  color: #220815;
}

.alert-pink-900-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-pink-900-dark .alert-link {
  color: #0d0d0d;
}

.alert-red-100 {
  color: #f8d7da;
  background-color: #101010;
  border-color: #f8d7da;
}
.alert-red-100 .alert-link {
  color: #c6acae;
}

.alert-red-100-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-red-100-dark .alert-link {
  color: #0d0d0d;
}

.alert-red-200 {
  color: #f1aeb5;
  background-color: #101010;
  border-color: #f1aeb5;
}
.alert-red-200 .alert-link {
  color: #c18b91;
}

.alert-red-200-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-red-200-dark .alert-link {
  color: #0d0d0d;
}

.alert-red-300 {
  color: #ea868f;
  background-color: #101010;
  border-color: #ea868f;
}
.alert-red-300 .alert-link {
  color: #bb6b72;
}

.alert-red-300-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-red-300-dark .alert-link {
  color: #0d0d0d;
}

.alert-red-400 {
  color: #e35d6a;
  background-color: #101010;
  border-color: #e35d6a;
}
.alert-red-400 .alert-link {
  color: #b64a55;
}

.alert-red-400-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-red-400-dark .alert-link {
  color: #0d0d0d;
}

.alert-red-500 {
  color: #dc3545;
  background-color: #101010;
  border-color: #dc3545;
}
.alert-red-500 .alert-link {
  color: #b02a37;
}

.alert-red-500-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-red-500-dark .alert-link {
  color: #0d0d0d;
}

.alert-red-600 {
  color: #b02a37;
  background-color: #101010;
  border-color: #b02a37;
}
.alert-red-600 .alert-link {
  color: #8d222c;
}

.alert-red-600-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-red-600-dark .alert-link {
  color: #0d0d0d;
}

.alert-red-700 {
  color: #842029;
  background-color: #101010;
  border-color: #842029;
}
.alert-red-700 .alert-link {
  color: #6a1a21;
}

.alert-red-700-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-red-700-dark .alert-link {
  color: #0d0d0d;
}

.alert-red-800 {
  color: #58151c;
  background-color: #101010;
  border-color: #58151c;
}
.alert-red-800 .alert-link {
  color: #461116;
}

.alert-red-800-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-red-800-dark .alert-link {
  color: #0d0d0d;
}

.alert-red-900 {
  color: #2c0b0e;
  background-color: #101010;
  border-color: #2c0b0e;
}
.alert-red-900 .alert-link {
  color: #23090b;
}

.alert-red-900-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-red-900-dark .alert-link {
  color: #0d0d0d;
}

.alert-orange-100 {
  color: #ffe5d0;
  background-color: #101010;
  border-color: #ffe5d0;
}
.alert-orange-100 .alert-link {
  color: #ccb7a6;
}

.alert-orange-100-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-orange-100-dark .alert-link {
  color: #0d0d0d;
}

.alert-orange-200 {
  color: #fecba1;
  background-color: #101010;
  border-color: #fecba1;
}
.alert-orange-200 .alert-link {
  color: #cba281;
}

.alert-orange-200-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-orange-200-dark .alert-link {
  color: #0d0d0d;
}

.alert-orange-300 {
  color: #feb272;
  background-color: #101010;
  border-color: #feb272;
}
.alert-orange-300 .alert-link {
  color: #cb8e5b;
}

.alert-orange-300-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-orange-300-dark .alert-link {
  color: #0d0d0d;
}

.alert-orange-400 {
  color: #fd9843;
  background-color: #101010;
  border-color: #fd9843;
}
.alert-orange-400 .alert-link {
  color: #ca7a36;
}

.alert-orange-400-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-orange-400-dark .alert-link {
  color: #0d0d0d;
}

.alert-orange-500 {
  color: #fd7e14;
  background-color: #101010;
  border-color: #fd7e14;
}
.alert-orange-500 .alert-link {
  color: #ca6510;
}

.alert-orange-500-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-orange-500-dark .alert-link {
  color: #0d0d0d;
}

.alert-orange-600 {
  color: #ca6510;
  background-color: #101010;
  border-color: #ca6510;
}
.alert-orange-600 .alert-link {
  color: #a2510d;
}

.alert-orange-600-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-orange-600-dark .alert-link {
  color: #0d0d0d;
}

.alert-orange-700 {
  color: #984c0c;
  background-color: #101010;
  border-color: #984c0c;
}
.alert-orange-700 .alert-link {
  color: #7a3d0a;
}

.alert-orange-700-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-orange-700-dark .alert-link {
  color: #0d0d0d;
}

.alert-orange-800 {
  color: #653208;
  background-color: #101010;
  border-color: #653208;
}
.alert-orange-800 .alert-link {
  color: #512806;
}

.alert-orange-800-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-orange-800-dark .alert-link {
  color: #0d0d0d;
}

.alert-orange-900 {
  color: #331904;
  background-color: #101010;
  border-color: #331904;
}
.alert-orange-900 .alert-link {
  color: #291403;
}

.alert-orange-900-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-orange-900-dark .alert-link {
  color: #0d0d0d;
}

.alert-yellow-100 {
  color: #fff3cd;
  background-color: #101010;
  border-color: #fff3cd;
}
.alert-yellow-100 .alert-link {
  color: #ccc2a4;
}

.alert-yellow-100-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-yellow-100-dark .alert-link {
  color: #0d0d0d;
}

.alert-yellow-200 {
  color: #ffe69c;
  background-color: #101010;
  border-color: #ffe69c;
}
.alert-yellow-200 .alert-link {
  color: #ccb87d;
}

.alert-yellow-200-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-yellow-200-dark .alert-link {
  color: #0d0d0d;
}

.alert-yellow-300 {
  color: #ffda6a;
  background-color: #101010;
  border-color: #ffda6a;
}
.alert-yellow-300 .alert-link {
  color: #ccae55;
}

.alert-yellow-300-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-yellow-300-dark .alert-link {
  color: #0d0d0d;
}

.alert-yellow-400 {
  color: #ffcd39;
  background-color: #101010;
  border-color: #ffcd39;
}
.alert-yellow-400 .alert-link {
  color: #cca42e;
}

.alert-yellow-400-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-yellow-400-dark .alert-link {
  color: #0d0d0d;
}

.alert-yellow-500 {
  color: #ffc107;
  background-color: #101010;
  border-color: #ffc107;
}
.alert-yellow-500 .alert-link {
  color: #cc9a06;
}

.alert-yellow-500-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-yellow-500-dark .alert-link {
  color: #0d0d0d;
}

.alert-yellow-600 {
  color: #cc9a06;
  background-color: #101010;
  border-color: #cc9a06;
}
.alert-yellow-600 .alert-link {
  color: #a37b05;
}

.alert-yellow-600-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-yellow-600-dark .alert-link {
  color: #0d0d0d;
}

.alert-yellow-700 {
  color: #997404;
  background-color: #101010;
  border-color: #997404;
}
.alert-yellow-700 .alert-link {
  color: #7a5d03;
}

.alert-yellow-700-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-yellow-700-dark .alert-link {
  color: #0d0d0d;
}

.alert-yellow-800 {
  color: #664d03;
  background-color: #101010;
  border-color: #664d03;
}
.alert-yellow-800 .alert-link {
  color: #523e02;
}

.alert-yellow-800-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-yellow-800-dark .alert-link {
  color: #0d0d0d;
}

.alert-yellow-900 {
  color: #332701;
  background-color: #101010;
  border-color: #332701;
}
.alert-yellow-900 .alert-link {
  color: #291f01;
}

.alert-yellow-900-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-yellow-900-dark .alert-link {
  color: #0d0d0d;
}

.alert-green-100 {
  color: #d1e7dd;
  background-color: #101010;
  border-color: #d1e7dd;
}
.alert-green-100 .alert-link {
  color: #a7b9b1;
}

.alert-green-100-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-green-100-dark .alert-link {
  color: #0d0d0d;
}

.alert-green-200 {
  color: #a3cfbb;
  background-color: #101010;
  border-color: #a3cfbb;
}
.alert-green-200 .alert-link {
  color: #82a696;
}

.alert-green-200-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-green-200-dark .alert-link {
  color: #0d0d0d;
}

.alert-green-300 {
  color: #75b798;
  background-color: #101010;
  border-color: #75b798;
}
.alert-green-300 .alert-link {
  color: #5e927a;
}

.alert-green-300-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-green-300-dark .alert-link {
  color: #0d0d0d;
}

.alert-green-400 {
  color: #479f76;
  background-color: #101010;
  border-color: #479f76;
}
.alert-green-400 .alert-link {
  color: #397f5e;
}

.alert-green-400-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-green-400-dark .alert-link {
  color: #0d0d0d;
}

.alert-green-500 {
  color: #198754;
  background-color: #101010;
  border-color: #198754;
}
.alert-green-500 .alert-link {
  color: #146c43;
}

.alert-green-500-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-green-500-dark .alert-link {
  color: #0d0d0d;
}

.alert-green-600 {
  color: #146c43;
  background-color: #101010;
  border-color: #146c43;
}
.alert-green-600 .alert-link {
  color: #105636;
}

.alert-green-600-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-green-600-dark .alert-link {
  color: #0d0d0d;
}

.alert-green-700 {
  color: #0f5132;
  background-color: #101010;
  border-color: #0f5132;
}
.alert-green-700 .alert-link {
  color: #0c4128;
}

.alert-green-700-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-green-700-dark .alert-link {
  color: #0d0d0d;
}

.alert-green-800 {
  color: #0a3622;
  background-color: #101010;
  border-color: #0a3622;
}
.alert-green-800 .alert-link {
  color: #082b1b;
}

.alert-green-800-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-green-800-dark .alert-link {
  color: #0d0d0d;
}

.alert-green-900 {
  color: #051b11;
  background-color: #101010;
  border-color: #051b11;
}
.alert-green-900 .alert-link {
  color: #04160e;
}

.alert-green-900-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-green-900-dark .alert-link {
  color: #0d0d0d;
}

.alert-teal-100 {
  color: #d2f4ea;
  background-color: #101010;
  border-color: #d2f4ea;
}
.alert-teal-100 .alert-link {
  color: #a8c3bb;
}

.alert-teal-100-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-teal-100-dark .alert-link {
  color: #0d0d0d;
}

.alert-teal-200 {
  color: #a6e9d5;
  background-color: #101010;
  border-color: #a6e9d5;
}
.alert-teal-200 .alert-link {
  color: #85baaa;
}

.alert-teal-200-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-teal-200-dark .alert-link {
  color: #0d0d0d;
}

.alert-teal-300 {
  color: #79dfc1;
  background-color: #101010;
  border-color: #79dfc1;
}
.alert-teal-300 .alert-link {
  color: #61b29a;
}

.alert-teal-300-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-teal-300-dark .alert-link {
  color: #0d0d0d;
}

.alert-teal-400 {
  color: #4dd4ac;
  background-color: #101010;
  border-color: #4dd4ac;
}
.alert-teal-400 .alert-link {
  color: #3eaa8a;
}

.alert-teal-400-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-teal-400-dark .alert-link {
  color: #0d0d0d;
}

.alert-teal-500 {
  color: #20c997;
  background-color: #101010;
  border-color: #20c997;
}
.alert-teal-500 .alert-link {
  color: #1aa179;
}

.alert-teal-500-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-teal-500-dark .alert-link {
  color: #0d0d0d;
}

.alert-teal-600 {
  color: #1aa179;
  background-color: #101010;
  border-color: #1aa179;
}
.alert-teal-600 .alert-link {
  color: #158161;
}

.alert-teal-600-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-teal-600-dark .alert-link {
  color: #0d0d0d;
}

.alert-teal-700 {
  color: #13795b;
  background-color: #101010;
  border-color: #13795b;
}
.alert-teal-700 .alert-link {
  color: #0f6149;
}

.alert-teal-700-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-teal-700-dark .alert-link {
  color: #0d0d0d;
}

.alert-teal-800 {
  color: #0d503c;
  background-color: #101010;
  border-color: #0d503c;
}
.alert-teal-800 .alert-link {
  color: #0a4030;
}

.alert-teal-800-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-teal-800-dark .alert-link {
  color: #0d0d0d;
}

.alert-teal-900 {
  color: #06281e;
  background-color: #101010;
  border-color: #06281e;
}
.alert-teal-900 .alert-link {
  color: #052018;
}

.alert-teal-900-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-teal-900-dark .alert-link {
  color: #0d0d0d;
}

.alert-cyan-100 {
  color: #cff4fc;
  background-color: #101010;
  border-color: #cff4fc;
}
.alert-cyan-100 .alert-link {
  color: #a6c3ca;
}

.alert-cyan-100-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-cyan-100-dark .alert-link {
  color: #0d0d0d;
}

.alert-cyan-200 {
  color: #9eeaf9;
  background-color: #101010;
  border-color: #9eeaf9;
}
.alert-cyan-200 .alert-link {
  color: #7ebbc7;
}

.alert-cyan-200-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-cyan-200-dark .alert-link {
  color: #0d0d0d;
}

.alert-cyan-300 {
  color: #6edff6;
  background-color: #101010;
  border-color: #6edff6;
}
.alert-cyan-300 .alert-link {
  color: #58b2c5;
}

.alert-cyan-300-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-cyan-300-dark .alert-link {
  color: #0d0d0d;
}

.alert-cyan-400 {
  color: #3dd5f3;
  background-color: #101010;
  border-color: #3dd5f3;
}
.alert-cyan-400 .alert-link {
  color: #31aac2;
}

.alert-cyan-400-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-cyan-400-dark .alert-link {
  color: #0d0d0d;
}

.alert-cyan-500 {
  color: #0dcaf0;
  background-color: #101010;
  border-color: #0dcaf0;
}
.alert-cyan-500 .alert-link {
  color: #0aa2c0;
}

.alert-cyan-500-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-cyan-500-dark .alert-link {
  color: #0d0d0d;
}

.alert-cyan-600 {
  color: #0aa2c0;
  background-color: #101010;
  border-color: #0aa2c0;
}
.alert-cyan-600 .alert-link {
  color: #08829a;
}

.alert-cyan-600-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-cyan-600-dark .alert-link {
  color: #0d0d0d;
}

.alert-cyan-700 {
  color: #087990;
  background-color: #101010;
  border-color: #087990;
}
.alert-cyan-700 .alert-link {
  color: #066173;
}

.alert-cyan-700-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-cyan-700-dark .alert-link {
  color: #0d0d0d;
}

.alert-cyan-800 {
  color: #055160;
  background-color: #101010;
  border-color: #055160;
}
.alert-cyan-800 .alert-link {
  color: #04414d;
}

.alert-cyan-800-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-cyan-800-dark .alert-link {
  color: #0d0d0d;
}

.alert-cyan-900 {
  color: #032830;
  background-color: #101010;
  border-color: #032830;
}
.alert-cyan-900 .alert-link {
  color: #022026;
}

.alert-cyan-900-dark {
  color: #101010;
  background-color: #101010;
  border-color: #101010;
}
.alert-cyan-900-dark .alert-link {
  color: #0d0d0d;
}

@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.69375rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0d6efd;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #52575c;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #52575c;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #999;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #999;
  background-color: #1c1c1c;
  border: 1px solid rgba(255, 255, 255, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #7a8288;
  pointer-events: none;
  background-color: #1c1c1c;
}
.list-group-item.active {
  z-index: 2;
  color: #ced4da;
  background-color: #272b30;
  border-color: #272b30;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #084298;
  background-color: #cfe2ff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #084298;
  background-color: #bacbe6;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #084298;
  border-color: #084298;
}

.list-group-item-secondary {
  color: #494e52;
  background-color: #e4e6e7;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #494e52;
  background-color: #cdcfd0;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #494e52;
  border-color: #494e52;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #171a1d;
  background-color: #d4d5d6;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #171a1d;
  background-color: #bfc0c1;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #171a1d;
  border-color: #171a1d;
}

.list-group-item-sfx {
  color: #065982;
  background-color: #ceeaf7;
}
.list-group-item-sfx.list-group-item-action:hover, .list-group-item-sfx.list-group-item-action:focus {
  color: #065982;
  background-color: #b9d3de;
}
.list-group-item-sfx.list-group-item-action.active {
  color: #fff;
  background-color: #065982;
  border-color: #065982;
}

.list-group-item-that-dark-dark {
  color: #0b0c0d;
  background-color: #d0d0d0;
}
.list-group-item-that-dark-dark.list-group-item-action:hover, .list-group-item-that-dark-dark.list-group-item-action:focus {
  color: #0b0c0d;
  background-color: #bbbbbb;
}
.list-group-item-that-dark-dark.list-group-item-action.active {
  color: #fff;
  background-color: #0b0c0d;
  border-color: #0b0c0d;
}

.list-group-item-flat-f {
  color: #666666;
  background-color: white;
}
.list-group-item-flat-f.list-group-item-action:hover, .list-group-item-flat-f.list-group-item-action:focus {
  color: #666666;
  background-color: #e6e6e6;
}
.list-group-item-flat-f.list-group-item-action.active {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
}

.list-group-item-flat-e {
  color: #5f5f5f;
  background-color: #fcfcfc;
}
.list-group-item-flat-e.list-group-item-action:hover, .list-group-item-flat-e.list-group-item-action:focus {
  color: #5f5f5f;
  background-color: #e3e3e3;
}
.list-group-item-flat-e.list-group-item-action.active {
  color: #fff;
  background-color: #5f5f5f;
  border-color: #5f5f5f;
}

.list-group-item-flat-d {
  color: #585858;
  background-color: #f8f8f8;
}
.list-group-item-flat-d.list-group-item-action:hover, .list-group-item-flat-d.list-group-item-action:focus {
  color: #585858;
  background-color: #dfdfdf;
}
.list-group-item-flat-d.list-group-item-action.active {
  color: #fff;
  background-color: #585858;
  border-color: #585858;
}

.list-group-item-flat-c {
  color: #525252;
  background-color: whitesmoke;
}
.list-group-item-flat-c.list-group-item-action:hover, .list-group-item-flat-c.list-group-item-action:focus {
  color: #525252;
  background-color: #dddddd;
}
.list-group-item-flat-c.list-group-item-action.active {
  color: #fff;
  background-color: #525252;
  border-color: #525252;
}

.list-group-item-flat-b {
  color: #4b4b4b;
  background-color: #f1f1f1;
}
.list-group-item-flat-b.list-group-item-action:hover, .list-group-item-flat-b.list-group-item-action:focus {
  color: #4b4b4b;
  background-color: #d9d9d9;
}
.list-group-item-flat-b.list-group-item-action.active {
  color: #fff;
  background-color: #4b4b4b;
  border-color: #4b4b4b;
}

.list-group-item-flat-a {
  color: #666666;
  background-color: #eeeeee;
}
.list-group-item-flat-a.list-group-item-action:hover, .list-group-item-flat-a.list-group-item-action:focus {
  color: #666666;
  background-color: #d6d6d6;
}
.list-group-item-flat-a.list-group-item-action.active {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
}

.list-group-item-flat-9 {
  color: #5c5c5c;
  background-color: #ebebeb;
}
.list-group-item-flat-9.list-group-item-action:hover, .list-group-item-flat-9.list-group-item-action:focus {
  color: #5c5c5c;
  background-color: #d4d4d4;
}
.list-group-item-flat-9.list-group-item-action.active {
  color: #fff;
  background-color: #5c5c5c;
  border-color: #5c5c5c;
}

.list-group-item-flat-8 {
  color: #525252;
  background-color: #e7e7e7;
}
.list-group-item-flat-8.list-group-item-action:hover, .list-group-item-flat-8.list-group-item-action:focus {
  color: #525252;
  background-color: #d0d0d0;
}
.list-group-item-flat-8.list-group-item-action.active {
  color: #fff;
  background-color: #525252;
  border-color: #525252;
}

.list-group-item-flat-7 {
  color: #474747;
  background-color: #e4e4e4;
}
.list-group-item-flat-7.list-group-item-action:hover, .list-group-item-flat-7.list-group-item-action:focus {
  color: #474747;
  background-color: #cdcdcd;
}
.list-group-item-flat-7.list-group-item-action.active {
  color: #fff;
  background-color: #474747;
  border-color: #474747;
}

.list-group-item-flat-6 {
  color: #3d3d3d;
  background-color: #e0e0e0;
}
.list-group-item-flat-6.list-group-item-action:hover, .list-group-item-flat-6.list-group-item-action:focus {
  color: #3d3d3d;
  background-color: #cacaca;
}
.list-group-item-flat-6.list-group-item-action.active {
  color: #fff;
  background-color: #3d3d3d;
  border-color: #3d3d3d;
}

.list-group-item-flat-5 {
  color: #333333;
  background-color: #dddddd;
}
.list-group-item-flat-5.list-group-item-action:hover, .list-group-item-flat-5.list-group-item-action:focus {
  color: #333333;
  background-color: #c7c7c7;
}
.list-group-item-flat-5.list-group-item-action.active {
  color: #fff;
  background-color: #333333;
  border-color: #333333;
}

.list-group-item-flat-4 {
  color: #292929;
  background-color: #dadada;
}
.list-group-item-flat-4.list-group-item-action:hover, .list-group-item-flat-4.list-group-item-action:focus {
  color: #292929;
  background-color: #c4c4c4;
}
.list-group-item-flat-4.list-group-item-action.active {
  color: #fff;
  background-color: #292929;
  border-color: #292929;
}

.list-group-item-flat-3 {
  color: #1f1f1f;
  background-color: #d6d6d6;
}
.list-group-item-flat-3.list-group-item-action:hover, .list-group-item-flat-3.list-group-item-action:focus {
  color: #1f1f1f;
  background-color: #c1c1c1;
}
.list-group-item-flat-3.list-group-item-action.active {
  color: #fff;
  background-color: #1f1f1f;
  border-color: #1f1f1f;
}

.list-group-item-flat-2 {
  color: #141414;
  background-color: lightgray;
}
.list-group-item-flat-2.list-group-item-action:hover, .list-group-item-flat-2.list-group-item-action:focus {
  color: #141414;
  background-color: #bebebe;
}
.list-group-item-flat-2.list-group-item-action.active {
  color: #fff;
  background-color: #141414;
  border-color: #141414;
}

.list-group-item-flat-1 {
  color: #0a0a0a;
  background-color: #cfcfcf;
}
.list-group-item-flat-1.list-group-item-action:hover, .list-group-item-flat-1.list-group-item-action:focus {
  color: #0a0a0a;
  background-color: #bababa;
}
.list-group-item-flat-1.list-group-item-action.active {
  color: #fff;
  background-color: #0a0a0a;
  border-color: #0a0a0a;
}

.list-group-item-flat-0 {
  color: black;
  background-color: #cccccc;
}
.list-group-item-flat-0.list-group-item-action:hover, .list-group-item-flat-0.list-group-item-action:focus {
  color: black;
  background-color: #b8b8b8;
}
.list-group-item-flat-0.list-group-item-action.active {
  color: #fff;
  background-color: black;
  border-color: black;
}

.list-group-item-blue {
  color: #084298;
  background-color: #cfe2ff;
}
.list-group-item-blue.list-group-item-action:hover, .list-group-item-blue.list-group-item-action:focus {
  color: #084298;
  background-color: #bacbe6;
}
.list-group-item-blue.list-group-item-action.active {
  color: #fff;
  background-color: #084298;
  border-color: #084298;
}

.list-group-item-indigo {
  color: #3d0a91;
  background-color: #e0cffc;
}
.list-group-item-indigo.list-group-item-action:hover, .list-group-item-indigo.list-group-item-action:focus {
  color: #3d0a91;
  background-color: #cabae3;
}
.list-group-item-indigo.list-group-item-action.active {
  color: #fff;
  background-color: #3d0a91;
  border-color: #3d0a91;
}

.list-group-item-purple {
  color: #432874;
  background-color: #e2d9f3;
}
.list-group-item-purple.list-group-item-action:hover, .list-group-item-purple.list-group-item-action:focus {
  color: #432874;
  background-color: #cbc3db;
}
.list-group-item-purple.list-group-item-action.active {
  color: #fff;
  background-color: #432874;
  border-color: #432874;
}

.list-group-item-pink {
  color: #801f4f;
  background-color: #f7d6e6;
}
.list-group-item-pink.list-group-item-action:hover, .list-group-item-pink.list-group-item-action:focus {
  color: #801f4f;
  background-color: #dec1cf;
}
.list-group-item-pink.list-group-item-action.active {
  color: #fff;
  background-color: #801f4f;
  border-color: #801f4f;
}

.list-group-item-red {
  color: #842029;
  background-color: #f8d7da;
}
.list-group-item-red.list-group-item-action:hover, .list-group-item-red.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}
.list-group-item-red.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-orange {
  color: #984c0c;
  background-color: #ffe5d0;
}
.list-group-item-orange.list-group-item-action:hover, .list-group-item-orange.list-group-item-action:focus {
  color: #984c0c;
  background-color: #e6cebb;
}
.list-group-item-orange.list-group-item-action.active {
  color: #fff;
  background-color: #984c0c;
  border-color: #984c0c;
}

.list-group-item-yellow {
  color: #664d03;
  background-color: #fff3cd;
}
.list-group-item-yellow.list-group-item-action:hover, .list-group-item-yellow.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}
.list-group-item-yellow.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-green {
  color: #0f5132;
  background-color: #d1e7dd;
}
.list-group-item-green.list-group-item-action:hover, .list-group-item-green.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}
.list-group-item-green.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-teal {
  color: #13795b;
  background-color: #d2f4ea;
}
.list-group-item-teal.list-group-item-action:hover, .list-group-item-teal.list-group-item-action:focus {
  color: #13795b;
  background-color: #bddcd3;
}
.list-group-item-teal.list-group-item-action.active {
  color: #fff;
  background-color: #13795b;
  border-color: #13795b;
}

.list-group-item-cyan {
  color: #055160;
  background-color: #cff4fc;
}
.list-group-item-cyan.list-group-item-action:hover, .list-group-item-cyan.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}
.list-group-item-cyan.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-white {
  color: #666666;
  background-color: white;
}
.list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
  color: #666666;
  background-color: #e6e6e6;
}
.list-group-item-white.list-group-item-action.active {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
}

.list-group-item-gray {
  color: #494e52;
  background-color: #e4e6e7;
}
.list-group-item-gray.list-group-item-action:hover, .list-group-item-gray.list-group-item-action:focus {
  color: #494e52;
  background-color: #cdcfd0;
}
.list-group-item-gray.list-group-item-action.active {
  color: #fff;
  background-color: #494e52;
  border-color: #494e52;
}

.list-group-item-gray-dark {
  color: #232629;
  background-color: #d8d9da;
}
.list-group-item-gray-dark.list-group-item-action:hover, .list-group-item-gray-dark.list-group-item-action:focus {
  color: #232629;
  background-color: #c2c3c4;
}
.list-group-item-gray-dark.list-group-item-action.active {
  color: #fff;
  background-color: #232629;
  border-color: #232629;
}

.list-group-item-blue-100 {
  color: #535a66;
  background-color: #f5f9ff;
}
.list-group-item-blue-100.list-group-item-action:hover, .list-group-item-blue-100.list-group-item-action:focus {
  color: #535a66;
  background-color: #dde0e6;
}
.list-group-item-blue-100.list-group-item-action.active {
  color: #fff;
  background-color: #535a66;
  border-color: #535a66;
}

.list-group-item-blue-200 {
  color: #3f4f66;
  background-color: #ecf3ff;
}
.list-group-item-blue-200.list-group-item-action:hover, .list-group-item-blue-200.list-group-item-action:focus {
  color: #3f4f66;
  background-color: #d4dbe6;
}
.list-group-item-blue-200.list-group-item-action.active {
  color: #fff;
  background-color: #3f4f66;
  border-color: #3f4f66;
}

.list-group-item-blue-300 {
  color: #426598;
  background-color: #e2eeff;
}
.list-group-item-blue-300.list-group-item-action:hover, .list-group-item-blue-300.list-group-item-action:focus {
  color: #426598;
  background-color: #cbd6e6;
}
.list-group-item-blue-300.list-group-item-action.active {
  color: #fff;
  background-color: #426598;
  border-color: #426598;
}

.list-group-item-blue-400 {
  color: #255398;
  background-color: #d8e8ff;
}
.list-group-item-blue-400.list-group-item-action:hover, .list-group-item-blue-400.list-group-item-action:focus {
  color: #255398;
  background-color: #c2d1e6;
}
.list-group-item-blue-400.list-group-item-action.active {
  color: #fff;
  background-color: #255398;
  border-color: #255398;
}

.list-group-item-blue-500 {
  color: #084298;
  background-color: #cfe2ff;
}
.list-group-item-blue-500.list-group-item-action:hover, .list-group-item-blue-500.list-group-item-action:focus {
  color: #084298;
  background-color: #bacbe6;
}
.list-group-item-blue-500.list-group-item-action.active {
  color: #fff;
  background-color: #084298;
  border-color: #084298;
}

.list-group-item-blue-600 {
  color: #063579;
  background-color: #cedef4;
}
.list-group-item-blue-600.list-group-item-action:hover, .list-group-item-blue-600.list-group-item-action:focus {
  color: #063579;
  background-color: #b9c8dc;
}
.list-group-item-blue-600.list-group-item-action.active {
  color: #fff;
  background-color: #063579;
  border-color: #063579;
}

.list-group-item-blue-700 {
  color: #05285b;
  background-color: #ced9ea;
}
.list-group-item-blue-700.list-group-item-action:hover, .list-group-item-blue-700.list-group-item-action:focus {
  color: #05285b;
  background-color: #b9c3d3;
}
.list-group-item-blue-700.list-group-item-action.active {
  color: #fff;
  background-color: #05285b;
  border-color: #05285b;
}

.list-group-item-blue-800 {
  color: #031a3d;
  background-color: #cdd5e0;
}
.list-group-item-blue-800.list-group-item-action:hover, .list-group-item-blue-800.list-group-item-action:focus {
  color: #031a3d;
  background-color: #b9c0ca;
}
.list-group-item-blue-800.list-group-item-action.active {
  color: #fff;
  background-color: #031a3d;
  border-color: #031a3d;
}

.list-group-item-blue-900 {
  color: #020d1f;
  background-color: #cdd0d6;
}
.list-group-item-blue-900.list-group-item-action:hover, .list-group-item-blue-900.list-group-item-action:focus {
  color: #020d1f;
  background-color: #b9bbc1;
}
.list-group-item-blue-900.list-group-item-action.active {
  color: #fff;
  background-color: #020d1f;
  border-color: #020d1f;
}

.list-group-item-indigo-100 {
  color: #5a5365;
  background-color: #f9f5fe;
}
.list-group-item-indigo-100.list-group-item-action:hover, .list-group-item-indigo-100.list-group-item-action:focus {
  color: #5a5365;
  background-color: #e0dde5;
}
.list-group-item-indigo-100.list-group-item-action.active {
  color: #fff;
  background-color: #5a5365;
  border-color: #5a5365;
}

.list-group-item-indigo-200 {
  color: #745f96;
  background-color: #f3ecfe;
}
.list-group-item-indigo-200.list-group-item-action:hover, .list-group-item-indigo-200.list-group-item-action:focus {
  color: #745f96;
  background-color: #dbd4e5;
}
.list-group-item-indigo-200.list-group-item-action.active {
  color: #fff;
  background-color: #745f96;
  border-color: #745f96;
}

.list-group-item-indigo-300 {
  color: #624394;
  background-color: #ede2fd;
}
.list-group-item-indigo-300.list-group-item-action:hover, .list-group-item-indigo-300.list-group-item-action:focus {
  color: #624394;
  background-color: #d5cbe4;
}
.list-group-item-indigo-300.list-group-item-action.active {
  color: #fff;
  background-color: #624394;
  border-color: #624394;
}

.list-group-item-indigo-400 {
  color: #502693;
  background-color: #e7d9fd;
}
.list-group-item-indigo-400.list-group-item-action:hover, .list-group-item-indigo-400.list-group-item-action:focus {
  color: #502693;
  background-color: #d0c3e4;
}
.list-group-item-indigo-400.list-group-item-action.active {
  color: #fff;
  background-color: #502693;
  border-color: #502693;
}

.list-group-item-indigo-500 {
  color: #3d0a91;
  background-color: #e0cffc;
}
.list-group-item-indigo-500.list-group-item-action:hover, .list-group-item-indigo-500.list-group-item-action:focus {
  color: #3d0a91;
  background-color: #cabae3;
}
.list-group-item-indigo-500.list-group-item-action.active {
  color: #fff;
  background-color: #3d0a91;
  border-color: #3d0a91;
}

.list-group-item-indigo-600 {
  color: #310874;
  background-color: #dccff3;
}
.list-group-item-indigo-600.list-group-item-action:hover, .list-group-item-indigo-600.list-group-item-action:focus {
  color: #310874;
  background-color: #c6badb;
}
.list-group-item-indigo-600.list-group-item-action.active {
  color: #fff;
  background-color: #310874;
  border-color: #310874;
}

.list-group-item-indigo-700 {
  color: #250657;
  background-color: #d8cee9;
}
.list-group-item-indigo-700.list-group-item-action:hover, .list-group-item-indigo-700.list-group-item-action:focus {
  color: #250657;
  background-color: #c2b9d2;
}
.list-group-item-indigo-700.list-group-item-action.active {
  color: #fff;
  background-color: #250657;
  border-color: #250657;
}

.list-group-item-indigo-800 {
  color: #19043a;
  background-color: #d4cddf;
}
.list-group-item-indigo-800.list-group-item-action:hover, .list-group-item-indigo-800.list-group-item-action:focus {
  color: #19043a;
  background-color: #bfb9c9;
}
.list-group-item-indigo-800.list-group-item-action.active {
  color: #fff;
  background-color: #19043a;
  border-color: #19043a;
}

.list-group-item-indigo-900 {
  color: #0c021d;
  background-color: #d0cdd6;
}
.list-group-item-indigo-900.list-group-item-action:hover, .list-group-item-indigo-900.list-group-item-action:focus {
  color: #0c021d;
  background-color: #bbb9c1;
}
.list-group-item-indigo-900.list-group-item-action.active {
  color: #fff;
  background-color: #0c021d;
  border-color: #0c021d;
}

.list-group-item-purple-100 {
  color: #5a5761;
  background-color: #f9f7fd;
}
.list-group-item-purple-100.list-group-item-action:hover, .list-group-item-purple-100.list-group-item-action:focus {
  color: #5a5761;
  background-color: #e0dee4;
}
.list-group-item-purple-100.list-group-item-action.active {
  color: #fff;
  background-color: #5a5761;
  border-color: #5a5761;
}

.list-group-item-purple-200 {
  color: #4f485c;
  background-color: #f3f0fa;
}
.list-group-item-purple-200.list-group-item-action:hover, .list-group-item-purple-200.list-group-item-action:focus {
  color: #4f485c;
  background-color: #dbd8e1;
}
.list-group-item-purple-200.list-group-item-action.active {
  color: #fff;
  background-color: #4f485c;
  border-color: #4f485c;
}

.list-group-item-purple-300 {
  color: #655583;
  background-color: #eee8f8;
}
.list-group-item-purple-300.list-group-item-action:hover, .list-group-item-purple-300.list-group-item-action:focus {
  color: #655583;
  background-color: #d6d1df;
}
.list-group-item-purple-300.list-group-item-action.active {
  color: #fff;
  background-color: #655583;
  border-color: #655583;
}

.list-group-item-purple-400 {
  color: #543e7b;
  background-color: #e8e1f5;
}
.list-group-item-purple-400.list-group-item-action:hover, .list-group-item-purple-400.list-group-item-action:focus {
  color: #543e7b;
  background-color: #d1cbdd;
}
.list-group-item-purple-400.list-group-item-action.active {
  color: #fff;
  background-color: #543e7b;
  border-color: #543e7b;
}

.list-group-item-purple-500 {
  color: #432874;
  background-color: #e2d9f3;
}
.list-group-item-purple-500.list-group-item-action:hover, .list-group-item-purple-500.list-group-item-action:focus {
  color: #432874;
  background-color: #cbc3db;
}
.list-group-item-purple-500.list-group-item-action.active {
  color: #fff;
  background-color: #432874;
  border-color: #432874;
}

.list-group-item-purple-600 {
  color: #35205c;
  background-color: #ded7eb;
}
.list-group-item-purple-600.list-group-item-action:hover, .list-group-item-purple-600.list-group-item-action:focus {
  color: #35205c;
  background-color: #c8c2d4;
}
.list-group-item-purple-600.list-group-item-action.active {
  color: #fff;
  background-color: #35205c;
  border-color: #35205c;
}

.list-group-item-purple-700 {
  color: #281846;
  background-color: #d9d4e3;
}
.list-group-item-purple-700.list-group-item-action:hover, .list-group-item-purple-700.list-group-item-action:focus {
  color: #281846;
  background-color: #c3bfcc;
}
.list-group-item-purple-700.list-group-item-action.active {
  color: #fff;
  background-color: #281846;
  border-color: #281846;
}

.list-group-item-purple-800 {
  color: #1a102e;
  background-color: #d5d1db;
}
.list-group-item-purple-800.list-group-item-action:hover, .list-group-item-purple-800.list-group-item-action:focus {
  color: #1a102e;
  background-color: #c0bcc5;
}
.list-group-item-purple-800.list-group-item-action.active {
  color: #fff;
  background-color: #1a102e;
  border-color: #1a102e;
}

.list-group-item-purple-900 {
  color: #0d0817;
  background-color: #d0cfd4;
}
.list-group-item-purple-900.list-group-item-action:hover, .list-group-item-purple-900.list-group-item-action:focus {
  color: #0d0817;
  background-color: #bbbabf;
}
.list-group-item-purple-900.list-group-item-action.active {
  color: #fff;
  background-color: #0d0817;
  border-color: #0d0817;
}

.list-group-item-pink-100 {
  color: #63565c;
  background-color: #fdf7fa;
}
.list-group-item-pink-100.list-group-item-action:hover, .list-group-item-pink-100.list-group-item-action:focus {
  color: #63565c;
  background-color: #e4dee1;
}
.list-group-item-pink-100.list-group-item-action.active {
  color: #fff;
  background-color: #63565c;
  border-color: #63565c;
}

.list-group-item-pink-200 {
  color: #604552;
  background-color: #fceff5;
}
.list-group-item-pink-200.list-group-item-action:hover, .list-group-item-pink-200.list-group-item-action:focus {
  color: #604552;
  background-color: #e3d7dd;
}
.list-group-item-pink-200.list-group-item-action.active {
  color: #fff;
  background-color: #604552;
  border-color: #604552;
}

.list-group-item-pink-300 {
  color: #8a506d;
  background-color: #fae7f0;
}
.list-group-item-pink-300.list-group-item-action:hover, .list-group-item-pink-300.list-group-item-action:focus {
  color: #8a506d;
  background-color: #e1d0d8;
}
.list-group-item-pink-300.list-group-item-action.active {
  color: #fff;
  background-color: #8a506d;
  border-color: #8a506d;
}

.list-group-item-pink-400 {
  color: #85375e;
  background-color: #f8deeb;
}
.list-group-item-pink-400.list-group-item-action:hover, .list-group-item-pink-400.list-group-item-action:focus {
  color: #85375e;
  background-color: #dfc8d4;
}
.list-group-item-pink-400.list-group-item-action.active {
  color: #fff;
  background-color: #85375e;
  border-color: #85375e;
}

.list-group-item-pink-500 {
  color: #801f4f;
  background-color: #f7d6e6;
}
.list-group-item-pink-500.list-group-item-action:hover, .list-group-item-pink-500.list-group-item-action:focus {
  color: #801f4f;
  background-color: #dec1cf;
}
.list-group-item-pink-500.list-group-item-action.active {
  color: #fff;
  background-color: #801f4f;
  border-color: #801f4f;
}

.list-group-item-pink-600 {
  color: #671940;
  background-color: #eed4e1;
}
.list-group-item-pink-600.list-group-item-action:hover, .list-group-item-pink-600.list-group-item-action:focus {
  color: #671940;
  background-color: #d6bfcb;
}
.list-group-item-pink-600.list-group-item-action.active {
  color: #fff;
  background-color: #671940;
  border-color: #671940;
}

.list-group-item-pink-700 {
  color: #4d132f;
  background-color: #e6d2dc;
}
.list-group-item-pink-700.list-group-item-action:hover, .list-group-item-pink-700.list-group-item-action:focus {
  color: #4d132f;
  background-color: #cfbdc6;
}
.list-group-item-pink-700.list-group-item-action.active {
  color: #fff;
  background-color: #4d132f;
  border-color: #4d132f;
}

.list-group-item-pink-800 {
  color: #340c20;
  background-color: #ddd0d7;
}
.list-group-item-pink-800.list-group-item-action:hover, .list-group-item-pink-800.list-group-item-action:focus {
  color: #340c20;
  background-color: #c7bbc2;
}
.list-group-item-pink-800.list-group-item-action.active {
  color: #fff;
  background-color: #340c20;
  border-color: #340c20;
}

.list-group-item-pink-900 {
  color: #1a0610;
  background-color: #d5ced1;
}
.list-group-item-pink-900.list-group-item-action:hover, .list-group-item-pink-900.list-group-item-action:focus {
  color: #1a0610;
  background-color: #c0b9bc;
}
.list-group-item-pink-900.list-group-item-action.active {
  color: #fff;
  background-color: #1a0610;
  border-color: #1a0610;
}

.list-group-item-red-100 {
  color: #635657;
  background-color: #fef7f8;
}
.list-group-item-red-100.list-group-item-action:hover, .list-group-item-red-100.list-group-item-action:focus {
  color: #635657;
  background-color: #e5dedf;
}
.list-group-item-red-100.list-group-item-action.active {
  color: #fff;
  background-color: #635657;
  border-color: #635657;
}

.list-group-item-red-200 {
  color: #604648;
  background-color: #fceff0;
}
.list-group-item-red-200.list-group-item-action:hover, .list-group-item-red-200.list-group-item-action:focus {
  color: #604648;
  background-color: #e3d7d8;
}
.list-group-item-red-200.list-group-item-action.active {
  color: #fff;
  background-color: #604648;
  border-color: #604648;
}

.list-group-item-red-300 {
  color: #8c5056;
  background-color: #fbe7e9;
}
.list-group-item-red-300.list-group-item-action:hover, .list-group-item-red-300.list-group-item-action:focus {
  color: #8c5056;
  background-color: #e2d0d2;
}
.list-group-item-red-300.list-group-item-action.active {
  color: #fff;
  background-color: #8c5056;
  border-color: #8c5056;
}

.list-group-item-red-400 {
  color: #883840;
  background-color: #f9dfe1;
}
.list-group-item-red-400.list-group-item-action:hover, .list-group-item-red-400.list-group-item-action:focus {
  color: #883840;
  background-color: #e0c9cb;
}
.list-group-item-red-400.list-group-item-action.active {
  color: #fff;
  background-color: #883840;
  border-color: #883840;
}

.list-group-item-red-500 {
  color: #842029;
  background-color: #f8d7da;
}
.list-group-item-red-500.list-group-item-action:hover, .list-group-item-red-500.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}
.list-group-item-red-500.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-red-600 {
  color: #6a1921;
  background-color: #efd4d7;
}
.list-group-item-red-600.list-group-item-action:hover, .list-group-item-red-600.list-group-item-action:focus {
  color: #6a1921;
  background-color: #d7bfc2;
}
.list-group-item-red-600.list-group-item-action.active {
  color: #fff;
  background-color: #6a1921;
  border-color: #6a1921;
}

.list-group-item-red-700 {
  color: #4f1319;
  background-color: #e6d2d4;
}
.list-group-item-red-700.list-group-item-action:hover, .list-group-item-red-700.list-group-item-action:focus {
  color: #4f1319;
  background-color: #cfbdbf;
}
.list-group-item-red-700.list-group-item-action.active {
  color: #fff;
  background-color: #4f1319;
  border-color: #4f1319;
}

.list-group-item-red-800 {
  color: #350d11;
  background-color: #ded0d2;
}
.list-group-item-red-800.list-group-item-action:hover, .list-group-item-red-800.list-group-item-action:focus {
  color: #350d11;
  background-color: #c8bbbd;
}
.list-group-item-red-800.list-group-item-action.active {
  color: #fff;
  background-color: #350d11;
  border-color: #350d11;
}

.list-group-item-red-900 {
  color: #1a0708;
  background-color: #d5cecf;
}
.list-group-item-red-900.list-group-item-action:hover, .list-group-item-red-900.list-group-item-action:focus {
  color: #1a0708;
  background-color: #c0b9ba;
}
.list-group-item-red-900.list-group-item-action.active {
  color: #fff;
  background-color: #1a0708;
  border-color: #1a0708;
}

.list-group-item-orange-100 {
  color: #665c53;
  background-color: #fffaf6;
}
.list-group-item-orange-100.list-group-item-action:hover, .list-group-item-orange-100.list-group-item-action:focus {
  color: #665c53;
  background-color: #e6e1dd;
}
.list-group-item-orange-100.list-group-item-action.active {
  color: #fff;
  background-color: #665c53;
  border-color: #665c53;
}

.list-group-item-orange-200 {
  color: #665140;
  background-color: #fff5ec;
}
.list-group-item-orange-200.list-group-item-action:hover, .list-group-item-orange-200.list-group-item-action:focus {
  color: #665140;
  background-color: #e6ddd4;
}
.list-group-item-orange-200.list-group-item-action.active {
  color: #fff;
  background-color: #665140;
  border-color: #665140;
}

.list-group-item-orange-300 {
  color: #66472e;
  background-color: #fff0e3;
}
.list-group-item-orange-300.list-group-item-action:hover, .list-group-item-orange-300.list-group-item-action:focus {
  color: #66472e;
  background-color: #e6d8cc;
}
.list-group-item-orange-300.list-group-item-action.active {
  color: #fff;
  background-color: #66472e;
  border-color: #66472e;
}

.list-group-item-orange-400 {
  color: #985b28;
  background-color: #ffead9;
}
.list-group-item-orange-400.list-group-item-action:hover, .list-group-item-orange-400.list-group-item-action:focus {
  color: #985b28;
  background-color: #e6d3c3;
}
.list-group-item-orange-400.list-group-item-action.active {
  color: #fff;
  background-color: #985b28;
  border-color: #985b28;
}

.list-group-item-orange-500 {
  color: #984c0c;
  background-color: #ffe5d0;
}
.list-group-item-orange-500.list-group-item-action:hover, .list-group-item-orange-500.list-group-item-action:focus {
  color: #984c0c;
  background-color: #e6cebb;
}
.list-group-item-orange-500.list-group-item-action.active {
  color: #fff;
  background-color: #984c0c;
  border-color: #984c0c;
}

.list-group-item-orange-600 {
  color: #793d0a;
  background-color: #f4e0cf;
}
.list-group-item-orange-600.list-group-item-action:hover, .list-group-item-orange-600.list-group-item-action:focus {
  color: #793d0a;
  background-color: #dccaba;
}
.list-group-item-orange-600.list-group-item-action.active {
  color: #fff;
  background-color: #793d0a;
  border-color: #793d0a;
}

.list-group-item-orange-700 {
  color: #5b2e07;
  background-color: #eadbce;
}
.list-group-item-orange-700.list-group-item-action:hover, .list-group-item-orange-700.list-group-item-action:focus {
  color: #5b2e07;
  background-color: #d3c5b9;
}
.list-group-item-orange-700.list-group-item-action.active {
  color: #fff;
  background-color: #5b2e07;
  border-color: #5b2e07;
}

.list-group-item-orange-800 {
  color: #3d1e05;
  background-color: #e0d6ce;
}
.list-group-item-orange-800.list-group-item-action:hover, .list-group-item-orange-800.list-group-item-action:focus {
  color: #3d1e05;
  background-color: #cac1b9;
}
.list-group-item-orange-800.list-group-item-action.active {
  color: #fff;
  background-color: #3d1e05;
  border-color: #3d1e05;
}

.list-group-item-orange-900 {
  color: #1f0f02;
  background-color: #d6d1cd;
}
.list-group-item-orange-900.list-group-item-action:hover, .list-group-item-orange-900.list-group-item-action:focus {
  color: #1f0f02;
  background-color: #c1bcb9;
}
.list-group-item-orange-900.list-group-item-action.active {
  color: #fff;
  background-color: #1f0f02;
  border-color: #1f0f02;
}

.list-group-item-yellow-100 {
  color: #666152;
  background-color: #fffdf5;
}
.list-group-item-yellow-100.list-group-item-action:hover, .list-group-item-yellow-100.list-group-item-action:focus {
  color: #666152;
  background-color: #e6e4dd;
}
.list-group-item-yellow-100.list-group-item-action.active {
  color: #fff;
  background-color: #666152;
  border-color: #666152;
}

.list-group-item-yellow-200 {
  color: #665c3e;
  background-color: #fffaeb;
}
.list-group-item-yellow-200.list-group-item-action:hover, .list-group-item-yellow-200.list-group-item-action:focus {
  color: #665c3e;
  background-color: #e6e1d4;
}
.list-group-item-yellow-200.list-group-item-action.active {
  color: #fff;
  background-color: #665c3e;
  border-color: #665c3e;
}

.list-group-item-yellow-300 {
  color: #66572a;
  background-color: #fff8e1;
}
.list-group-item-yellow-300.list-group-item-action:hover, .list-group-item-yellow-300.list-group-item-action:focus {
  color: #66572a;
  background-color: #e6dfcb;
}
.list-group-item-yellow-300.list-group-item-action.active {
  color: #fff;
  background-color: #66572a;
  border-color: #66572a;
}

.list-group-item-yellow-400 {
  color: #665217;
  background-color: #fff5d7;
}
.list-group-item-yellow-400.list-group-item-action:hover, .list-group-item-yellow-400.list-group-item-action:focus {
  color: #665217;
  background-color: #e6ddc2;
}
.list-group-item-yellow-400.list-group-item-action.active {
  color: #fff;
  background-color: #665217;
  border-color: #665217;
}

.list-group-item-yellow-500 {
  color: #664d03;
  background-color: #fff3cd;
}
.list-group-item-yellow-500.list-group-item-action:hover, .list-group-item-yellow-500.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}
.list-group-item-yellow-500.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-yellow-600 {
  color: #7a5c04;
  background-color: #f5ebcd;
}
.list-group-item-yellow-600.list-group-item-action:hover, .list-group-item-yellow-600.list-group-item-action:focus {
  color: #7a5c04;
  background-color: #ddd4b9;
}
.list-group-item-yellow-600.list-group-item-action.active {
  color: #fff;
  background-color: #7a5c04;
  border-color: #7a5c04;
}

.list-group-item-yellow-700 {
  color: #5c4602;
  background-color: #ebe3cd;
}
.list-group-item-yellow-700.list-group-item-action:hover, .list-group-item-yellow-700.list-group-item-action:focus {
  color: #5c4602;
  background-color: #d4ccb9;
}
.list-group-item-yellow-700.list-group-item-action.active {
  color: #fff;
  background-color: #5c4602;
  border-color: #5c4602;
}

.list-group-item-yellow-800 {
  color: #3d2e02;
  background-color: #e0dbcd;
}
.list-group-item-yellow-800.list-group-item-action:hover, .list-group-item-yellow-800.list-group-item-action:focus {
  color: #3d2e02;
  background-color: #cac5b9;
}
.list-group-item-yellow-800.list-group-item-action.active {
  color: #fff;
  background-color: #3d2e02;
  border-color: #3d2e02;
}

.list-group-item-yellow-900 {
  color: #1f1701;
  background-color: #d6d4cc;
}
.list-group-item-yellow-900.list-group-item-action:hover, .list-group-item-yellow-900.list-group-item-action:focus {
  color: #1f1701;
  background-color: #c1bfb8;
}
.list-group-item-yellow-900.list-group-item-action.active {
  color: #fff;
  background-color: #1f1701;
  border-color: #1f1701;
}

.list-group-item-green-100 {
  color: #545c58;
  background-color: #f6faf8;
}
.list-group-item-green-100.list-group-item-action:hover, .list-group-item-green-100.list-group-item-action:focus {
  color: #545c58;
  background-color: #dde1df;
}
.list-group-item-green-100.list-group-item-action.active {
  color: #fff;
  background-color: #545c58;
  border-color: #545c58;
}

.list-group-item-green-200 {
  color: #41534b;
  background-color: #edf5f1;
}
.list-group-item-green-200.list-group-item-action:hover, .list-group-item-green-200.list-group-item-action:focus {
  color: #41534b;
  background-color: #d5ddd9;
}
.list-group-item-green-200.list-group-item-action.active {
  color: #fff;
  background-color: #41534b;
  border-color: #41534b;
}

.list-group-item-green-300 {
  color: #466e5b;
  background-color: #e3f1ea;
}
.list-group-item-green-300.list-group-item-action:hover, .list-group-item-green-300.list-group-item-action:focus {
  color: #466e5b;
  background-color: #ccd9d3;
}
.list-group-item-green-300.list-group-item-action.active {
  color: #fff;
  background-color: #466e5b;
  border-color: #466e5b;
}

.list-group-item-green-400 {
  color: #2b5f47;
  background-color: #daece4;
}
.list-group-item-green-400.list-group-item-action:hover, .list-group-item-green-400.list-group-item-action:focus {
  color: #2b5f47;
  background-color: #c4d4cd;
}
.list-group-item-green-400.list-group-item-action.active {
  color: #fff;
  background-color: #2b5f47;
  border-color: #2b5f47;
}

.list-group-item-green-500 {
  color: #0f5132;
  background-color: #d1e7dd;
}
.list-group-item-green-500.list-group-item-action:hover, .list-group-item-green-500.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}
.list-group-item-green-500.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-green-600 {
  color: #0c4128;
  background-color: #d0e2d9;
}
.list-group-item-green-600.list-group-item-action:hover, .list-group-item-green-600.list-group-item-action:focus {
  color: #0c4128;
  background-color: #bbcbc3;
}
.list-group-item-green-600.list-group-item-action.active {
  color: #fff;
  background-color: #0c4128;
  border-color: #0c4128;
}

.list-group-item-green-700 {
  color: #09311e;
  background-color: #cfdcd6;
}
.list-group-item-green-700.list-group-item-action:hover, .list-group-item-green-700.list-group-item-action:focus {
  color: #09311e;
  background-color: #bac6c1;
}
.list-group-item-green-700.list-group-item-action.active {
  color: #fff;
  background-color: #09311e;
  border-color: #09311e;
}

.list-group-item-green-800 {
  color: #062014;
  background-color: #ced7d3;
}
.list-group-item-green-800.list-group-item-action:hover, .list-group-item-green-800.list-group-item-action:focus {
  color: #062014;
  background-color: #b9c2be;
}
.list-group-item-green-800.list-group-item-action.active {
  color: #fff;
  background-color: #062014;
  border-color: #062014;
}

.list-group-item-green-900 {
  color: #03100a;
  background-color: #cdd1cf;
}
.list-group-item-green-900.list-group-item-action:hover, .list-group-item-green-900.list-group-item-action:focus {
  color: #03100a;
  background-color: #b9bcba;
}
.list-group-item-green-900.list-group-item-action.active {
  color: #fff;
  background-color: #03100a;
  border-color: #03100a;
}

.list-group-item-teal-100 {
  color: #54625e;
  background-color: #f6fdfb;
}
.list-group-item-teal-100.list-group-item-action:hover, .list-group-item-teal-100.list-group-item-action:focus {
  color: #54625e;
  background-color: #dde4e2;
}
.list-group-item-teal-100.list-group-item-action.active {
  color: #fff;
  background-color: #54625e;
  border-color: #54625e;
}

.list-group-item-teal-200 {
  color: #425d55;
  background-color: #edfbf7;
}
.list-group-item-teal-200.list-group-item-action:hover, .list-group-item-teal-200.list-group-item-action:focus {
  color: #425d55;
  background-color: #d5e2de;
}
.list-group-item-teal-200.list-group-item-action.active {
  color: #fff;
  background-color: #425d55;
  border-color: #425d55;
}

.list-group-item-teal-300 {
  color: #30594d;
  background-color: #e4f9f3;
}
.list-group-item-teal-300.list-group-item-action:hover, .list-group-item-teal-300.list-group-item-action:focus {
  color: #30594d;
  background-color: #cde0db;
}
.list-group-item-teal-300.list-group-item-action.active {
  color: #fff;
  background-color: #30594d;
  border-color: #30594d;
}

.list-group-item-teal-400 {
  color: #1f5545;
  background-color: #dbf6ee;
}
.list-group-item-teal-400.list-group-item-action:hover, .list-group-item-teal-400.list-group-item-action:focus {
  color: #1f5545;
  background-color: #c5ddd6;
}
.list-group-item-teal-400.list-group-item-action.active {
  color: #fff;
  background-color: #1f5545;
  border-color: #1f5545;
}

.list-group-item-teal-500 {
  color: #13795b;
  background-color: #d2f4ea;
}
.list-group-item-teal-500.list-group-item-action:hover, .list-group-item-teal-500.list-group-item-action:focus {
  color: #13795b;
  background-color: #bddcd3;
}
.list-group-item-teal-500.list-group-item-action.active {
  color: #fff;
  background-color: #13795b;
  border-color: #13795b;
}

.list-group-item-teal-600 {
  color: #106149;
  background-color: #d1ece4;
}
.list-group-item-teal-600.list-group-item-action:hover, .list-group-item-teal-600.list-group-item-action:focus {
  color: #106149;
  background-color: #bcd4cd;
}
.list-group-item-teal-600.list-group-item-action.active {
  color: #fff;
  background-color: #106149;
  border-color: #106149;
}

.list-group-item-teal-700 {
  color: #0b4937;
  background-color: #d0e4de;
}
.list-group-item-teal-700.list-group-item-action:hover, .list-group-item-teal-700.list-group-item-action:focus {
  color: #0b4937;
  background-color: #bbcdc8;
}
.list-group-item-teal-700.list-group-item-action.active {
  color: #fff;
  background-color: #0b4937;
  border-color: #0b4937;
}

.list-group-item-teal-800 {
  color: #083024;
  background-color: #cfdcd8;
}
.list-group-item-teal-800.list-group-item-action:hover, .list-group-item-teal-800.list-group-item-action:focus {
  color: #083024;
  background-color: #bac6c2;
}
.list-group-item-teal-800.list-group-item-action.active {
  color: #fff;
  background-color: #083024;
  border-color: #083024;
}

.list-group-item-teal-900 {
  color: #041812;
  background-color: #cdd4d2;
}
.list-group-item-teal-900.list-group-item-action:hover, .list-group-item-teal-900.list-group-item-action:focus {
  color: #041812;
  background-color: #b9bfbd;
}
.list-group-item-teal-900.list-group-item-action.active {
  color: #fff;
  background-color: #041812;
  border-color: #041812;
}

.list-group-item-cyan-100 {
  color: #536265;
  background-color: #f5fdfe;
}
.list-group-item-cyan-100.list-group-item-action:hover, .list-group-item-cyan-100.list-group-item-action:focus {
  color: #536265;
  background-color: #dde4e5;
}
.list-group-item-cyan-100.list-group-item-action.active {
  color: #fff;
  background-color: #536265;
  border-color: #536265;
}

.list-group-item-cyan-200 {
  color: #3f5e64;
  background-color: #ecfbfe;
}
.list-group-item-cyan-200.list-group-item-action:hover, .list-group-item-cyan-200.list-group-item-action:focus {
  color: #3f5e64;
  background-color: #d4e2e5;
}
.list-group-item-cyan-200.list-group-item-action.active {
  color: #fff;
  background-color: #3f5e64;
  border-color: #3f5e64;
}

.list-group-item-cyan-300 {
  color: #2c5962;
  background-color: #e2f9fd;
}
.list-group-item-cyan-300.list-group-item-action:hover, .list-group-item-cyan-300.list-group-item-action:focus {
  color: #2c5962;
  background-color: #cbe0e4;
}
.list-group-item-cyan-300.list-group-item-action.active {
  color: #fff;
  background-color: #2c5962;
  border-color: #2c5962;
}

.list-group-item-cyan-400 {
  color: #185561;
  background-color: #d8f7fd;
}
.list-group-item-cyan-400.list-group-item-action:hover, .list-group-item-cyan-400.list-group-item-action:focus {
  color: #185561;
  background-color: #c2dee4;
}
.list-group-item-cyan-400.list-group-item-action.active {
  color: #fff;
  background-color: #185561;
  border-color: #185561;
}

.list-group-item-cyan-500 {
  color: #055160;
  background-color: #cff4fc;
}
.list-group-item-cyan-500.list-group-item-action:hover, .list-group-item-cyan-500.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}
.list-group-item-cyan-500.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-cyan-600 {
  color: #066173;
  background-color: #ceecf2;
}
.list-group-item-cyan-600.list-group-item-action:hover, .list-group-item-cyan-600.list-group-item-action:focus {
  color: #066173;
  background-color: #b9d4da;
}
.list-group-item-cyan-600.list-group-item-action.active {
  color: #fff;
  background-color: #066173;
  border-color: #066173;
}

.list-group-item-cyan-700 {
  color: #054956;
  background-color: #cee4e9;
}
.list-group-item-cyan-700.list-group-item-action:hover, .list-group-item-cyan-700.list-group-item-action:focus {
  color: #054956;
  background-color: #b9cdd2;
}
.list-group-item-cyan-700.list-group-item-action.active {
  color: #fff;
  background-color: #054956;
  border-color: #054956;
}

.list-group-item-cyan-800 {
  color: #03313a;
  background-color: #cddcdf;
}
.list-group-item-cyan-800.list-group-item-action:hover, .list-group-item-cyan-800.list-group-item-action:focus {
  color: #03313a;
  background-color: #b9c6c9;
}
.list-group-item-cyan-800.list-group-item-action.active {
  color: #fff;
  background-color: #03313a;
  border-color: #03313a;
}

.list-group-item-cyan-900 {
  color: #02181d;
  background-color: #cdd4d6;
}
.list-group-item-cyan-900.list-group-item-action:hover, .list-group-item-cyan-900.list-group-item-action:focus {
  color: #02181d;
  background-color: #b9bfc1;
}
.list-group-item-cyan-900.list-group-item-action.active {
  color: #fff;
  background-color: #02181d;
  border-color: #02181d;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(39, 43, 48, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.toast:not(.showing):not(.show) {
  opacity: 0;
}
.toast.hide {
  display: none;
}

.toast-container {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #7a8288;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #101010;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid rgba(122, 130, 136, 0.5);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid rgba(122, 130, 136, 0.5);
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-content {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.809375rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.809375rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.925rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #999;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

@-webkit-keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: 0.75s linear infinite spinner-grow;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1050;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #101010;
  background-clip: padding-box;
  outline: 0;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}
.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #0d6efd;
}
.link-primary:hover, .link-primary:focus {
  color: black;
}

.link-secondary {
  color: #7a8288;
}
.link-secondary:hover, .link-secondary:focus {
  color: white;
}

.link-success {
  color: #198754;
}
.link-success:hover, .link-success:focus {
  color: black;
}

.link-info {
  color: #0dcaf0;
}
.link-info:hover, .link-info:focus {
  color: white;
}

.link-warning {
  color: #ffc107;
}
.link-warning:hover, .link-warning:focus {
  color: white;
}

.link-danger {
  color: #dc3545;
}
.link-danger:hover, .link-danger:focus {
  color: black;
}

.link-light {
  color: #f8f9fa;
}
.link-light:hover, .link-light:focus {
  color: white;
}

.link-dark {
  color: #272b30;
}
.link-dark:hover, .link-dark:focus {
  color: black;
}

.link-sfx {
  color: #0a94d9;
}
.link-sfx:hover, .link-sfx:focus {
  color: white;
}

.link-that-dark-dark {
  color: #121416;
}
.link-that-dark-dark:hover, .link-that-dark-dark:focus {
  color: black;
}

.link-flat-f {
  color: #fff;
}
.link-flat-f:hover, .link-flat-f:focus {
  color: white;
}

.link-flat-e {
  color: #eee;
}
.link-flat-e:hover, .link-flat-e:focus {
  color: white;
}

.link-flat-d {
  color: #ddd;
}
.link-flat-d:hover, .link-flat-d:focus {
  color: white;
}

.link-flat-c {
  color: #ccc;
}
.link-flat-c:hover, .link-flat-c:focus {
  color: white;
}

.link-flat-b {
  color: #bbb;
}
.link-flat-b:hover, .link-flat-b:focus {
  color: white;
}

.link-flat-a {
  color: #aaa;
}
.link-flat-a:hover, .link-flat-a:focus {
  color: white;
}

.link-flat-9 {
  color: #999;
}
.link-flat-9:hover, .link-flat-9:focus {
  color: white;
}

.link-flat-8 {
  color: #888;
}
.link-flat-8:hover, .link-flat-8:focus {
  color: white;
}

.link-flat-7 {
  color: #777;
}
.link-flat-7:hover, .link-flat-7:focus {
  color: white;
}

.link-flat-6 {
  color: #666;
}
.link-flat-6:hover, .link-flat-6:focus {
  color: black;
}

.link-flat-5 {
  color: #555;
}
.link-flat-5:hover, .link-flat-5:focus {
  color: black;
}

.link-flat-4 {
  color: #444;
}
.link-flat-4:hover, .link-flat-4:focus {
  color: black;
}

.link-flat-3 {
  color: #333;
}
.link-flat-3:hover, .link-flat-3:focus {
  color: black;
}

.link-flat-2 {
  color: #222;
}
.link-flat-2:hover, .link-flat-2:focus {
  color: black;
}

.link-flat-1 {
  color: #111;
}
.link-flat-1:hover, .link-flat-1:focus {
  color: black;
}

.link-flat-0 {
  color: #000;
}
.link-flat-0:hover, .link-flat-0:focus {
  color: black;
}

.link-blue {
  color: #0d6efd;
}
.link-blue:hover, .link-blue:focus {
  color: black;
}

.link-indigo {
  color: #6610f2;
}
.link-indigo:hover, .link-indigo:focus {
  color: black;
}

.link-purple {
  color: #6f42c1;
}
.link-purple:hover, .link-purple:focus {
  color: black;
}

.link-pink {
  color: #d63384;
}
.link-pink:hover, .link-pink:focus {
  color: black;
}

.link-red {
  color: #dc3545;
}
.link-red:hover, .link-red:focus {
  color: black;
}

.link-orange {
  color: #fd7e14;
}
.link-orange:hover, .link-orange:focus {
  color: white;
}

.link-yellow {
  color: #ffc107;
}
.link-yellow:hover, .link-yellow:focus {
  color: white;
}

.link-green {
  color: #198754;
}
.link-green:hover, .link-green:focus {
  color: black;
}

.link-teal {
  color: #20c997;
}
.link-teal:hover, .link-teal:focus {
  color: white;
}

.link-cyan {
  color: #0dcaf0;
}
.link-cyan:hover, .link-cyan:focus {
  color: white;
}

.link-white {
  color: #fff;
}
.link-white:hover, .link-white:focus {
  color: white;
}

.link-gray {
  color: #7a8288;
}
.link-gray:hover, .link-gray:focus {
  color: white;
}

.link-gray-dark {
  color: #3a3f44;
}
.link-gray-dark:hover, .link-gray-dark:focus {
  color: black;
}

.link-blue-100 {
  color: #cfe2ff;
}
.link-blue-100:hover, .link-blue-100:focus {
  color: white;
}

.link-blue-200 {
  color: #9ec5fe;
}
.link-blue-200:hover, .link-blue-200:focus {
  color: white;
}

.link-blue-300 {
  color: #6ea8fe;
}
.link-blue-300:hover, .link-blue-300:focus {
  color: white;
}

.link-blue-400 {
  color: #3d8bfd;
}
.link-blue-400:hover, .link-blue-400:focus {
  color: white;
}

.link-blue-500 {
  color: #0d6efd;
}
.link-blue-500:hover, .link-blue-500:focus {
  color: black;
}

.link-blue-600 {
  color: #0a58ca;
}
.link-blue-600:hover, .link-blue-600:focus {
  color: black;
}

.link-blue-700 {
  color: #084298;
}
.link-blue-700:hover, .link-blue-700:focus {
  color: black;
}

.link-blue-800 {
  color: #052c65;
}
.link-blue-800:hover, .link-blue-800:focus {
  color: black;
}

.link-blue-900 {
  color: #031633;
}
.link-blue-900:hover, .link-blue-900:focus {
  color: black;
}

.link-indigo-100 {
  color: #e0cffc;
}
.link-indigo-100:hover, .link-indigo-100:focus {
  color: white;
}

.link-indigo-200 {
  color: #c29ffa;
}
.link-indigo-200:hover, .link-indigo-200:focus {
  color: white;
}

.link-indigo-300 {
  color: #a370f7;
}
.link-indigo-300:hover, .link-indigo-300:focus {
  color: white;
}

.link-indigo-400 {
  color: #8540f5;
}
.link-indigo-400:hover, .link-indigo-400:focus {
  color: black;
}

.link-indigo-500 {
  color: #6610f2;
}
.link-indigo-500:hover, .link-indigo-500:focus {
  color: black;
}

.link-indigo-600 {
  color: #520dc2;
}
.link-indigo-600:hover, .link-indigo-600:focus {
  color: black;
}

.link-indigo-700 {
  color: #3d0a91;
}
.link-indigo-700:hover, .link-indigo-700:focus {
  color: black;
}

.link-indigo-800 {
  color: #290661;
}
.link-indigo-800:hover, .link-indigo-800:focus {
  color: black;
}

.link-indigo-900 {
  color: #140330;
}
.link-indigo-900:hover, .link-indigo-900:focus {
  color: black;
}

.link-purple-100 {
  color: #e2d9f3;
}
.link-purple-100:hover, .link-purple-100:focus {
  color: white;
}

.link-purple-200 {
  color: #c5b3e6;
}
.link-purple-200:hover, .link-purple-200:focus {
  color: white;
}

.link-purple-300 {
  color: #a98eda;
}
.link-purple-300:hover, .link-purple-300:focus {
  color: white;
}

.link-purple-400 {
  color: #8c68cd;
}
.link-purple-400:hover, .link-purple-400:focus {
  color: white;
}

.link-purple-500 {
  color: #6f42c1;
}
.link-purple-500:hover, .link-purple-500:focus {
  color: black;
}

.link-purple-600 {
  color: #59359a;
}
.link-purple-600:hover, .link-purple-600:focus {
  color: black;
}

.link-purple-700 {
  color: #432874;
}
.link-purple-700:hover, .link-purple-700:focus {
  color: black;
}

.link-purple-800 {
  color: #2c1a4d;
}
.link-purple-800:hover, .link-purple-800:focus {
  color: black;
}

.link-purple-900 {
  color: #160d27;
}
.link-purple-900:hover, .link-purple-900:focus {
  color: black;
}

.link-pink-100 {
  color: #f7d6e6;
}
.link-pink-100:hover, .link-pink-100:focus {
  color: white;
}

.link-pink-200 {
  color: #efadce;
}
.link-pink-200:hover, .link-pink-200:focus {
  color: white;
}

.link-pink-300 {
  color: #e685b5;
}
.link-pink-300:hover, .link-pink-300:focus {
  color: white;
}

.link-pink-400 {
  color: #de5c9d;
}
.link-pink-400:hover, .link-pink-400:focus {
  color: white;
}

.link-pink-500 {
  color: #d63384;
}
.link-pink-500:hover, .link-pink-500:focus {
  color: black;
}

.link-pink-600 {
  color: #ab296a;
}
.link-pink-600:hover, .link-pink-600:focus {
  color: black;
}

.link-pink-700 {
  color: #801f4f;
}
.link-pink-700:hover, .link-pink-700:focus {
  color: black;
}

.link-pink-800 {
  color: #561435;
}
.link-pink-800:hover, .link-pink-800:focus {
  color: black;
}

.link-pink-900 {
  color: #2b0a1a;
}
.link-pink-900:hover, .link-pink-900:focus {
  color: black;
}

.link-red-100 {
  color: #f8d7da;
}
.link-red-100:hover, .link-red-100:focus {
  color: white;
}

.link-red-200 {
  color: #f1aeb5;
}
.link-red-200:hover, .link-red-200:focus {
  color: white;
}

.link-red-300 {
  color: #ea868f;
}
.link-red-300:hover, .link-red-300:focus {
  color: white;
}

.link-red-400 {
  color: #e35d6a;
}
.link-red-400:hover, .link-red-400:focus {
  color: white;
}

.link-red-500 {
  color: #dc3545;
}
.link-red-500:hover, .link-red-500:focus {
  color: black;
}

.link-red-600 {
  color: #b02a37;
}
.link-red-600:hover, .link-red-600:focus {
  color: black;
}

.link-red-700 {
  color: #842029;
}
.link-red-700:hover, .link-red-700:focus {
  color: black;
}

.link-red-800 {
  color: #58151c;
}
.link-red-800:hover, .link-red-800:focus {
  color: black;
}

.link-red-900 {
  color: #2c0b0e;
}
.link-red-900:hover, .link-red-900:focus {
  color: black;
}

.link-orange-100 {
  color: #ffe5d0;
}
.link-orange-100:hover, .link-orange-100:focus {
  color: white;
}

.link-orange-200 {
  color: #fecba1;
}
.link-orange-200:hover, .link-orange-200:focus {
  color: white;
}

.link-orange-300 {
  color: #feb272;
}
.link-orange-300:hover, .link-orange-300:focus {
  color: white;
}

.link-orange-400 {
  color: #fd9843;
}
.link-orange-400:hover, .link-orange-400:focus {
  color: white;
}

.link-orange-500 {
  color: #fd7e14;
}
.link-orange-500:hover, .link-orange-500:focus {
  color: white;
}

.link-orange-600 {
  color: #ca6510;
}
.link-orange-600:hover, .link-orange-600:focus {
  color: white;
}

.link-orange-700 {
  color: #984c0c;
}
.link-orange-700:hover, .link-orange-700:focus {
  color: black;
}

.link-orange-800 {
  color: #653208;
}
.link-orange-800:hover, .link-orange-800:focus {
  color: black;
}

.link-orange-900 {
  color: #331904;
}
.link-orange-900:hover, .link-orange-900:focus {
  color: black;
}

.link-yellow-100 {
  color: #fff3cd;
}
.link-yellow-100:hover, .link-yellow-100:focus {
  color: white;
}

.link-yellow-200 {
  color: #ffe69c;
}
.link-yellow-200:hover, .link-yellow-200:focus {
  color: white;
}

.link-yellow-300 {
  color: #ffda6a;
}
.link-yellow-300:hover, .link-yellow-300:focus {
  color: white;
}

.link-yellow-400 {
  color: #ffcd39;
}
.link-yellow-400:hover, .link-yellow-400:focus {
  color: white;
}

.link-yellow-500 {
  color: #ffc107;
}
.link-yellow-500:hover, .link-yellow-500:focus {
  color: white;
}

.link-yellow-600 {
  color: #cc9a06;
}
.link-yellow-600:hover, .link-yellow-600:focus {
  color: white;
}

.link-yellow-700 {
  color: #997404;
}
.link-yellow-700:hover, .link-yellow-700:focus {
  color: white;
}

.link-yellow-800 {
  color: #664d03;
}
.link-yellow-800:hover, .link-yellow-800:focus {
  color: black;
}

.link-yellow-900 {
  color: #332701;
}
.link-yellow-900:hover, .link-yellow-900:focus {
  color: black;
}

.link-green-100 {
  color: #d1e7dd;
}
.link-green-100:hover, .link-green-100:focus {
  color: white;
}

.link-green-200 {
  color: #a3cfbb;
}
.link-green-200:hover, .link-green-200:focus {
  color: white;
}

.link-green-300 {
  color: #75b798;
}
.link-green-300:hover, .link-green-300:focus {
  color: white;
}

.link-green-400 {
  color: #479f76;
}
.link-green-400:hover, .link-green-400:focus {
  color: white;
}

.link-green-500 {
  color: #198754;
}
.link-green-500:hover, .link-green-500:focus {
  color: black;
}

.link-green-600 {
  color: #146c43;
}
.link-green-600:hover, .link-green-600:focus {
  color: black;
}

.link-green-700 {
  color: #0f5132;
}
.link-green-700:hover, .link-green-700:focus {
  color: black;
}

.link-green-800 {
  color: #0a3622;
}
.link-green-800:hover, .link-green-800:focus {
  color: black;
}

.link-green-900 {
  color: #051b11;
}
.link-green-900:hover, .link-green-900:focus {
  color: black;
}

.link-teal-100 {
  color: #d2f4ea;
}
.link-teal-100:hover, .link-teal-100:focus {
  color: white;
}

.link-teal-200 {
  color: #a6e9d5;
}
.link-teal-200:hover, .link-teal-200:focus {
  color: white;
}

.link-teal-300 {
  color: #79dfc1;
}
.link-teal-300:hover, .link-teal-300:focus {
  color: white;
}

.link-teal-400 {
  color: #4dd4ac;
}
.link-teal-400:hover, .link-teal-400:focus {
  color: white;
}

.link-teal-500 {
  color: #20c997;
}
.link-teal-500:hover, .link-teal-500:focus {
  color: white;
}

.link-teal-600 {
  color: #1aa179;
}
.link-teal-600:hover, .link-teal-600:focus {
  color: white;
}

.link-teal-700 {
  color: #13795b;
}
.link-teal-700:hover, .link-teal-700:focus {
  color: black;
}

.link-teal-800 {
  color: #0d503c;
}
.link-teal-800:hover, .link-teal-800:focus {
  color: black;
}

.link-teal-900 {
  color: #06281e;
}
.link-teal-900:hover, .link-teal-900:focus {
  color: black;
}

.link-cyan-100 {
  color: #cff4fc;
}
.link-cyan-100:hover, .link-cyan-100:focus {
  color: white;
}

.link-cyan-200 {
  color: #9eeaf9;
}
.link-cyan-200:hover, .link-cyan-200:focus {
  color: white;
}

.link-cyan-300 {
  color: #6edff6;
}
.link-cyan-300:hover, .link-cyan-300:focus {
  color: white;
}

.link-cyan-400 {
  color: #3dd5f3;
}
.link-cyan-400:hover, .link-cyan-400:focus {
  color: white;
}

.link-cyan-500 {
  color: #0dcaf0;
}
.link-cyan-500:hover, .link-cyan-500:focus {
  color: white;
}

.link-cyan-600 {
  color: #0aa2c0;
}
.link-cyan-600:hover, .link-cyan-600:focus {
  color: white;
}

.link-cyan-700 {
  color: #087990;
}
.link-cyan-700:hover, .link-cyan-700:focus {
  color: black;
}

.link-cyan-800 {
  color: #055160;
}
.link-cyan-800:hover, .link-cyan-800:focus {
  color: black;
}

.link-cyan-900 {
  color: #032830;
}
.link-cyan-900:hover, .link-cyan-900:focus {
  color: black;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #7a8288 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #7a8288 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #7a8288 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #7a8288 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #7a8288 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #0d6efd !important;
}

.border-secondary {
  border-color: #7a8288 !important;
}

.border-success {
  border-color: #198754 !important;
}

.border-info {
  border-color: #0dcaf0 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #272b30 !important;
}

.border-sfx {
  border-color: #0a94d9 !important;
}

.border-that-dark-dark {
  border-color: #121416 !important;
}

.border-flat-f {
  border-color: #fff !important;
}

.border-flat-e {
  border-color: #eee !important;
}

.border-flat-d {
  border-color: #ddd !important;
}

.border-flat-c {
  border-color: #ccc !important;
}

.border-flat-b {
  border-color: #bbb !important;
}

.border-flat-a {
  border-color: #aaa !important;
}

.border-flat-9 {
  border-color: #999 !important;
}

.border-flat-8 {
  border-color: #888 !important;
}

.border-flat-7 {
  border-color: #777 !important;
}

.border-flat-6 {
  border-color: #666 !important;
}

.border-flat-5 {
  border-color: #555 !important;
}

.border-flat-4 {
  border-color: #444 !important;
}

.border-flat-3 {
  border-color: #333 !important;
}

.border-flat-2 {
  border-color: #222 !important;
}

.border-flat-1 {
  border-color: #111 !important;
}

.border-flat-0 {
  border-color: #000 !important;
}

.border-blue {
  border-color: #0d6efd !important;
}

.border-indigo {
  border-color: #6610f2 !important;
}

.border-purple {
  border-color: #6f42c1 !important;
}

.border-pink {
  border-color: #d63384 !important;
}

.border-red {
  border-color: #dc3545 !important;
}

.border-orange {
  border-color: #fd7e14 !important;
}

.border-yellow {
  border-color: #ffc107 !important;
}

.border-green {
  border-color: #198754 !important;
}

.border-teal {
  border-color: #20c997 !important;
}

.border-cyan {
  border-color: #0dcaf0 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-gray {
  border-color: #7a8288 !important;
}

.border-gray-dark {
  border-color: #3a3f44 !important;
}

.border-blue-100 {
  border-color: #cfe2ff !important;
}

.border-blue-200 {
  border-color: #9ec5fe !important;
}

.border-blue-300 {
  border-color: #6ea8fe !important;
}

.border-blue-400 {
  border-color: #3d8bfd !important;
}

.border-blue-500 {
  border-color: #0d6efd !important;
}

.border-blue-600 {
  border-color: #0a58ca !important;
}

.border-blue-700 {
  border-color: #084298 !important;
}

.border-blue-800 {
  border-color: #052c65 !important;
}

.border-blue-900 {
  border-color: #031633 !important;
}

.border-indigo-100 {
  border-color: #e0cffc !important;
}

.border-indigo-200 {
  border-color: #c29ffa !important;
}

.border-indigo-300 {
  border-color: #a370f7 !important;
}

.border-indigo-400 {
  border-color: #8540f5 !important;
}

.border-indigo-500 {
  border-color: #6610f2 !important;
}

.border-indigo-600 {
  border-color: #520dc2 !important;
}

.border-indigo-700 {
  border-color: #3d0a91 !important;
}

.border-indigo-800 {
  border-color: #290661 !important;
}

.border-indigo-900 {
  border-color: #140330 !important;
}

.border-purple-100 {
  border-color: #e2d9f3 !important;
}

.border-purple-200 {
  border-color: #c5b3e6 !important;
}

.border-purple-300 {
  border-color: #a98eda !important;
}

.border-purple-400 {
  border-color: #8c68cd !important;
}

.border-purple-500 {
  border-color: #6f42c1 !important;
}

.border-purple-600 {
  border-color: #59359a !important;
}

.border-purple-700 {
  border-color: #432874 !important;
}

.border-purple-800 {
  border-color: #2c1a4d !important;
}

.border-purple-900 {
  border-color: #160d27 !important;
}

.border-pink-100 {
  border-color: #f7d6e6 !important;
}

.border-pink-200 {
  border-color: #efadce !important;
}

.border-pink-300 {
  border-color: #e685b5 !important;
}

.border-pink-400 {
  border-color: #de5c9d !important;
}

.border-pink-500 {
  border-color: #d63384 !important;
}

.border-pink-600 {
  border-color: #ab296a !important;
}

.border-pink-700 {
  border-color: #801f4f !important;
}

.border-pink-800 {
  border-color: #561435 !important;
}

.border-pink-900 {
  border-color: #2b0a1a !important;
}

.border-red-100 {
  border-color: #f8d7da !important;
}

.border-red-200 {
  border-color: #f1aeb5 !important;
}

.border-red-300 {
  border-color: #ea868f !important;
}

.border-red-400 {
  border-color: #e35d6a !important;
}

.border-red-500 {
  border-color: #dc3545 !important;
}

.border-red-600 {
  border-color: #b02a37 !important;
}

.border-red-700 {
  border-color: #842029 !important;
}

.border-red-800 {
  border-color: #58151c !important;
}

.border-red-900 {
  border-color: #2c0b0e !important;
}

.border-orange-100 {
  border-color: #ffe5d0 !important;
}

.border-orange-200 {
  border-color: #fecba1 !important;
}

.border-orange-300 {
  border-color: #feb272 !important;
}

.border-orange-400 {
  border-color: #fd9843 !important;
}

.border-orange-500 {
  border-color: #fd7e14 !important;
}

.border-orange-600 {
  border-color: #ca6510 !important;
}

.border-orange-700 {
  border-color: #984c0c !important;
}

.border-orange-800 {
  border-color: #653208 !important;
}

.border-orange-900 {
  border-color: #331904 !important;
}

.border-yellow-100 {
  border-color: #fff3cd !important;
}

.border-yellow-200 {
  border-color: #ffe69c !important;
}

.border-yellow-300 {
  border-color: #ffda6a !important;
}

.border-yellow-400 {
  border-color: #ffcd39 !important;
}

.border-yellow-500 {
  border-color: #ffc107 !important;
}

.border-yellow-600 {
  border-color: #cc9a06 !important;
}

.border-yellow-700 {
  border-color: #997404 !important;
}

.border-yellow-800 {
  border-color: #664d03 !important;
}

.border-yellow-900 {
  border-color: #332701 !important;
}

.border-green-100 {
  border-color: #d1e7dd !important;
}

.border-green-200 {
  border-color: #a3cfbb !important;
}

.border-green-300 {
  border-color: #75b798 !important;
}

.border-green-400 {
  border-color: #479f76 !important;
}

.border-green-500 {
  border-color: #198754 !important;
}

.border-green-600 {
  border-color: #146c43 !important;
}

.border-green-700 {
  border-color: #0f5132 !important;
}

.border-green-800 {
  border-color: #0a3622 !important;
}

.border-green-900 {
  border-color: #051b11 !important;
}

.border-teal-100 {
  border-color: #d2f4ea !important;
}

.border-teal-200 {
  border-color: #a6e9d5 !important;
}

.border-teal-300 {
  border-color: #79dfc1 !important;
}

.border-teal-400 {
  border-color: #4dd4ac !important;
}

.border-teal-500 {
  border-color: #20c997 !important;
}

.border-teal-600 {
  border-color: #1aa179 !important;
}

.border-teal-700 {
  border-color: #13795b !important;
}

.border-teal-800 {
  border-color: #0d503c !important;
}

.border-teal-900 {
  border-color: #06281e !important;
}

.border-cyan-100 {
  border-color: #cff4fc !important;
}

.border-cyan-200 {
  border-color: #9eeaf9 !important;
}

.border-cyan-300 {
  border-color: #6edff6 !important;
}

.border-cyan-400 {
  border-color: #3dd5f3 !important;
}

.border-cyan-500 {
  border-color: #0dcaf0 !important;
}

.border-cyan-600 {
  border-color: #0aa2c0 !important;
}

.border-cyan-700 {
  border-color: #087990 !important;
}

.border-cyan-800 {
  border-color: #055160 !important;
}

.border-cyan-900 {
  border-color: #032830 !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mr-5 {
  margin-right: 3rem !important;
}

.mr-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.ml-5 {
  margin-left: 3rem !important;
}

.ml-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.35625rem + 1.275vw) !important;
}

.fs-2 {
  font-size: calc(1.31rem + 0.72vw) !important;
}

.fs-3 {
  font-size: calc(1.286875rem + 0.4425vw) !important;
}

.fs-4 {
  font-size: calc(1.26375rem + 0.165vw) !important;
}

.fs-5 {
  font-size: 1.15625rem !important;
}

.fs-6 {
  font-size: 0.925rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-justify {
  text-align: justify !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  color: #0d6efd !important;
}

.text-secondary {
  color: #7a8288 !important;
}

.text-success {
  color: #198754 !important;
}

.text-info {
  color: #0dcaf0 !important;
}

.text-warning {
  color: #ffc107 !important;
}

.text-danger {
  color: #dc3545 !important;
}

.text-light {
  color: #f8f9fa !important;
}

.text-dark {
  color: #272b30 !important;
}

.text-sfx {
  color: #0a94d9 !important;
}

.text-that-dark-dark {
  color: #121416 !important;
}

.text-flat-f {
  color: #fff !important;
}

.text-flat-e {
  color: #eee !important;
}

.text-flat-d {
  color: #ddd !important;
}

.text-flat-c {
  color: #ccc !important;
}

.text-flat-b {
  color: #bbb !important;
}

.text-flat-a {
  color: #aaa !important;
}

.text-flat-9 {
  color: #999 !important;
}

.text-flat-8 {
  color: #888 !important;
}

.text-flat-7 {
  color: #777 !important;
}

.text-flat-6 {
  color: #666 !important;
}

.text-flat-5 {
  color: #555 !important;
}

.text-flat-4 {
  color: #444 !important;
}

.text-flat-3 {
  color: #333 !important;
}

.text-flat-2 {
  color: #222 !important;
}

.text-flat-1 {
  color: #111 !important;
}

.text-flat-0 {
  color: #000 !important;
}

.text-blue {
  color: #0d6efd !important;
}

.text-indigo {
  color: #6610f2 !important;
}

.text-purple {
  color: #6f42c1 !important;
}

.text-pink {
  color: #d63384 !important;
}

.text-red {
  color: #dc3545 !important;
}

.text-orange {
  color: #fd7e14 !important;
}

.text-yellow {
  color: #ffc107 !important;
}

.text-green {
  color: #198754 !important;
}

.text-teal {
  color: #20c997 !important;
}

.text-cyan {
  color: #0dcaf0 !important;
}

.text-white {
  color: #fff !important;
}

.text-gray {
  color: #7a8288 !important;
}

.text-gray-dark {
  color: #3a3f44 !important;
}

.text-blue-100 {
  color: #cfe2ff !important;
}

.text-blue-200 {
  color: #9ec5fe !important;
}

.text-blue-300 {
  color: #6ea8fe !important;
}

.text-blue-400 {
  color: #3d8bfd !important;
}

.text-blue-500 {
  color: #0d6efd !important;
}

.text-blue-600 {
  color: #0a58ca !important;
}

.text-blue-700 {
  color: #084298 !important;
}

.text-blue-800 {
  color: #052c65 !important;
}

.text-blue-900 {
  color: #031633 !important;
}

.text-indigo-100 {
  color: #e0cffc !important;
}

.text-indigo-200 {
  color: #c29ffa !important;
}

.text-indigo-300 {
  color: #a370f7 !important;
}

.text-indigo-400 {
  color: #8540f5 !important;
}

.text-indigo-500 {
  color: #6610f2 !important;
}

.text-indigo-600 {
  color: #520dc2 !important;
}

.text-indigo-700 {
  color: #3d0a91 !important;
}

.text-indigo-800 {
  color: #290661 !important;
}

.text-indigo-900 {
  color: #140330 !important;
}

.text-purple-100 {
  color: #e2d9f3 !important;
}

.text-purple-200 {
  color: #c5b3e6 !important;
}

.text-purple-300 {
  color: #a98eda !important;
}

.text-purple-400 {
  color: #8c68cd !important;
}

.text-purple-500 {
  color: #6f42c1 !important;
}

.text-purple-600 {
  color: #59359a !important;
}

.text-purple-700 {
  color: #432874 !important;
}

.text-purple-800 {
  color: #2c1a4d !important;
}

.text-purple-900 {
  color: #160d27 !important;
}

.text-pink-100 {
  color: #f7d6e6 !important;
}

.text-pink-200 {
  color: #efadce !important;
}

.text-pink-300 {
  color: #e685b5 !important;
}

.text-pink-400 {
  color: #de5c9d !important;
}

.text-pink-500 {
  color: #d63384 !important;
}

.text-pink-600 {
  color: #ab296a !important;
}

.text-pink-700 {
  color: #801f4f !important;
}

.text-pink-800 {
  color: #561435 !important;
}

.text-pink-900 {
  color: #2b0a1a !important;
}

.text-red-100 {
  color: #f8d7da !important;
}

.text-red-200 {
  color: #f1aeb5 !important;
}

.text-red-300 {
  color: #ea868f !important;
}

.text-red-400 {
  color: #e35d6a !important;
}

.text-red-500 {
  color: #dc3545 !important;
}

.text-red-600 {
  color: #b02a37 !important;
}

.text-red-700 {
  color: #842029 !important;
}

.text-red-800 {
  color: #58151c !important;
}

.text-red-900 {
  color: #2c0b0e !important;
}

.text-orange-100 {
  color: #ffe5d0 !important;
}

.text-orange-200 {
  color: #fecba1 !important;
}

.text-orange-300 {
  color: #feb272 !important;
}

.text-orange-400 {
  color: #fd9843 !important;
}

.text-orange-500 {
  color: #fd7e14 !important;
}

.text-orange-600 {
  color: #ca6510 !important;
}

.text-orange-700 {
  color: #984c0c !important;
}

.text-orange-800 {
  color: #653208 !important;
}

.text-orange-900 {
  color: #331904 !important;
}

.text-yellow-100 {
  color: #fff3cd !important;
}

.text-yellow-200 {
  color: #ffe69c !important;
}

.text-yellow-300 {
  color: #ffda6a !important;
}

.text-yellow-400 {
  color: #ffcd39 !important;
}

.text-yellow-500 {
  color: #ffc107 !important;
}

.text-yellow-600 {
  color: #cc9a06 !important;
}

.text-yellow-700 {
  color: #997404 !important;
}

.text-yellow-800 {
  color: #664d03 !important;
}

.text-yellow-900 {
  color: #332701 !important;
}

.text-green-100 {
  color: #d1e7dd !important;
}

.text-green-200 {
  color: #a3cfbb !important;
}

.text-green-300 {
  color: #75b798 !important;
}

.text-green-400 {
  color: #479f76 !important;
}

.text-green-500 {
  color: #198754 !important;
}

.text-green-600 {
  color: #146c43 !important;
}

.text-green-700 {
  color: #0f5132 !important;
}

.text-green-800 {
  color: #0a3622 !important;
}

.text-green-900 {
  color: #051b11 !important;
}

.text-teal-100 {
  color: #d2f4ea !important;
}

.text-teal-200 {
  color: #a6e9d5 !important;
}

.text-teal-300 {
  color: #79dfc1 !important;
}

.text-teal-400 {
  color: #4dd4ac !important;
}

.text-teal-500 {
  color: #20c997 !important;
}

.text-teal-600 {
  color: #1aa179 !important;
}

.text-teal-700 {
  color: #13795b !important;
}

.text-teal-800 {
  color: #0d503c !important;
}

.text-teal-900 {
  color: #06281e !important;
}

.text-cyan-100 {
  color: #cff4fc !important;
}

.text-cyan-200 {
  color: #9eeaf9 !important;
}

.text-cyan-300 {
  color: #6edff6 !important;
}

.text-cyan-400 {
  color: #3dd5f3 !important;
}

.text-cyan-500 {
  color: #0dcaf0 !important;
}

.text-cyan-600 {
  color: #0aa2c0 !important;
}

.text-cyan-700 {
  color: #087990 !important;
}

.text-cyan-800 {
  color: #055160 !important;
}

.text-cyan-900 {
  color: #032830 !important;
}

.text-body {
  color: #999 !important;
}

.text-muted {
  color: #7a8288 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.bg-primary {
  background-color: #0d6efd !important;
}

.bg-secondary {
  background-color: #7a8288 !important;
}

.bg-success {
  background-color: #198754 !important;
}

.bg-info {
  background-color: #0dcaf0 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.bg-dark {
  background-color: #272b30 !important;
}

.bg-sfx {
  background-color: #0a94d9 !important;
}

.bg-that-dark-dark {
  background-color: #121416 !important;
}

.bg-flat-f {
  background-color: #fff !important;
}

.bg-flat-e {
  background-color: #eee !important;
}

.bg-flat-d {
  background-color: #ddd !important;
}

.bg-flat-c {
  background-color: #ccc !important;
}

.bg-flat-b {
  background-color: #bbb !important;
}

.bg-flat-a {
  background-color: #aaa !important;
}

.bg-flat-9 {
  background-color: #999 !important;
}

.bg-flat-8 {
  background-color: #888 !important;
}

.bg-flat-7 {
  background-color: #777 !important;
}

.bg-flat-6 {
  background-color: #666 !important;
}

.bg-flat-5 {
  background-color: #555 !important;
}

.bg-flat-4 {
  background-color: #444 !important;
}

.bg-flat-3 {
  background-color: #333 !important;
}

.bg-flat-2 {
  background-color: #222 !important;
}

.bg-flat-1 {
  background-color: #111 !important;
}

.bg-flat-0 {
  background-color: #000 !important;
}

.bg-blue {
  background-color: #0d6efd !important;
}

.bg-indigo {
  background-color: #6610f2 !important;
}

.bg-purple {
  background-color: #6f42c1 !important;
}

.bg-pink {
  background-color: #d63384 !important;
}

.bg-red {
  background-color: #dc3545 !important;
}

.bg-orange {
  background-color: #fd7e14 !important;
}

.bg-yellow {
  background-color: #ffc107 !important;
}

.bg-green {
  background-color: #198754 !important;
}

.bg-teal {
  background-color: #20c997 !important;
}

.bg-cyan {
  background-color: #0dcaf0 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-gray {
  background-color: #7a8288 !important;
}

.bg-gray-dark {
  background-color: #3a3f44 !important;
}

.bg-blue-100 {
  background-color: #cfe2ff !important;
}

.bg-blue-200 {
  background-color: #9ec5fe !important;
}

.bg-blue-300 {
  background-color: #6ea8fe !important;
}

.bg-blue-400 {
  background-color: #3d8bfd !important;
}

.bg-blue-500 {
  background-color: #0d6efd !important;
}

.bg-blue-600 {
  background-color: #0a58ca !important;
}

.bg-blue-700 {
  background-color: #084298 !important;
}

.bg-blue-800 {
  background-color: #052c65 !important;
}

.bg-blue-900 {
  background-color: #031633 !important;
}

.bg-indigo-100 {
  background-color: #e0cffc !important;
}

.bg-indigo-200 {
  background-color: #c29ffa !important;
}

.bg-indigo-300 {
  background-color: #a370f7 !important;
}

.bg-indigo-400 {
  background-color: #8540f5 !important;
}

.bg-indigo-500 {
  background-color: #6610f2 !important;
}

.bg-indigo-600 {
  background-color: #520dc2 !important;
}

.bg-indigo-700 {
  background-color: #3d0a91 !important;
}

.bg-indigo-800 {
  background-color: #290661 !important;
}

.bg-indigo-900 {
  background-color: #140330 !important;
}

.bg-purple-100 {
  background-color: #e2d9f3 !important;
}

.bg-purple-200 {
  background-color: #c5b3e6 !important;
}

.bg-purple-300 {
  background-color: #a98eda !important;
}

.bg-purple-400 {
  background-color: #8c68cd !important;
}

.bg-purple-500 {
  background-color: #6f42c1 !important;
}

.bg-purple-600 {
  background-color: #59359a !important;
}

.bg-purple-700 {
  background-color: #432874 !important;
}

.bg-purple-800 {
  background-color: #2c1a4d !important;
}

.bg-purple-900 {
  background-color: #160d27 !important;
}

.bg-pink-100 {
  background-color: #f7d6e6 !important;
}

.bg-pink-200 {
  background-color: #efadce !important;
}

.bg-pink-300 {
  background-color: #e685b5 !important;
}

.bg-pink-400 {
  background-color: #de5c9d !important;
}

.bg-pink-500 {
  background-color: #d63384 !important;
}

.bg-pink-600 {
  background-color: #ab296a !important;
}

.bg-pink-700 {
  background-color: #801f4f !important;
}

.bg-pink-800 {
  background-color: #561435 !important;
}

.bg-pink-900 {
  background-color: #2b0a1a !important;
}

.bg-red-100 {
  background-color: #f8d7da !important;
}

.bg-red-200 {
  background-color: #f1aeb5 !important;
}

.bg-red-300 {
  background-color: #ea868f !important;
}

.bg-red-400 {
  background-color: #e35d6a !important;
}

.bg-red-500 {
  background-color: #dc3545 !important;
}

.bg-red-600 {
  background-color: #b02a37 !important;
}

.bg-red-700 {
  background-color: #842029 !important;
}

.bg-red-800 {
  background-color: #58151c !important;
}

.bg-red-900 {
  background-color: #2c0b0e !important;
}

.bg-orange-100 {
  background-color: #ffe5d0 !important;
}

.bg-orange-200 {
  background-color: #fecba1 !important;
}

.bg-orange-300 {
  background-color: #feb272 !important;
}

.bg-orange-400 {
  background-color: #fd9843 !important;
}

.bg-orange-500 {
  background-color: #fd7e14 !important;
}

.bg-orange-600 {
  background-color: #ca6510 !important;
}

.bg-orange-700 {
  background-color: #984c0c !important;
}

.bg-orange-800 {
  background-color: #653208 !important;
}

.bg-orange-900 {
  background-color: #331904 !important;
}

.bg-yellow-100 {
  background-color: #fff3cd !important;
}

.bg-yellow-200 {
  background-color: #ffe69c !important;
}

.bg-yellow-300 {
  background-color: #ffda6a !important;
}

.bg-yellow-400 {
  background-color: #ffcd39 !important;
}

.bg-yellow-500 {
  background-color: #ffc107 !important;
}

.bg-yellow-600 {
  background-color: #cc9a06 !important;
}

.bg-yellow-700 {
  background-color: #997404 !important;
}

.bg-yellow-800 {
  background-color: #664d03 !important;
}

.bg-yellow-900 {
  background-color: #332701 !important;
}

.bg-green-100 {
  background-color: #d1e7dd !important;
}

.bg-green-200 {
  background-color: #a3cfbb !important;
}

.bg-green-300 {
  background-color: #75b798 !important;
}

.bg-green-400 {
  background-color: #479f76 !important;
}

.bg-green-500 {
  background-color: #198754 !important;
}

.bg-green-600 {
  background-color: #146c43 !important;
}

.bg-green-700 {
  background-color: #0f5132 !important;
}

.bg-green-800 {
  background-color: #0a3622 !important;
}

.bg-green-900 {
  background-color: #051b11 !important;
}

.bg-teal-100 {
  background-color: #d2f4ea !important;
}

.bg-teal-200 {
  background-color: #a6e9d5 !important;
}

.bg-teal-300 {
  background-color: #79dfc1 !important;
}

.bg-teal-400 {
  background-color: #4dd4ac !important;
}

.bg-teal-500 {
  background-color: #20c997 !important;
}

.bg-teal-600 {
  background-color: #1aa179 !important;
}

.bg-teal-700 {
  background-color: #13795b !important;
}

.bg-teal-800 {
  background-color: #0d503c !important;
}

.bg-teal-900 {
  background-color: #06281e !important;
}

.bg-cyan-100 {
  background-color: #cff4fc !important;
}

.bg-cyan-200 {
  background-color: #9eeaf9 !important;
}

.bg-cyan-300 {
  background-color: #6edff6 !important;
}

.bg-cyan-400 {
  background-color: #3dd5f3 !important;
}

.bg-cyan-500 {
  background-color: #0dcaf0 !important;
}

.bg-cyan-600 {
  background-color: #0aa2c0 !important;
}

.bg-cyan-700 {
  background-color: #087990 !important;
}

.bg-cyan-800 {
  background-color: #055160 !important;
}

.bg-cyan-900 {
  background-color: #032830 !important;
}

.bg-body {
  background-color: #101010 !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mr-sm-0 {
    margin-right: 0 !important;
  }

  .mr-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mr-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mr-sm-3 {
    margin-right: 1rem !important;
  }

  .mr-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mr-sm-5 {
    margin-right: 3rem !important;
  }

  .mr-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .ml-sm-0 {
    margin-left: 0 !important;
  }

  .ml-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ml-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ml-sm-3 {
    margin-left: 1rem !important;
  }

  .ml-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ml-sm-5 {
    margin-left: 3rem !important;
  }

  .ml-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -1rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n5 {
    margin-top: -3rem !important;
  }

  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .me-sm-n3 {
    margin-right: -1rem !important;
  }

  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-sm-n3 {
    margin-left: -1rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n5 {
    margin-left: -3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }

  .text-sm-justify {
    text-align: justify !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mr-md-0 {
    margin-right: 0 !important;
  }

  .mr-md-1 {
    margin-right: 0.25rem !important;
  }

  .mr-md-2 {
    margin-right: 0.5rem !important;
  }

  .mr-md-3 {
    margin-right: 1rem !important;
  }

  .mr-md-4 {
    margin-right: 1.5rem !important;
  }

  .mr-md-5 {
    margin-right: 3rem !important;
  }

  .mr-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .ml-md-0 {
    margin-left: 0 !important;
  }

  .ml-md-1 {
    margin-left: 0.25rem !important;
  }

  .ml-md-2 {
    margin-left: 0.5rem !important;
  }

  .ml-md-3 {
    margin-left: 1rem !important;
  }

  .ml-md-4 {
    margin-left: 1.5rem !important;
  }

  .ml-md-5 {
    margin-left: 3rem !important;
  }

  .ml-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -1rem !important;
  }

  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n5 {
    margin-top: -3rem !important;
  }

  .me-md-n1 {
    margin-right: -0.25rem !important;
  }

  .me-md-n2 {
    margin-right: -0.5rem !important;
  }

  .me-md-n3 {
    margin-right: -1rem !important;
  }

  .me-md-n4 {
    margin-right: -1.5rem !important;
  }

  .me-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-md-n3 {
    margin-left: -1rem !important;
  }

  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n5 {
    margin-left: -3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }

  .text-md-justify {
    text-align: justify !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mr-lg-0 {
    margin-right: 0 !important;
  }

  .mr-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mr-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mr-lg-3 {
    margin-right: 1rem !important;
  }

  .mr-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mr-lg-5 {
    margin-right: 3rem !important;
  }

  .mr-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .ml-lg-0 {
    margin-left: 0 !important;
  }

  .ml-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ml-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ml-lg-3 {
    margin-left: 1rem !important;
  }

  .ml-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ml-lg-5 {
    margin-left: 3rem !important;
  }

  .ml-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -1rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n5 {
    margin-top: -3rem !important;
  }

  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .me-lg-n3 {
    margin-right: -1rem !important;
  }

  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-lg-n3 {
    margin-left: -1rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n5 {
    margin-left: -3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }

  .text-lg-justify {
    text-align: justify !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mr-xl-0 {
    margin-right: 0 !important;
  }

  .mr-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mr-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mr-xl-3 {
    margin-right: 1rem !important;
  }

  .mr-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mr-xl-5 {
    margin-right: 3rem !important;
  }

  .mr-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .ml-xl-0 {
    margin-left: 0 !important;
  }

  .ml-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ml-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ml-xl-3 {
    margin-left: 1rem !important;
  }

  .ml-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ml-xl-5 {
    margin-left: 3rem !important;
  }

  .ml-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n5 {
    margin-top: -3rem !important;
  }

  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xl-n3 {
    margin-right: -1rem !important;
  }

  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n5 {
    margin-left: -3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }

  .text-xl-justify {
    text-align: justify !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mr-xxl-0 {
    margin-right: 0 !important;
  }

  .mr-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .mr-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .mr-xxl-3 {
    margin-right: 1rem !important;
  }

  .mr-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .mr-xxl-5 {
    margin-right: 3rem !important;
  }

  .mr-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .ml-xxl-0 {
    margin-left: 0 !important;
  }

  .ml-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ml-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ml-xxl-3 {
    margin-left: 1rem !important;
  }

  .ml-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ml-xxl-5 {
    margin-left: 3rem !important;
  }

  .ml-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -3rem !important;
  }

  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }

  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xxl-n3 {
    margin-right: -1rem !important;
  }

  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }

  .text-xxl-justify {
    text-align: justify !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.3125rem !important;
  }

  .fs-2 {
    font-size: 1.85rem !important;
  }

  .fs-3 {
    font-size: 1.61875rem !important;
  }

  .fs-4 {
    font-size: 1.3875rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
.blur-1 {
  filter: blur(1px);
}

.rounded-1 {
  border-radius: 1px;
}

.blur-2 {
  filter: blur(2px);
}

.rounded-2 {
  border-radius: 2px;
}

.blur-3 {
  filter: blur(3px);
}

.rounded-3 {
  border-radius: 3px;
}

.blur-4 {
  filter: blur(4px);
}

.rounded-4 {
  border-radius: 4px;
}

.blur-5 {
  filter: blur(5px);
}

.rounded-5 {
  border-radius: 5px;
}

.blur-6 {
  filter: blur(6px);
}

.rounded-6 {
  border-radius: 6px;
}

.blur-7 {
  filter: blur(7px);
}

.rounded-7 {
  border-radius: 7px;
}

.blur-8 {
  filter: blur(8px);
}

.rounded-8 {
  border-radius: 8px;
}

.blur-9 {
  filter: blur(9px);
}

.rounded-9 {
  border-radius: 9px;
}

.blur-10 {
  filter: blur(10px);
}

.rounded-10 {
  border-radius: 10px;
}

.blur-11 {
  filter: blur(11px);
}

.rounded-11 {
  border-radius: 11px;
}

.blur-12 {
  filter: blur(12px);
}

.rounded-12 {
  border-radius: 12px;
}

.blur-13 {
  filter: blur(13px);
}

.rounded-13 {
  border-radius: 13px;
}

.blur-14 {
  filter: blur(14px);
}

.rounded-14 {
  border-radius: 14px;
}

.blur-15 {
  filter: blur(15px);
}

.rounded-15 {
  border-radius: 15px;
}

.blur-16 {
  filter: blur(16px);
}

.rounded-16 {
  border-radius: 16px;
}

.blur-17 {
  filter: blur(17px);
}

.rounded-17 {
  border-radius: 17px;
}

.blur-18 {
  filter: blur(18px);
}

.rounded-18 {
  border-radius: 18px;
}

.blur-19 {
  filter: blur(19px);
}

.rounded-19 {
  border-radius: 19px;
}

.blur-20 {
  filter: blur(20px);
}

.rounded-20 {
  border-radius: 20px;
}

.blur-21 {
  filter: blur(21px);
}

.rounded-21 {
  border-radius: 21px;
}

.blur-22 {
  filter: blur(22px);
}

.rounded-22 {
  border-radius: 22px;
}

.blur-23 {
  filter: blur(23px);
}

.rounded-23 {
  border-radius: 23px;
}

.blur-24 {
  filter: blur(24px);
}

.rounded-24 {
  border-radius: 24px;
}

.blur-25 {
  filter: blur(25px);
}

.rounded-25 {
  border-radius: 25px;
}

.font-italic {
  font-style: italic;
}

.font-bold {
  font-weight: 700;
}

.font-underline {
  text-decoration: underline;
}

.text-italic {
  font-style: italic;
}

.text-bold {
  font-weight: 700;
}

.text-underline {
  text-decoration: underline;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.form-group {
  margin-bottom: 1rem;
}

.bw {
  filter: grayscale(1);
}

.color {
  filter: grayscale(0);
}

/*# sourceMappingURL=bootstrap.css.map */